import moment from 'moment';
import 'moment/locale/de';

import {updateObject} from '../utils/reducerUtils';

const addMomentsToTestResult = (testResult) => {
	let {createdAt, distributedAt, usedAt, completedAt} = testResult;

	createdAt = moment(createdAt);

	if (distributedAt) {
		distributedAt = moment(distributedAt).locale('de');
	}

	if (usedAt) {
		usedAt = moment(usedAt).locale('de');
	}

	if (completedAt) {
		completedAt = moment(completedAt).locale('de');
	}

	return updateObject(testResult, {
		createdAt,
		distributedAt,
		usedAt,
		completedAt,
	});
};

export default function addMomentsToTestResults(testResults) {
	return testResults.map(addMomentsToTestResult);
}
