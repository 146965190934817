import React from 'react';

import {updateObject} from '../../utils/reducerUtils';
import VerifyCurrentLogin from './VerifyCurrentLogin';

import './styles.scss';
import strings from '../../utils/strings';

class AdminInformation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			initiatesLoginChange: false,
			confirmsLoginChange: false,
			newLoginMethod: null,
		};
	}

	validateChangeLogin(loginMethods) {
		let flag = 0;
		if (!loginMethods.includes('Feide')) {
			if (loginMethods.includes('BankID')) {
				flag = flag + 1;
			}
			if (loginMethods.includes('Legacy')) {
				flag = flag + 1;
			}
			if (loginMethods.includes('BankIDMobile')) {
				flag = flag + 1;
			}
		}

		if (flag === 1) {
			return true;
		}

		return false;
	}

	render() {
		const testAdmin = this.props.testAdmin;
		const isSuperAdmin = this.props.isSuperAdmin;
		const loginMethods = this.props.loginMethods;
		let loginMethodsInfo = '';
		for (let i = 0; i < loginMethods.length; i++) {
			if (loginMethods[i + 1]) {
				loginMethodsInfo =
					loginMethodsInfo + loginMethods[i].toString() + ', ';
			} else {
				if (loginMethods[i].toString() === 'custom') {
					loginMethodsInfo = loginMethodsInfo + 'Passwort';
				} else {
					loginMethodsInfo =
						loginMethodsInfo + loginMethods[i].toString();
				}
			}
		}

		return (
			<div className="lit-subscription-main">
				<div className="tab-heading">
					<h3 className="lit-page-title">
						{isSuperAdmin
							? `${`${testAdmin.firstName} ${testAdmin.lastName}`} ${
									strings.referalinformation
							  }`
							: strings.myinformation}
					</h3>
				</div>
				<form
					className="register-form"
					onSubmit={(event) => {
						event.preventDefault();
						const testAdminResult = updateObject(testAdmin);
						this.props.onSaveTestAdmin(testAdminResult);
					}}
				>
					<br />

					<div className="lit-input inline">
						<label
							className="lit-input__label wide"
							htmlFor="test-admin-modal-firstName"
						>
							{strings.firstname}
						</label>
						<input
							className="lit-input__field"
							id="test-admin-modal-firstName"
							name="firstName"
							onChange={this.props.onHandleUpdateTestAdminEvent}
							placeholder="Kari"
							required
							type="text"
							value={testAdmin.firstName || ''}
						/>
					</div>
					<div className="lit-input inline">
						<label
							className="lit-input__label wide"
							htmlFor="test-admin-modal-lastName"
						>
							{strings.lastname}
						</label>
						<input
							className="lit-input__field"
							id="test-admin-modal-lastName"
							name="lastName"
							onChange={this.props.onHandleUpdateTestAdminEvent}
							placeholder="Nordmann"
							required
							type="text"
							value={testAdmin.lastName || ''}
						/>
					</div>
					<div className="lit-input inline">
						<label
							className="lit-input__label wide"
							htmlFor="test-admin-modal-organization"
						>
							{strings.shcool_or_company}
						</label>
						<input
							className="lit-input__field"
							id="test-admin-modal-organization"
							name="organization"
							onChange={this.props.onHandleUpdateTestAdminEvent}
							placeholder="Kongsbakken vgs"
							type="text"
							value={testAdmin.organization || ''}
						/>
					</div>
					<div className="lit-input inline">
						<label
							className="lit-input__label wide"
							htmlFor="test-admin-modal-email"
						>
							{strings.email}
						</label>
						<input
							className="lit-input__field"
							id="test-admin-modal-email"
							name="email"
							onChange={this.props.onHandleUpdateTestAdminEvent}
							placeholder="kari@nordmann.no"
							required
							type="email"
							value={testAdmin.email || ''}
						/>
					</div>

					<div className="lit-input inline">
						<label
							className="lit-input__label wide"
							htmlFor="test-admin-modal-phone"
						>
							{strings.telephone}
						</label>
						<input
							className="lit-input__field"
							id="test-admin-modal-phone"
							name="phone"
							onChange={this.props.onHandleUpdateTestAdminEvent}
							placeholder="987 65 432"
							required
							type="tel"
							value={testAdmin.phone || ''}
						/>
					</div>
					<div className="lit-input inline">
						<label
							className="lit-input__label wide"
							htmlFor="test-admin-modal-loginMethod"
						>
							{loginMethods.length > 1
								? 'Innloggingsmetoder'
								: 'Login-Methode'}
						</label>
						<input
							className="lit-input__field"
							id="test-admin-modal-loginMethod"
							name="loginMethod"
							disabled
							type="tel"
							value={loginMethodsInfo}
						/>
					</div>

					<div className="lit-input-save">
						<button className="lit-btn bg-primary">
							{`${strings.save}`}
						</button>
					</div>
				</form>
				<br />
				{this.validateChangeLogin(loginMethods) &&
					!loginMethods.includes('Feide') && (
						<div className="lit-change-loginMethod">
							<p>
								{' '}
								{`Din nåværande ${
									loginMethods.length > 1
										? 'innloggingsmetoder'
										: 'innloggingsmetode'
								} er [${loginMethodsInfo}] Ønsker du å endre Innloggingsmetode ? (Anbefalt)`}
							</p>
							<div>
								<input
									className="lit-change-login"
									id={'feide'}
									name="password"
									onChange={() => {
										this.setState({
											initiatesLoginChange: true,
											newLoginMethod: 'feide',
										});
									}}
									type="radio"
								/>
								<label
									className="lit-login-label"
									htmlFor={'feide'}
								>
									{strings.changeToFeide}
								</label>
							</div>
							<br></br>
							<div>
								<input
									className="lit-change-login"
									id={'password'}
									name="password"
									disabled
									onChange={() => {
										this.setState({
											initiatesLoginChange: true,
											newLoginMethod: 'password',
										});
									}}
									type="radio"
								/>
								<label
									className="lit-login-label"
									htmlFor={'password'}
								>
									{strings.chageToPassword}
								</label>
							</div>
							<br></br>
							{this.state.initiatesLoginChange && (
								<div className="lit-input">
									<button
										className="lit-btn bg-primary"
										onClick={() => {
											this.setState({
												confirmsLoginChange: true,
											});
										}}
									>
										{`${strings.confirm}`}
									</button>
								</div>
							)}
						</div>
					)}
				{this.state.confirmsLoginChange && (
					<VerifyCurrentLogin
						closeWindow={() => {
							this.setState({confirmsLoginChange: false});
						}}
						regToken={testAdmin.regToken}
						testAdmin={testAdmin}
						chosenMethod={this.state.newLoginMethod}
					/>
				)}
			</div>
		);
	}
}

export default AdminInformation;
