import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import createAuthToken from '../../api/createAuthToken';

import './styles.scss';

import {BANK_ID, BANK_ID_MOBILE, FEIDE} from '../../constants/index';

import {initAuthAction} from '../../actions/index';
import strings from '../../utils/strings';

const testKey = null;
let feidSelection = false;
class VerifyCurrentLogin extends React.Component {
	constructor() {
		super();
		this.state = {
			authToken: null,
			authError: null,
		};

		this.createdAndFetchRegistrationToken = this.createdAndFetchRegistrationToken.bind(
			this
		);
	}

	componentWillMount() {
		this.createdAndFetchRegistrationToken();
	}
	async createdAndFetchRegistrationToken() {
		const adminId = this.props.testAdmin.id;
		const email = this.props.testAdmin.email;

		createAuthToken(adminId, email).then((res) => {
			this.setState({
				authToken: res[0].UUID,
				authError: this.props.error,
			});
		});
	}

	render() {
		const {error, iframeUrl, initAuth, isInit} = this.props;
		const {authToken} = this.state;
		const showIframe = isInit && iframeUrl;
		const loginClass = testKey ? 'lit-login' : 'lit-verifyLogin';

		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island-newLogin"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="lit-newlogin">
						<div className="lit-newLogin__content">
							<div className={loginClass}>
								{!isInit && (
									<div
										className={
											loginClass + '__wrapper bg-white'
										}
									>
										<h2 className={loginClass + '__header'}>
											{'Bytt til ' + 'FEIDE'}
										</h2>
										<p
											className={
												loginClass + '__description'
											}
										>
											Du har nå valgt å bytte til en annet
											identifiseringsmetode. Vennligst
											start med å identifisere deg selv.
											Husk å bruke samme metode som du
											bruker for å logge på.
										</p>

										<div
											className={
												loginClass + '__action-wrapper'
											}
										>
											<button
												className={
													testKey
														? 'lit-btn bg-primary  bg-border'
														: 'lit-btn bg-primary'
												}
												onClick={() => {
													if (testKey) {
														if (
															window.confirm(
																strings.reminder_bankidregistration
															)
														) {
															initAuth(
																BANK_ID,
																null,
																this.props
																	.regToken,
																this.props
																	.chosenMethod,
																'verify'
															);
														}
													} else {
														initAuth(
															BANK_ID,
															null,
															authToken,
															this.props
																.chosenMethod,
															'verify'
														);
													}
												}}
											>
												{strings.bankid}
											</button>
											<button
												className="lit-btn bg-primary bg-border"
												onClick={() => {
													initAuth(
														BANK_ID_MOBILE,
														testKey,
														authToken,
														this.props.chosenMethod,
														'verify'
													);
												}}
											>
												{strings.bankidonmobile}
											</button>
										</div>
										{/* <br />
										 */}
									</div>
								)}
								{error && (
									<div className="lit-change-login-error">
										<p className="find-me">
											Unable to change login Method:
											Error::
											<strong>{error}.</strong>
											Please try again later.
										</p>
									</div>
								)}

								{showIframe && feidSelection && (
									<div className="bank-id-iframe-wrapper">
										<iframe
											title="login-frame"
											frameBorder="0"
											id="authFrame"
											scrolling="no"
											src={iframeUrl}
											style={{
												width: '120%',
												minHeight: '900px',
											}}
										/>
									</div>
								)}
								{showIframe && !feidSelection && (
									<div className="bank-id-iframe-wrapper">
										<iframe
											title="login-frame"
											frameBorder="0"
											id="authFrame"
											scrolling="no"
											src={iframeUrl}
											style={{
												width: '100%',
												minHeight: '200px',
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

VerifyCurrentLogin.propTypes = {
	error: PropTypes.string.isRequired,
	iframeUrl: PropTypes.string.isRequired,
	initAuth: PropTypes.func.isRequired,
	isInit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCurrentLogin);
