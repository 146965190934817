import React from 'react';
import strings from '../../../../utils/strings';
import classNames from 'classnames';
import './styles.scss';
class NewClassModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			className: '',
			isCreatingNewClass: true,
			targetGroup: '',
			isAddingGroup: false,
			groupId: null,
			isAddingCandidates: false,
			inclusiveMember: [],
			exclusiveMembers: [],
			inclusiveIds: [],
			targetClass: '',
		};
		this.excludeMembersIfExists = this.excludeMembersIfExists.bind(this);
	}
	componentDidMount() {}
	determineGender(currentTestUser, allTestUsers) {
		for (let i = 0; i < allTestUsers.length; i++) {
			const testsResults = allTestUsers[i].testResults;
			for (let j = 0; j < testsResults.length; j++) {
				if (testsResults[j].testUser) {
					if (
						testsResults[j].testUser.testUserId ===
							currentTestUser &&
						testsResults[j].info
					) {
						const information = JSON.parse(testsResults[j].info);
						if (testsResults[j].type !== 'child') {
							if (information.gender === 'm') {
								return (
									<td className="lit-list-users-new-group-col">
										{strings.male}
									</td>
								);
							} else {
								return (
									<td className="lit-list-users-new-group-col">
										{strings.female}
									</td>
								);
							}
						} else if (information.gender === 'm') {
							return (
								<td className="lit-list-users-new-group-col">
									{strings.boy}
								</td>
							);
						} else if (information.gender === 'f') {
							return (
								<td className="lit-list-users-new-group-col">
									{strings.girl}
								</td>
							);
						}
					}
				}
			}
		}
		return null;
	}
	setDefaultUser(filteredTestUsers, adminClasses, classData, currentUser) {
		const userClasses = adminClasses.map((userClass) => {
			return userClass.className;
		});
		const defaultClass = [...new Set(userClasses)];

		this.excludeMembersIfExists(
			defaultClass[0],
			classData,
			filteredTestUsers,
			currentUser
		);
	}

	async excludeMembersIfExists(
		value,
		classMembers,
		selectedTestUsers,
		currentUser
	) {
		const {subscription} = currentUser;
		const selectedTestUserIds = selectedTestUsers.map((TestUser) => {
			return {
				testUser: TestUser.id,
				firstName: TestUser.firstName,
				lastName: TestUser.lastName,
			};
		});
		var getrelevantData = classMembers.filter(
			(userClass) => userClass.className === value
		);

		const candidates = getrelevantData[0].classInfo;

		let flag = null;
		let currentClassId = null;
		const temObj = [];
		const permObj = [];
		for (let i = 0; i < selectedTestUserIds.length; i++) {
			flag = 0;
			for (let j = 0; j < candidates.length; j++) {
				if (
					selectedTestUserIds[i].testUser ===
					candidates[j].candidateId
				) {
					flag = 1;
					const matched = {
						Name:
							selectedTestUserIds[i].firstName +
							' ' +
							selectedTestUserIds[i].lastName,
					};

					temObj.push(matched);
				} else {
					currentClassId = candidates[j].classId;
				}
			}
			if (flag !== 1) {
				const unMatched = {
					testUser: selectedTestUserIds[i].testUser,
					class: currentClassId,
					subscription: subscription.id,
					adminid: currentUser.id,
				};
				permObj.push(unMatched);
			}
		}
		this.setState({
			exclusiveMembers: permObj,
			targetClass: currentClassId,
			inclusiveMember: temObj,
		});
	}

	render() {
		const {subscription} = this.props.currentUser;
		const {isAddingGroup} = this.state;
		const UsersGroups = this.props.userGroups;
		const groups = UsersGroups.map((userGroup) => {
			return userGroup.name;
		});
		const filteredTestUsers = this.props.selectedTestCandidates;
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{strings.createclass}</h3>
					<table id="testUsers_table" className="lit-list-user-list">
						<thead>
							<tr>
								<th className="lit-list-users-new-group-header">
									{strings.firstname +
										', ' +
										strings.lastname}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.email}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.telephone}
								</th>
								<th className="lit-list-users-new-group-header">
									{strings.gender}
								</th>
							</tr>
						</thead>
						<tbody>
							{filteredTestUsers.map((testUser) => {
								return (
									<tr
										className="lit-list__item"
										key={testUser.id}
									>
										<td className="lit-list-users-new-group-col">
											{testUser.firstName &&
												`${testUser.firstName}, ${testUser.lastName}`}
										</td>
										<td className="lit-list-users-new-group-col">
											{testUser.email}
										</td>
										<td className="lit-list-users-new-group-col">
											{testUser.phone}
										</td>
										{this.determineGender(
											testUser.id,
											this.props
												.batchedFilteredTestResults
										)}
									</tr>
								);
							})}
						</tbody>
					</table>
					{Boolean(this.props.adminClasses.length) && (
						<div className="lit-input-newgroup2">
							{this.state.isAddingCandidates &&
								!this.state.isDeletingCandidates && <div />}
							<div className="checkbox-wrapper-13">
								<input
									type="checkbox"
									className="user_checkbox2"
									name="velg gruppet"
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({
												isCreatingNewClass: false,
												isDeletingCandidates: false,
												isAddingCandidates: true,
											});
											this.setDefaultUser(
												filteredTestUsers,
												this.props.adminClasses,
												this.props.classData,
												this.props.currentUser
											);
										} else {
											this.setState({
												isCreatingNewClass: true,
												isDeletingCandidates: false,
												isAddingCandidates: false,
											});
										}
									}}
								/>
								<label>
									{'Hinzufügen zu einer bestehenden Klasse'}
								</label>
							</div>
						</div>
					)}

					{!this.state.isCreatingNewClass &&
						this.state.isAddingCandidates &&
						!this.state.isDeletingCandidates && (
							<div className="lit-input-newgroup-name">
								<select
									className="lit-input__field"
									/* defaultValue={
									this.props.adminClasses[0].className
								} */
									name="group"
									onChange={(event) => {
										this.excludeMembersIfExists(
											event.target.value,
											this.props.classData,
											filteredTestUsers,
											this.props.currentUser
										).then(() => {
											console.log('members-excluded');
										});
									}}
								>
									{this.props.classData.map((adminClass) => (
										<option
											key={adminClass.classId}
											id={adminClass.classId}
											value={adminClass.className}
											disabled={!adminClass.ownsClass}
										>
											{adminClass.className}
										</option>
									))}
								</select>
							</div>
						)}

					{!this.state.isCreatingNewClass &&
						this.state.isAddingCandidates &&
						!this.state.isDeletingCandidates && (
							<div className="lit-input_addUser">
								<button
									className={classNames('print-hide', {
										'lit-btn see-more': true,
										'bg-tertiary': true,
									})}
									onClick={() => {
										if (this.state.inclusiveMember.length) {
											let existingMembers = '';
											for (const property of this.state
												.inclusiveMember) {
												existingMembers +=
													JSON.stringify(
														Object.values(property)
													).replace(/[\[\]"]+/g, '') +
													', ';
											}
											window.alert(
												existingMembers +
													strings.alreadyexistsingroup
											);
										}

										if (
											this.state.exclusiveMembers.length
										) {
											this.props.onAddingCandidates(
												this.state.exclusiveMembers
											);
										}
									}}
								>
									{strings.insert}
								</button>
								<button
									className="lit-btn bg-negative2"
									onClick={() => {
										this.props.closeWindow();
									}}
								>
									{strings.cancel}
								</button>
							</div>
						)}

					{this.state.isCreatingNewClass &&
						!this.state.isAddingCandidates && (
							<div className="lit-input-newgroup-name">
								<input
									className="lit-tf_search"
									type="text"
									placeholder={strings.writeclassname}
									onChange={(e) => {
										const val = e.target.value;
										this.setState({className: val});
									}}
								/>
							</div>
						)}

					{this.state.isCreatingNewClass &&
						!this.state.isAddingCandidates && (
							<div className="lit-input_searchUser">
								<button
									className={classNames('print-hide', {
										'lit-btn see-more': true,
										'bg-tertiary': true,
									})}
									onClick={() => {
										if (this.state.className.length) {
											const className = this.state
												.className;
											const adminId = this.props
												.currentUser.id;
											const subsId = subscription.id;
											const usersIds = filteredTestUsers.map(
												(testUser) => testUser.id
											);

											this.props.onCreateClass(
												usersIds,
												adminId,
												subsId,
												className,
												this.state.groupId
											);
										} else {
											window.alert(
												strings.writegroupname
											);
										}
									}}
								>
									{strings.newclass}
								</button>
								<button
									className="lit-btn bg-negative2"
									onClick={() => {
										this.props.closeWindow();
									}}
								>
									{strings.cancel}
								</button>
							</div>
						)}
				</div>
			</div>
		);
	}
}
export default NewClassModal;
