import {ENDPOINT_URL} from '../constants/index';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

export default function softDeleteTest(id, testKey) {
	return fetch(
		`${ENDPOINT_URL}/test/${id}/softdelete`,
		getFetchOptions({
			method: 'PATCH',
			body: JSON.stringify(testKey),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
