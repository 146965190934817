import React from 'react';
import strings from '../../utils/strings';

class AddTestToGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groupId: null,
			isRemovingTestsFromGroup: false,
		};
		/* this.setAvailableTypes = this.setAvailableTypes.bind(this); */
	}
	componentDidMount() {}

	render() {
		const UsersGroups = this.props.userGroups;
		const groups = UsersGroups.map((userGroup) => {
			return userGroup.name;
		});
		const cancelButton = (
			<button
				className="lit-btn bg-negative2"
				onClick={() => this.props.closeWindow()}
			>
				{strings.cancel}
			</button>
		);
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					{Boolean(groups.length) && (
						<div className="lit-input inline">
							<label className="lit-input__label">
								{strings.removefromgroup}
							</label>
							<input
								type="checkbox"
								className="lit-input_checkbox"
								name="velg gruppet"
								onChange={(e) => {
									if (e.target.checked) {
										this.setState({
											isRemovingTestsFromGroup: true,
											groupId: UsersGroups[0].id,
										});
									} else {
										this.setState({
											groupId: 0,
											isRemovingTestsFromGroup: false,
										});
									}
								}}
							/>
						</div>
					)}
					<div>
						{!this.state.isRemovingTestsFromGroup && (
							<div>
								<div className="lit-input inline">
									<label className="lit-input__groupname-label">
										{strings.groupname}
									</label>
									<select
										className="lit-input__field"
										defaultValue={strings.chooseagroup}
										name="group"
										onChange={(event) => {
											var chosenGroup = UsersGroups.filter(
												(userGroup) =>
													userGroup.name ===
													event.target.value
											);

											this.setState({
												groupId: chosenGroup[0].id,
											});
										}}
									>
										{' '}
										<option
											key={1}
											value={strings.chooseagroup}
											disabled
										>
											{strings.chooseagroup}
										</option>
										{groups.map((group) => (
											<option key={group} value={group}>
												{group}
											</option>
										))}
									</select>
								</div>
								<div>
									<button
										className="lit-btn bg-primary"
										onClick={() => {
											this.props.onAddingTestToGroup(
												this.state.groupId,
												this.props.selectedTests
											);
										}}
										disabled={!this.state.groupId}
									>
										{strings.save}
									</button>
									{cancelButton}
								</div>
							</div>
						)}
						{this.state.isRemovingTestsFromGroup && (
							<div>
								<div className="lit-input">
									<br></br>
									<p
										style={{
											backgroundColor: 'white',
											textAlign: 'center',
										}}
									>
										Hvis testen er delt i en gruppe, vil
										dette fjerne testen fra gruppen den er
										lagt til.{' '}
									</p>

									<div style={{marginTop: '28px'}}>
										<button
											className="lit-btn bg-primary"
											onClick={() => {
												this.props.onRemovingFromGroups(
													/* this.state.groupId, */
													this.props.selectedTests
												);
											}}
										>
											{strings.save}
										</button>
										{cancelButton}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default AddTestToGroup;
