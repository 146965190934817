import {
	NEEDS_APPROVAL,
	AWAITING_CONFIRMATION,
	TRIAL,
	BASIC,
	NORMING,
	PRODUKSJON,
	PROD_NORMING,
	INACTIVE,
} from '../constants/index';

export default function getSubscriptionSummary(user) {
	const allLanguages = user.languages;
	let prodStatus = false;
	let normStatus = false;
	const searchAbleFields = [
		'child',
		'youth',
		'adult',
		'child_followup',
		'youth_followup',
		'adult_followup',
		'norming_youth',
		'norming_child',
		'norming_adult',
	];

	const {approved, registered, active, trial} = user;

	if (!approved) {
		return NEEDS_APPROVAL;
	}

	if (!registered) {
		return AWAITING_CONFIRMATION;
	}

	if (trial) {
		return TRIAL;
	}

	if (!active) {
		return INACTIVE;
	}

	for (let i = 0; i < allLanguages.length; i++) {
		for (const field of searchAbleFields) {
			if (allLanguages[i][field] === 1) {
				if (
					field === 'norming_child' ||
					field === 'norming_youth' ||
					field === 'norming_adult'
				) {
					normStatus = true;
				}
				if (
					field === 'child' ||
					field === 'youth' ||
					field === 'adult' ||
					field === 'child_followup' ||
					field === 'youth_followup' ||
					field === 'adult_followup'
				) {
					prodStatus = true;
				}
			}
		}
	}

	if (prodStatus && normStatus) {
		return PROD_NORMING;
	} else if (prodStatus && !normStatus) {
		return PRODUKSJON;
	} else if (!prodStatus && normStatus) {
		return NORMING;
	}

	return BASIC;
}
