/* eslint-disable complexity */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
import React from 'react';
import classNames from 'classnames';
import TestUserInfo from './TestUserInfo';
import Subtest from './Subtest';
import SubtestReport from './SubtestReport';
import SubtestAnswers from './SubtestAnswers';
import strings from '../../../utils/strings';
import getQuestionChoices from '../Utils/getQuestionChoices';
import moment from 'moment';

import '../../TestView/styles.scss';

import getSingleTestResult from '../../../api/getSingleTestResult';
import updateLogEvent from '../../../api/updateLogEvent';

import {withRouter} from 'react-router-dom';

import HorizontalBarChart from '../../../components/HorizontalBarChart';

import childWordchainAnswers from './child-wordchain-answers.json';
import childWordchainAnswersNn from './child-wordchain-answers-nn.json';
import test5Questions from './test5.json';
import test6Questions from './test6.json';
import test5youthQuestions from './test5youth.json';
import test5youthQuestionsNn from './test5youthNn.json';

import test5youthQuestionsDe from './test5YouthDe.json';
import test6youthQuestionsDe from './test6YouthDe.json';

import test6youthQuestions from './test6youth.json';
import test6youthQuestionsNn from './test6youthNn.json';

import test5childQuestions from './test5child.json';
import test5childQuestionsNn from './test5childNn.json';
import test6childQuestions from './test6child.json';
import test6childQuestionsNn from './test6childNn.json';
import test6childUpperQuestions from './test6childUpper.json';
import test6childUpperQuestionsNn from './test6childUpperNn.json';
import {timeSinceWCaltered} from '../../../constants';
import HandBook from './HandBook';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint} from '@fortawesome/free-solid-svg-icons';
import Assessment from './assessment';
class SingleTest extends React.Component {
	constructor() {
		super();
		this.state = {
			showTestUserInfo: false,
			testResult: null,
			testType: null,
			testId: null,
			testKey: null,
			showResultsTab: false,
			showReportTab: true,
			showInformationTab: false,
			showHandBookTab: false,
			showResultsActive: 'tablink1',
			showReportActive: 'tablink2-active',
			showInformationActive: 'tablink3',
			showHandBookActive: 'tablink4',

			birthDate: null,
			buttonTabs: [
				{
					id: 2,
					value: 'report',
					buttonClass: 'tablink2-active',
					text: 'Bericht',
				},
				{
					id: 'defaultOpen',
					value: 'result',
					buttonClass: 'tablink1',
					text: 'Ergebnisse',
				},
				{
					id: 3,
					value: 'info',
					buttonClass: 'tablink3',
					text: strings.userinformation,
				},
			],
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		window.scrollTo(0, 0);
		getSingleTestResult(id).then((testResult) => {
			const testType = testResult.type;
			const testUser = testResult.testUser;
			const birthDate = testUser.hasOwnProperty('birthDate')
				? ` Født: ${moment(testUser.birthDate).format('DD.MM.YYYY')}`
				: '';

			testResult.subtests.map((subtest) => {
				let questions = false;
				switch (testType) {
					case 'adult-screening':
						if (subtest.id === 5) {
							questions = test5Questions;
						} else if (subtest.id === 6) {
							questions = test6Questions;
						}
						break;

					case 'youth-screening':
						questions = this.setupQuestion(subtest, questions);
						break;
					case 'child-screening':
						const processedTestResult = this.reOrderChildScreening(
							testResult
						);

						const {testUser} = processedTestResult;
						const childGrade = testUser.education.youthGrade;

						if (subtest.id === 5 && subtest.language === 'de') {
							questions =
								test5childQuestions[childGrade.toString()];
						} else if (
							subtest.id === 5 &&
							subtest.language === 'nn'
						) {
							questions =
								test5childQuestionsNn[childGrade.toString()];
						} else if (
							subtest.id === 6 &&
							subtest.language === 'de'
						) {
							if (childGrade < 5) {
								questions = test6childQuestions;
							} else {
								questions = test6childUpperQuestions;
							}
						} else if (
							subtest.id === 6 &&
							subtest.language === 'nn'
						) {
							if (childGrade < 5) {
								questions = test6childQuestionsNn;
							} else {
								questions = test6childUpperQuestionsNn;
							}
						}
						break;
				}

				if (subtest.id === 4) {
					subtest.formattedAnswers = this.formatTypingSpeedAnswers(
						subtest.answers
					);
				} else if (subtest.id === 1 && testType === 'child-screening') {
					const {testUser} = testResult;
					const childGrade = testUser.education.youthGrade;

					subtest.formattedAnswers = this.getAnswersWithCorrectAnswerforWD(
						subtest.answers,
						subtest.answersCorrect,
						questions,
						childGrade
					);
				} else if (subtest.id === 2 && testType === 'child-screening') {
					subtest.formattedAnswers = this.getAnswersWithCorrectAnswerforWC(
						subtest.createdAt,
						subtest.answers,
						subtest.answersCorrect,
						childWordchainAnswers,
						childWordchainAnswersNn,
						questions,
						subtest.language
					);
				} else {
					subtest.formattedAnswers = this.getAnswersWithCorrectAnswer(
						subtest.answers,
						subtest.answersCorrect,
						questions
					);
				}

				return subtest;
			});
			this.setState({
				testResult,
				testId: testResult.id,
				testKey: testResult.key,
				testType: testType,
				testUser,
				birthDate,
			});
		});
	}

	setupQuestion(subtest, questions) {
		switch (subtest.id) {
			case 5:
				if (subtest.language === 'no') {
					questions = test5youthQuestions;
					return questions;
				}
				if (subtest.language === 'nn') {
					questions = test5youthQuestionsNn;
					return questions;
				}

				if (subtest.language === 'de') {
					questions = test5youthQuestionsDe;
					return questions;
				}

			case 6:
				if (subtest.language === 'no') {
					questions = test6youthQuestions;
					return questions;
				}
				if (subtest.language === 'nn') {
					questions = test6youthQuestionsNn;
					return questions;
				}
				if (subtest.language === 'de') {
					questions = test6youthQuestionsDe;
					return questions;
				}
		}
		return false;
	}
	reOrderChildScreening(testResult) {
		let allSubtests = [];

		for (let i = 0; i < testResult.subtests.length; i++) {
			switch (testResult.subtests[i].id) {
				case 3:
					allSubtests[0] = testResult.subtests[i];
					break;
				case 2:
					allSubtests[1] = testResult.subtests[i];
					break;
				case 4:
					allSubtests[2] = testResult.subtests[i];
					break;
				case 6:
					allSubtests[3] = testResult.subtests[i];
					break;
				case 5:
					allSubtests[4] = testResult.subtests[i];
					break;
				case 1:
					allSubtests[5] = testResult.subtests[i];
					break;
			}
		}
		if (allSubtests.length) {
			allSubtests = allSubtests.filter(function (element) {
				return element !== undefined;
			});

			testResult.subtests = allSubtests;
		}
		return testResult;
	}

	updatePrintLog(action) {
		updateLogEvent('print', this.state.testId, '', action);
	}

	formatTypingSpeedAnswers(answers) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			const answerArray = answers[i].split(' ');
			output.push(
				answerArray.map((answer, index) => {
					return {
						id: index,
						value: answer,
					};
				})
			);
		}
		return output;
	}

	getAnswersWithCorrectAnswer(answers, answersCorrect, questions) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			let jInit = 0;
			let correctAnswerAltValues = null;
			//Saves us some looping if arrays are arranged as expected
			if (
				answers[i] &&
				answersCorrect[i] &&
				answers[i].id === answersCorrect[i].id
			) {
				jInit = i;
			}
			if (typeof answers[i].id === 'undefined') {
				break;
			}
			const currentAnswer = answers[i];
			const currentAnswerValue = currentAnswer.value.toLowerCase().trim();
			for (let j = jInit; j < answersCorrect.length; j++) {
				const currentCorrectAnswer = answersCorrect[j];
				if (currentAnswer.id === currentCorrectAnswer.id) {
					const correctAnswerValue = currentCorrectAnswer.value
						.toLowerCase()
						.trim();

					if (
						answersCorrect[j] &&
						answersCorrect[j].hasOwnProperty('altValues')
					) {
						correctAnswerAltValues = answersCorrect[i].altValues;
					}
					const isCorrectAnswer =
						currentAnswerValue === currentCorrectAnswer.value ||
						currentAnswerValue === correctAnswerAltValues;

					const hasAltValues =
						Array.isArray(currentCorrectAnswer.altValues) ||
						correctAnswerAltValues;
					const isCorrectAltValue =
						hasAltValues &&
						currentCorrectAnswer.altValues.indexOf(
							currentAnswerValue
						) !== -1;

					const answer = {
						id: currentAnswer.id,
						value: currentAnswerValue,
						isCorrect: isCorrectAnswer || isCorrectAltValue,
						correctValues: [correctAnswerValue],
					};

					if (hasAltValues) {
						answer.correctValues = [correctAnswerValue].concat(
							currentCorrectAnswer.altValues
						);
					}

					if (questions) {
						answer.question = questions[currentAnswer.id - 1]
							? questions[currentAnswer.id - 1].question
							: '';
						answer.choices = getQuestionChoices(
							questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1]
								: ''
						);
					}

					output.push(answer);
					break;
				}
			}
		}
		return output;
	}

	getAnswersWithCorrectAnswerforWD(
		answers,
		answersCorrect,
		questions,
		childGrade
	) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			let jInit = 0;
			//Saves us some looping if arrays are arranged as expected

			if (
				answers[i] &&
				answersCorrect[i] &&
				answers[i].id === answersCorrect[i].id
			) {
				jInit = i;
			}
			if (typeof answers[i].id === 'undefined') {
				break;
			}
			var punctuation = /[\.,?!]/g;
			const currentAnswer = answers[i];
			const currentAnswerValue = currentAnswer.value
				.toLowerCase()
				.trim()
				.replace(punctuation, '');
			for (let j = jInit; j < answersCorrect.length; j++) {
				const currentCorrectAnswer = answersCorrect[j];
				if (currentAnswer.id === currentCorrectAnswer.id) {
					const correctAnswerValue =
						childGrade < 5
							? currentCorrectAnswer.value.toLowerCase().trim()
							: currentCorrectAnswer.value.toLowerCase().trim();

					const isCorrectAnswer =
						childGrade < 5
							? currentAnswerValue === currentCorrectAnswer.value
							: currentAnswerValue === currentCorrectAnswer.value;
					const hasAltValues = Array.isArray(
						currentCorrectAnswer.altValues
					);

					const answer = {
						id: currentAnswer.id,
						value: currentAnswerValue,
						isCorrect: isCorrectAnswer,
						correctValues: [correctAnswerValue],
					};

					if (hasAltValues) {
						answer.correctValues = [correctAnswerValue].concat(
							currentCorrectAnswer.altValues
						);
					}

					if (questions) {
						answer.question = questions[currentAnswer.id - 1]
							? questions[currentAnswer.id - 1].question
							: '';
						answer.choices = getQuestionChoices(
							questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1]
								: ''
						);
					}

					output.push(answer);
					break;
				}
			}
		}
		return output;
	}

	getAnswersWithCorrectAnswerforWC(
		createdAt,
		answers,
		answerCorrectBackEnd,
		answersCorrect,
		answerCorrectNn,
		questions,
		subtestLanauge
	) {
		const createdMoment = moment(createdAt);
		const correctAnswerFile =
			subtestLanauge === 'no' ? answersCorrect : answerCorrectNn;
		const output = [];
		if (createdMoment.isBefore(timeSinceWCaltered)) {
			for (let i = 0; i < answers.length; i++) {
				let jInit = 0;
				//Saves us some looping if arrays are arranged as expected
				if (
					answers[i] &&
					correctAnswerFile[i] &&
					answers[i].id === correctAnswerFile[i].id
				) {
					jInit = i;
				}
				if (typeof answers[i].id === 'undefined') {
					break;
				}
				const currentAnswer = answers[i];
				const currentAnswerValue = currentAnswer.value
					.toLowerCase()
					.trim();
				for (let j = jInit; j < correctAnswerFile.length; j++) {
					const currentCorrectAnswer = correctAnswerFile[j];
					if (currentAnswer.id === currentCorrectAnswer.id) {
						const correctAnswerValue = currentCorrectAnswer.value
							.toLowerCase()
							.trim();
						const isCorrectAnswer =
							currentAnswerValue === currentCorrectAnswer.value;

						const hasAltValues = Array.isArray(
							currentCorrectAnswer.altValues
						);
						const isCorrectAltValue =
							hasAltValues &&
							currentCorrectAnswer.altValues.indexOf(
								currentAnswerValue
							) !== -1;

						const answer = {
							id: currentAnswer.id,
							value: currentAnswerValue,
							isCorrect: isCorrectAnswer || isCorrectAltValue,
							correctValues: [correctAnswerValue],
						};

						if (hasAltValues) {
							answer.correctValues = [correctAnswerValue].concat(
								currentCorrectAnswer.altValues
							);
						}

						if (questions) {
							answer.question = questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1].question
								: '';
							answer.choices = this.getQuestionChoices(
								questions[currentAnswer.id - 1]
									? questions[currentAnswer.id - 1]
									: ''
							);
						}

						output.push(answer);
						break;
					}
				}
			}
		} else {
			for (let i = 0; i < answers.length; i++) {
				let jInit = 0;
				//Saves us some looping if arrays are arranged as expected
				if (
					answers[i] &&
					answerCorrectBackEnd[i] &&
					answers[i].id === answerCorrectBackEnd[i].id
				) {
					jInit = i;
				}
				if (typeof answers[i].id === 'undefined') {
					break;
				}
				const currentAnswer = answers[i];
				const currentAnswerValue = currentAnswer.value
					.toLowerCase()
					.trim();
				for (let j = jInit; j < answerCorrectBackEnd.length; j++) {
					const currentCorrectAnswer = answerCorrectBackEnd[j];
					if (currentAnswer.id === currentCorrectAnswer.id) {
						const correctAnswerValue = currentCorrectAnswer.value
							.toLowerCase()
							.trim();
						const isCorrectAnswer =
							currentAnswerValue === currentCorrectAnswer.value;

						const hasAltValues = Array.isArray(
							currentCorrectAnswer.altValues
						);
						const isCorrectAltValue =
							hasAltValues &&
							currentCorrectAnswer.altValues.indexOf(
								currentAnswerValue
							) !== -1;

						const answer = {
							id: currentAnswer.id,
							value: currentAnswerValue,
							isCorrect: isCorrectAnswer || isCorrectAltValue,
							correctValues: [correctAnswerValue],
						};

						if (hasAltValues) {
							answer.correctValues = [correctAnswerValue].concat(
								currentCorrectAnswer.altValues
							);
						}

						if (questions) {
							answer.question = questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1].question
								: '';
							answer.choices = this.getQuestionChoices(
								questions[currentAnswer.id - 1]
									? questions[currentAnswer.id - 1]
									: ''
							);
						}

						output.push(answer);
						break;
					}
				}
			}
		}
		return output;
	}

	returnTestUserGrade() {
		let testUserGrade = null;
		const {testUser} = this.state;
		if (
			['child-screening', 'youth-screening'].includes(this.state.testType)
		) {
			testUserGrade = (
				<>
					<br />
					{`${testUser.education.youthGrade}.${strings.grade}`}
					<br />
				</>
			);
		} else if (
			testUser.education.hasOwnProperty('highestCompletedEducation')
		) {
			const highestCompletedEducation = testUser.education
				.highestCompletedEducation
				? testUser.education.highestCompletedEducation
				: strings.notavailable;

			testUserGrade = (
				<>
					<br />
					{`${strings.education}: ${highestCompletedEducation}`}
					<br />
				</>
			);
		}
		return testUserGrade;
	}

	setRelevantTab(key, index) {
		const {buttonTabs} = this.state;
		for (let i = 0; i < buttonTabs.length; i++) {
			if (
				i === index &&
				!buttonTabs[index].buttonClass.includes('active')
			) {
				const counter = index + 1;
				buttonTabs[index].buttonClass = `tablink${counter}-active`;
			} else {
				const counter = i + 1;
				buttonTabs[i].buttonClass = `tablink${counter}`;
			}
		}
		switch (key) {
			case 'result':
				this.setState({
					showResultsTab: true,
					showReportTab: false,
					showInformationTab: false,
					showTestUserInfo: false,
					showHandBookTab: false,

					buttonTabs,
				});
				break;
			case 'report':
				this.setState({
					showResultsTab: false,
					showReportTab: true,
					showInformationTab: true,
					showTestUserInfo: false,
					showHandBookTab: false,

					showHandBookActive: 'tablink4',
					buttonTabs,
				});
				break;
			case 'info':
				this.setState({
					showResultsTab: false,
					showReportTab: true,
					showInformationTab: false,
					showTestUserInfo: true,
					showHandBookTab: false,

					buttonTabs,
				});
				break;
			case 'book':
				this.setState({
					showResultsTab: false,
					showReportTab: false,
					showInformationTab: false,
					showHandBookTab: true,
					showTestUserInfo: false,
					buttonTabs,
				});
				break;
		}
	}
	render() {
		const {showTestUserInfo, testResult, birthDate} = this.state;
		if (!testResult) {
			return null;
		}

		const {testUser} = testResult;

		const completeName = testUser.firstName
			? `${testUser.firstName} ${testUser.lastName}`
			: strings.hiddenname;
		const firstName = testUser?.firstName || '<Name verbergen>';
		return (
			<div className="lit-single-test">
				<div>
					<div
						className={
							this.state.testType === 'youth-screening'
								? 'lit-single-youthtest-title-desc'
								: 'lit-single-adulttest-title-desc'
						}
					>
						<h1 className="lit-page-title">
							{this.state.showHandBookTab
								? strings.formatString(
										strings.handbooktitle,
										<>
											<b>HÅNDBOK</b>
											<br />
											<br />
										</>
								  )
								: this.state.showResultsTab
								? strings.formatString(
										strings.dynamicheaderfortestresult_adult,
										this.state.testType ===
											'youth-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.youthscreening}</b>
											</>
										) : this.state.testType ===
										  'adult-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.adultscreening}</b>
											</>
										) : (
											<b>
												<br />
												<br />
												{
													'Dysmate - C (Screening-Test für Kinder))'
												}
											</b>
										)
								  )
								: this.state.showTestUserInfo
								? strings.formatString(
										strings.dynamicheaderfortestcandidate_adult,
										this.state.testType ===
											'youth-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.youthscreening}</b>
											</>
										) : this.state.testType ===
										  'adult-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.adultscreening}</b>
											</>
										) : (
											<>
												<b>
													<br />
													<br />
													{
														'Dysmate - C (Screening-Test für Kinder))'
													}
												</b>
											</>
										)
								  )
								: this.state.showReportTab
								? strings.formatString(
										strings.dynamicheaderfortestreport_adult,
										this.state.testType ===
											'youth-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.youthscreening}</b>
											</>
										) : this.state.testType ===
										  'adult-screening' ? (
											<>
												{' '}
												<br />
												<br />
												<b>{strings.adultscreening}</b>
											</>
										) : (
											<>
												<br /> <br />
												<b>
													{
														'Dysmate - C (Screening-Test für Kinder))'
													}
												</b>
											</>
										)
								  )
								: ''}
						</h1>
						{!this.state.showHandBookTab && (
							<p className="lit-page-lead">
								{strings.formatString(
									this.state.showResultsTab
										? strings.info_results_belowpresentsinformation
										: this.state.showTestUserInfo
										? strings.info_user_belowpresentsinformation
										: this.state.showReportTab
										? strings.info_report_belowpresentsinformation
										: '',
									<>
										<br />
										<br />
										<b>{completeName}</b>
										<br />{' '}
									</>,

									<span>{birthDate}</span>,
									<span>{this.returnTestUserGrade()}</span>
								)}
								{testResult.completedAt ? (
									strings.formatString(
										strings.info_testwascompletedon,
										<>
											<b>
												{moment(
													testResult.completedAt
												).format('DD.MM.YYYY')}
												.
											</b>
											<br />
										</>
									)
								) : (
									<>
										{strings.info_testwasNotcompletedon}{' '}
										<br />
									</>
								)}
								{strings.formatString(
									strings.infor_scr_ageAtCommencement,
									<b>{testUser.age.years}</b>,

									<b>{testUser.age.months}</b>
								)}
							</p>
						)}
					</div>

					<div className="lit-tab-wrapper">
						<div className="lit-results-line" />
						{this.state.buttonTabs.map((tab, index) => {
							const id = tab.id;
							const value = tab.value;
							const buttonClass = tab.buttonClass;
							const btnText = tab.text;

							// eslint-disable-next-line react/jsx-key
							return (
								<button
									className={buttonClass}
									id={id}
									key={index}
									value={value}
									disabled={buttonClass.includes('active')}
									onClick={(e) => {
										this.setRelevantTab(
											e.target.value,
											index
										);
									}}
								>
									{btnText}
								</button>
							);
						})}
					</div>

					{this.state.showResultsTab &&
						!this.state.showReportTab &&
						!this.state.showInformationTab && (
							<div className="lit-histogram-Test_Details">
								<div className="lit-printBtn">
									<button
										className={classNames('print-hide', {
											'lit-btn2 see-more': true,
											'bg-tertiary': true,
										})}
										onClick={() => {
											window.print();
											this.updatePrintLog('Results');
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-icon-btns"
											icon={faPrint}
										/>

										<p className="lit-btns-txt">
											{strings.formatString(
												strings.print,
												strings.testresult
											)}
										</p>
									</button>
								</div>
								<div className="lit-results-background">
									<div className="sub-test total print-summary">
										{!testResult.completedAt && (
											<div className="incomplete-notice">
												{strings.incompleteMessage}
											</div>
										)}

										<h3 className="sub-test__name big">
											{strings.formatString(
												strings.summary,
												strings.testresult
											)}
										</h3>
										<div className="summary-chart">
											<HorizontalBarChart
												subtests={testResult.subtests}
												testType={this.state.testType}
											/>
										</div>
										<div className="explanations">
											<div className="explanation">
												<div className="explanation__figure negative" />
												<p className="explanation__text">
													{strings.formatString(
														strings.zscorered,
														<em>{strings.under}</em>
													)}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure neutral" />
												<p className="explanation__text">
													{strings.zscoreblue}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure positive" />
												<p className="explanation__text">
													{strings.formatString(
														strings.zscoregreen,
														<em>{strings.over}</em>
													)}
												</p>
											</div>
										</div>
									</div>

									<div className="print-sub-tests">
										{testResult.subtests.map((subtest) => {
											return (
												<Subtest
													subtest={subtest}
													type={this.state.testType}
													testType={
														this.state.testType
													} //is going to be used for better rendering of gjennomsnitting og stadaravvik skåre
													testResult={
														this.state.testResult
													}
													key={subtest.id}
												>
													<SubtestAnswers
														subtest={subtest}
														viewModes={
															this.state.viewModes
														}
													/>
												</Subtest>
											);
										})}
									</div>
								</div>
							</div>
						)}

					{showTestUserInfo && (
						<TestUserInfo
							testResult={testResult}
							testType={this.state.testType}
						/>
					)}
					{this.state.showReportTab && !this.state.showTestUserInfo && (
						<div className="lit-histogram-Test_Report">
							<div className="lit-printBtn">
								<button
									className={classNames('print-hide', {
										'lit-btn2 see-more': true,
										'bg-tertiary': true,
									})}
									onClick={() => {
										window.print();
										this.updatePrintLog('Report');
									}}
								>
									<FontAwesomeIcon
										size="lg"
										className="lit-icon-btns"
										icon={faPrint}
									/>

									<p className="lit-btns-txt">
										{strings.formatString(
											strings.print,
											strings.testreport
										)}
									</p>
								</button>
							</div>
							<div className="lit-results-background">
								<div className="sub-test total print-summary">
									{!testResult.completedAt && (
										<div className="incomplete-notice">
											{strings.incompleteMessage}
										</div>
									)}

									<h3 className="sub-test__name big">
										{strings.formatString(
											strings.summary,
											strings.testreport
										)}
									</h3>
									<div
										style={{
											margin: '10px',
											padding: '20px',
										}}
									>
										<p
											className="explanation__text"
											style={{textAlign: 'center'}}
										>
											{strings.formatString(
												strings.summarypretext,
												<b style={{color: '#0f4256'}}>
													{completeName}
												</b>
											)}
										</p>
									</div>
									<div className="summary-chart">
										<HorizontalBarChart
											subtests={testResult.subtests}
											testType={this.state.testType}
										/>
									</div>
									<div className="explanations">
										<div className="explanation">
											<div className="explanation__figure negative" />
											<p className="explanation__text">
												{strings.formatString(
													strings.zscorered,
													<em>{strings.under}</em>
												)}
											</p>
										</div>
										<div className="explanation">
											<div className="explanation__figure neutral" />
											<p className="explanation__text">
												{strings.zscoreblue}
											</p>
										</div>
										<div className="explanation">
											<div className="explanation__figure positive" />
											<p className="explanation__text">
												{strings.formatString(
													strings.zscoregreen,
													<em>{strings.over}</em>
												)}
											</p>
										</div>
									</div>
									<div className="explanation">
										<p>
											<i>{strings.inforeferall}</i>
										</p>
									</div>

									<div className="print-sub-tests">
										<h2
											className="sub-test-Report__name"
											style={{color: '#0f4256'}}
										>{`Die Subtests des Screenings`}</h2>

										{testResult.subtests.map(
											(subtest, index) => {
												return (
													<SubtestReport
														subtest={subtest}
														key={subtest.id}
														order={index + 1}
														type={
															this.state.testType
														}
														testResult={
															this.state
																.testResult
														}
													/>
												);
											}
										)}
									</div>
									<div className="lit-report-assessment">
										<Assessment
											testResult={testResult}
											completeName={completeName}
											firstName={firstName}
										/>
									</div>

									<div className="sub-test__comment-page-breaker" />
									<div className="sub-test__comment">
										<h4 className="sub-test__heading big">
											<span className="comment">
												Kommentarer:
											</span>
											<div className="line line-1" />
											<div className="line line-2" />
											<div className="line line-3" />
											<div className="line line-4" />
										</h4>
									</div>
								</div>
							</div>
						</div>
					)}
					{this.state.showHandBookTab && <HandBook />}
				</div>
			</div>
		);
	}
}

export default withRouter(SingleTest);
