import {ENDPOINT_URL} from '../constants/index';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

export default function deleteSingleTestPermanently(
	testId,
	testType,
	allTestKeys
) {
	return fetch(
		`${ENDPOINT_URL}/tests/singlepermanentdelete`,
		getFetchOptions({
			method: 'DELETE',
			body: JSON.stringify({
				testId,
				testType,
				allTestKeys,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
