import React, {useEffect, useState} from 'react';

import Login from './pages/Login';
import Backoffice from './pages/Backoffice';
import Registration from './pages/Registration';
import Enrollment from './pages/Enrollment';
import NewLoginMethod from './pages/NewLoginMethod';
import MyTests from './pages/MyTests';
import Subscription from './pages/MySubscription';
import Users from './pages/Users';
import Groups from './pages/Groups';
import AllTests from './pages/AllTests';
import AllChildTests from './pages/AllChildTests';
import SingleScreeningTest from './pages/TestView/SingleScreeningTest';
import SingleFollowupTest from './pages/TestView/SingleFollowupTest';
import SingleSpeechTest from './pages/TestView/SingleSpeechTest';
import EventLog from './pages/EventLog';
import HandBook from './pages/TestView/SingleScreeningTest/HandBook';
import SearchByTestKey from './pages/SearchByTestKey';
import EmailPasswordLogin from './pages/EmailPasswordLogin';
import ControlPanel from './pages/ControlPanel';
import Notifications from './pages/Notifications';

import './styles.scss';

// import PermissionRoute from './components/PermissionRoute';
// import PERMISSIONS from './utils/permissions';

import {
	BrowserRouter as Router,
	Route,
	// Redirect,
	Switch,
} from 'react-router-dom';
import EnvironmentBanner from './components/EnvironmentBanner';
import {
	ENVIRONMENT,
	LANGUAGE,
	controlPanel,
	notifications,
} from './constants/index';
import strings from './utils/strings';

const App = () => {
	// const auth = useSelector((state) => state.auth);
	const [language, setLanguage] = useState();
	// const auth = useSelector((state) => state.auth);

	useEffect(() => {
		console.log(language);
		//Set up language switcher
		if (origin === 'http://localhost:3002') {
			setLanguage('en');
			strings.setLanguage('en');
		} else {
			setLanguage(LANGUAGE);
			strings.setLanguage(LANGUAGE);
			console.log('Language set', LANGUAGE);
		}
		console.log(language);
	});
	return (
		<div className="PageWrapper">
			<EnvironmentBanner environment={ENVIRONMENT} />{' '}
			<Router>
				<Switch>
					<Route path="/" exact component={Login} />
					<Route
						path="/set-psw/"
						render={(props) => (
							<Login
								{...props}
								selectedMethod="Password"
								customSelection="true"
							/>
						)}
					/>
					<Route path="/login/" component={Login} />
					<Route
						path="/custom-login"
						component={EmailPasswordLogin}
					/>
					{<Route path="/new-method/" component={NewLoginMethod} />}
					<Route path="/registration/" component={Registration} />
					<Route path="/enrollment/" component={Enrollment} />
					<Route path="/register/" component={Registration} />
					<Route
						path="/"
						render={() => (
							<Backoffice>
								<Route path="/my-tests" component={MyTests} />
								<Route
									path="/single-test/:id"
									component={SingleScreeningTest}
								/>
								<Route
									path="/single-child-test/:id"
									component={SingleFollowupTest}
								/>
								<Route
									path="/single-speech-test/:id"
									component={SingleSpeechTest}
								/>
								<Route
									path="/my-subscription"
									component={Subscription}
								/>
								<Route
									path="/subscription/:id"
									component={Subscription}
								/>
								<Route
									path="/users"
									render={() => (
										<div className="lit-users-view">
											<h1 className="lit-page-title">
												{}
											</h1>
											<Users />
										</div>
									)}
								/>
								<Route
									path="/groups"
									render={() => (
										<div className="lit-routes-div">
											<h1 className="lit-page-title">
												{strings.groups}
											</h1>
											<Groups />
										</div>
									)}
								/>

								<Route
									path="/all-child-scr-tests"
									render={() => (
										<div className="lit-routes-div">
											<h1 className="lit-page-title">
												{strings.childtests}
											</h1>
											<AllTests type="child-screening" />
										</div>
									)}
								/>
								<Route
									path="/all-tests"
									render={() => (
										<div className="lit-routes-div">
											<h1 className="lit-page-title">
												{strings.adulttests}
											</h1>
											<AllTests type="adult-screening" />
										</div>
									)}
								/>
								<Route
									path="/all-youth-tests"
									render={() => (
										<div className="lit-routes-div">
											<h1 className="lit-page-title">
												{strings.youthtests}
											</h1>
											<AllTests type="youth-screening" />
										</div>
									)}
								/>
								<Route
									path="/all-child-tests"
									render={() => (
										<div className="lit-routes-div">
											<h1 className="lit-page-title">
												{'Oppfølgingstester'}
											</h1>
											<AllChildTests />
										</div>
									)}
								/>
								<Route
									path={controlPanel}
									render={() => (
										<div className="lit-users-view">
											<ControlPanel />
										</div>
									)}
								/>
								<Route
									path={notifications}
									render={() => (
										<div>
											<Notifications />
										</div>
									)}
								/>
								<Route path="/event-log" component={EventLog} />
								<Route path="/bibliotek" component={HandBook} />
							</Backoffice>
						)}
					/>
				</Switch>
			</Router>
		</div>
	);
};

export default App;
