import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import create from '../../../../assets/create-24px.svg';
import './styles.scss';
import InformationPane from '../../../../components/InformationPane';
import 'react-datepicker/dist/react-datepicker.css';

import UserStageIndicator from '../../../../components/UserStageIndicator';
import _ from 'lodash';

import {
	NEEDS_APPROVAL,
	AWAITING_CONFIRMATION,
	EXPIRED_TRIAL,
	TRIAL,
	BASIC,
	ACTIVE,
	INACTIVE,
	USER_STAGES_INFO,
	USER_SUBSCRIPTIONS_INFO,
	NORMING,
	PRODUKSJON,
	PROD_NORMING,
} from '../../../../constants/index';

import isTrialActive from '../../../../utils/isTrialActive';
import {updateObject} from '../../../../utils/reducerUtils';
import strings from '../../../../utils/strings';

moment.locale('de');

const SUBSCRIPTION_TYPES = [ACTIVE, BASIC, INACTIVE];
const defaultChangeSubscription = false;
const defaultEditStatus = false;
const defaultAllSubscription = [];

class UserStageEditor extends React.Component {
	constructor() {
		super();

		this.state = {
			userStage: null,
			changeSubscription: defaultChangeSubscription,
			isLimited: false,
			isBasicChecked: false,
			activeBtnStatus: false,
			productionSubscriptions: [],
			normingSubscriptions: [],
			selectedSubscriptions: [],
			allSubscriptions: defaultAllSubscription,
			allLanguages: null,
			isEditing: defaultEditStatus,
			userLanguages: null,
			extractedLanguages: [],
			hasAvailableLanguage: false,
			isAddingNewLanguage: false,
			selectedAdditionalLanguage: [],
			initiateUserApproval: false,
			paneVariable: null,
			paneMessage: null,
		};
		this.handleChange = this.handleChange.bind(this);
		this.convertID = this.convertID.bind(this);
		this.finishSubscriptionAssignment = this.finishSubscriptionAssignment.bind(
			this
		);
		this.handleProductionSubscriptions = this.handleProductionSubscriptions.bind(
			this
		);
		this.handleBasicSubscriptions = this.handleBasicSubscriptions.bind(
			this
		);
		this.renderDynamicCheckboxes = this.renderDynamicCheckboxes.bind(this);
	}

	componentWillMount() {
		const {testAdmin, language} = this.props;
		const {userStage} = testAdmin;
		let finalUserStage = userStage;

		if (userStage === TRIAL && !isTrialActive(testAdmin)) {
			finalUserStage = EXPIRED_TRIAL;
		}

		this.setState({
			userStage: finalUserStage,
			allLanguages: _.cloneDeep(language),
			userLanguages: testAdmin.subscription.languages,
		});
		if (userStage === BASIC) {
			this.setState({isLimited: true});
		}
	}

	handleChange() {
		this.setState({
			isEditing: !this.state.isEditing,
		});
		this.setUplanguageSubscription();
	}

	setUplanguageSubscription() {
		let tempObj = [];
		let iteratableFileds = [
			'id',
			'child',
			'youth',
			'adult',
			'child_followup',
			'youth_followup',
			'adult_followup',
			'norming_child',
			'norming_youth',
			'norming_adult',
			'basic',
		];
		for (let i = 0; i < this.state.userLanguages.length; i++) {
			for (let j = 0; i < this.state.allLanguages.length; j++) {
				if (
					this.state.userLanguages[i].language ===
					this.state.allLanguages[j].language
				) {
					this.state.allLanguages[j].default = true;
					for (const field of iteratableFileds) {
						this.state.allLanguages[j][
							field
						] = this.state.userLanguages[i][field];
					}
					tempObj.push(this.state.allLanguages[j]);
					break;
				}
			}
		}
		this.setState({extractedLanguages: tempObj});

		if (tempObj.length !== this.state.allLanguages.length) {
			this.setState({hasAvailableLanguage: true});
		}
	}
	convertID(id) {
		if (id === 'Engelsk') return 'english';
		if (id === 'Svensk') return 'swedish';
		if (id === 'Norsk') return 'norwegian';
		if (id === 'Tysk') return 'german';
		if (id === 'Dutch') return 'dutch';
	}
	async finishSubscriptionAssignment(id) {
		const tempObj = [];
		const currentLanguage = id;

		const lanagugeFileds = ['Engelsk', 'Norsk', 'Svensk', 'Dutch', 'Tysk'];
		this.state.selectedSubscriptions.map((subscription) => {
			for (const field of lanagugeFileds) {
				if (
					subscription[field].subscriptionUpdate.language ===
					currentLanguage
				) {
					tempObj.push(subscription[field].subscriptionUpdate);
					break;
				}
			}
			if (tempObj) {
				this.setState({allSubscriptions: tempObj});
			}
		});
	}

	async handleProductionSubscriptions(target, flag, languageStatus) {
		let language = target.id;

		let iteratableFileds = [
			'child',
			'youth',
			'adult',
			'child_followup',
			'youth_followup',
			'adult_followup',
			'norming_child',
			'norming_youth',
			'norming_adult',
		];

		if (target.checked) {
			switch (language) {
				case language: {
					languageStatus[flag] = 1;
					for (const field of iteratableFileds) {
						USER_SUBSCRIPTIONS_INFO[language].subscriptionUpdate[
							field
						] = languageStatus[field];
					}
					this.setState({
						selectedSubscriptions: [
							...defaultAllSubscription,
							USER_SUBSCRIPTIONS_INFO,
						],
					});

					break;
				}
			}
		}
		if (!target.checked) {
			switch (language) {
				case language: {
					languageStatus[flag] = 0;
					for (const field of iteratableFileds) {
						USER_SUBSCRIPTIONS_INFO[language].subscriptionUpdate[
							field
						] = languageStatus[field];
					}
					this.setState({
						selectedSubscriptions: [
							...defaultAllSubscription,
							USER_SUBSCRIPTIONS_INFO,
						],
					});
					break;
				}
			}
		}
	}
	handleBasicSubscriptions(target, flag, languageStatus) {
		let language = target.id;
		let iteratableFileds = [
			'child',
			'youth',
			'adult',
			'child_followup',
			'youth_followup',
			'adult_followup',
			'norming_child',
			'norming_youth',
			'norming_adult',
		];
		if (target.checked) {
			languageStatus[flag] = 1;
			USER_SUBSCRIPTIONS_INFO[language].subscriptionUpdate[flag] = 1;
			for (const field of iteratableFileds) {
				USER_SUBSCRIPTIONS_INFO[language].subscriptionUpdate[field] = 0;
			}
			this.setState({
				isLimited: true,
				isBasicChecked: true,
				userStage: BASIC,
				selectedSubscriptions: [
					...defaultAllSubscription,
					USER_SUBSCRIPTIONS_INFO,
				],
			});
		} else {
			this.setState({
				isLimited: false,
				isBasicChecked: false,
			});
		}
	}

	renderDynamicCheckboxes(id, testAdmin, languageStatus) {
		const {isLimited} = this.state;

		return (
			<div className="checkout__expanded">
				<div className="lit_subscriptions-wrapper">
					<div className="main_production">
						<label className="lang_label">Production</label>

						<div className="one_main-options">
							<input
								className="user_checkbox"
								type="checkbox"
								defaultChecked={languageStatus.child}
								disabled={this.state.isLimited}
								id={id}
								onChange={(event) => {
									this.handleProductionSubscriptions(
										event.target,
										'child',
										languageStatus
									);
								}}
							/>
							<label className="lit-tests-subs_label">
								Child Tests
							</label>
						</div>

						<div className="one_main-options">
							<input
								className="user_checkbox"
								type="checkbox"
								defaultChecked={languageStatus.youth}
								disabled={isLimited}
								id={id}
								onChange={(event) => {
									this.handleProductionSubscriptions(
										event.target,
										'youth',
										languageStatus
									);
								}}
							/>
							<label className="lit-tests-subs_label">
								Youth Tests
							</label>
						</div>

						<div className="one_main-options">
							<input
								className="user_checkbox"
								type="checkbox"
								defaultChecked={languageStatus.adult}
								disabled={isLimited}
								id={id}
								onChange={(event) => {
									this.handleProductionSubscriptions(
										event.target,
										'adult',
										languageStatus
									);
								}}
							/>
							<label className="lit-tests-subs_label">
								Adult Tests
							</label>
						</div>

						<div className="one_main-options">
							<input
								className="user_checkbox"
								type="checkbox"
								defaultChecked={languageStatus.youth_followup}
								disabled={isLimited}
								id={id}
								onChange={(event) => {
									this.handleProductionSubscriptions(
										event.target,
										'youth_followup',
										languageStatus
									);
								}}
							/>
							<label className="lit-tests-subs_label">
								Youth Followup
							</label>
						</div>

						<div className="one_main-options">
							<input
								className="user_checkbox"
								type="checkbox"
								defaultChecked={languageStatus.adult_followup}
								disabled={isLimited}
								id={id}
								onChange={(event) => {
									this.handleProductionSubscriptions(
										event.target,
										'adult_followup',
										languageStatus
									);
								}}
							/>
							<label className="lit-tests-subs_label">
								Adult Followup
							</label>
						</div>
					</div>
					<div className="main_norming">
						<label className="lang_label">Norming</label>

						<div className="two-main_options">
							<input
								className="user_checkbox"
								type="checkbox"
								defaultChecked={languageStatus.norming_child}
								disabled={isLimited}
								id={id}
								onChange={(event) => {
									this.handleProductionSubscriptions(
										event.target,
										'norming_child',
										languageStatus
									);
								}}
							/>
							<label className="lit-tests-subs_label">
								Child Tests
							</label>
						</div>

						<div className="two-main_options">
							<input
								className="user_checkbox"
								type="checkbox"
								defaultChecked={languageStatus.norming_youth}
								disabled={isLimited}
								id={id}
								onChange={(event) => {
									this.handleProductionSubscriptions(
										event.target,
										'norming_youth',
										languageStatus
									);
								}}
							/>
							<label className="lit-tests-subs_label">
								Youth Tests
							</label>
						</div>

						<div className="two-main_options">
							<input
								className="user_checkbox"
								type="checkbox"
								defaultChecked={languageStatus.norming_adult}
								disabled={isLimited}
								id={id}
								onChange={(event) => {
									this.handleProductionSubscriptions(
										event.target,
										'norming_adult',
										languageStatus
									);
								}}
							/>
							<label className="lit-tests-subs_label">
								Adult Tests
							</label>
						</div>
					</div>
					<div className="basic_subscription">
						<div>
							<label className="lang_label">Other</label>
						</div>

						<div className="three-main_options">
							<input
								className="user_checkbox"
								type="checkbox"
								id={id}
								defaultChecked={isLimited}
								onChange={(event) => {
									this.handleBasicSubscriptions(
										event.target,
										'basic',
										languageStatus
									);
								}}
							/>
							<label
								className="lit-tests-subs_label"
								htmlFor="lang_basic"
							>
								Basic
							</label>
						</div>
					</div>
				</div>
				<div className="lit_assign-permission">
					<button
						className="lit-btn assign-permission_bg-secondary"
						type="button"
						id={id}
						onClick={() => {
							this.setState({allSubscriptions: []});
							const currentId = this.convertID(id);
							let subscriptionUpdateObject = null;
							const searchableFields = [
								'child',
								'youth',
								'adult',
								'child_followup',
								'youth_followup',
								'adult_followup',
								'norming_child',
								'norming_youth',
								'norming_adult',
								'basic',
							];
							this.finishSubscriptionAssignment(currentId)
								.then(() => {
									if (this.state.allSubscriptions.length) {
										for (const innerField of searchableFields) {
											if (
												this.state.allSubscriptions[0][
													innerField
												] !== 0
											) {
												subscriptionUpdateObject = updateObject(
													{
														id: testAdmin.id,
													},
													this.state
														.allSubscriptions[0]
												);

												if (languageStatus.default) {
													this.props.onUpdateLanguageSubscription(
														subscriptionUpdateObject
													);
												}
												if (!languageStatus.default) {
													this.props.onAssignLanguageSubscription(
														subscriptionUpdateObject
													);
												}

												break;
											}
										}
									}
									if (subscriptionUpdateObject !== null) {
									} else {
										alert(
											strings.message_notallowedemptysubscription
										);
									}
								})
								.then(() => {
									if (subscriptionUpdateObject !== null) {
										for (const innerField of searchableFields) {
											this.state.allSubscriptions[0][
												innerField
											] = 0;
										}
									}

									languageStatus.active = false;
								});
						}}
					>
						{strings.savepermission}
					</button>
				</div>
				<div className="lit_delete-permission">
					<button
						className="lit-btn delete-permission_bg-secondary"
						type="button"
						id={id}
						onClick={() => {
							if (
								window.confirm(strings.warning_deleteLanguage)
							) {
								this.props.onDeleteLanguage(languageStatus.id);
								this.setUplanguageSubscription();
							}
						}}
					>
						{strings.delete}
					</button>
				</div>
			</div>
		);
	}

	renderDynamicMessagePane(message, userData) {
		this.setState({
			initiateUserApproval: true,
			paneVariable: userData,
			paneMessage: message,
		});
	}

	render() {
		const {
			userStage,
			isEditing,
			activeBtnStatus,
			selectedAdditionalLanguage,
		} = this.state;
		const {onSaveSubscription, onExtendTrial, testAdmin} = this.props;

		let editorContent;
		switch (userStage) {
			case NEEDS_APPROVAL:
			case AWAITING_CONFIRMATION: {
				editorContent = (
					<div className="lit-input">
						<div>
							<button
								className="lit-btn bg-tertiary"
								onClick={() => {
									this.renderDynamicMessagePane(
										strings.message_confirmation,
										{
											id: testAdmin.id,
											approved: true,
										}
									);
								}}
								type="button"
							>
								{userStage === NEEDS_APPROVAL
									? strings.approveuser
									: strings.sendnewinvitation}
							</button>
						</div>
					</div>
				);
				break;
			}

			case BASIC:
			case ACTIVE:
			case NORMING:
			case PROD_NORMING:
			case PRODUKSJON:
			case TRIAL:
			case INACTIVE:
			case EXPIRED_TRIAL: {
				editorContent = (
					<form
						className="lit-input"
						onSubmit={(event) => {
							event.preventDefault();

							if (
								userStage === EXPIRED_TRIAL ||
								userStage === TRIAL
							) {
								return;
							}

							const userStageUpdateValues =
								USER_STAGES_INFO[userStage].subscriptionUpdates;
							const subscriptionUpdateObject = updateObject(
								{
									id: testAdmin.id,
								},
								userStageUpdateValues
							);

							onSaveSubscription(subscriptionUpdateObject);
						}}
					>
						<div className="lit-input">
							<div className="lit-input inline">
								<label
									className="lit-input__label wide"
									htmlFor="user-stage-editor-subscription-type"
								>
									{strings.edit}
								</label>
								<select
									className="lit-input__field"
									id="user-stage-editor-subscription-type"
									name="organization"
									onChange={(event) => {
										this.setState({
											userStage: event.target.value,
										});
									}}
									placeholder="Kongsbakken vgs"
									type="text"
									value={userStage}
								>
									{userStage === EXPIRED_TRIAL && (
										<option value={EXPIRED_TRIAL}>
											{strings.trialperiodexpired}
										</option>
									)}
									{userStage === TRIAL && (
										<option value={TRIAL}>
											{strings.trialperiodinprogress}
										</option>
									)}
									{SUBSCRIPTION_TYPES.map(
										(subscriptionType) => {
											const userStageInfo =
												USER_STAGES_INFO[
													subscriptionType
												];
											return (
												<option
													key={subscriptionType}
													value={subscriptionType}
												>
													{userStageInfo.text}
												</option>
											);
										}
									)}
								</select>
							</div>
							{isEditing && (
								<div className="lit-input__field">
									{this.state.extractedLanguages.map(
										(languages) => (
											<div className="lit-lang">
												<button
													className="lit-btn-lang-expander lit-bg-secondary"
													type="button"
													id={languages.id}
													name={languages.name}
													onClick={() => {
														if (!languages.active) {
															languages.active = true;
															languages.labelId =
																languages.lang;

															this.setState({
																activeBtnStatus: true,
																allSubscriptions: [],
															});
														} else if (
															languages.active
														) {
															languages.active = false;
															this.setState({
																activeBtnStatus: !activeBtnStatus,
															});
														}
													}}
												>
													{languages.active ? (
														<span>
															{languages.hideText}
														</span>
													) : (
														<span>
															{
																languages.revealText
															}
														</span>
													)}
												</button>
												{languages.active && (
													<div className="checkout">
														{this.renderDynamicCheckboxes(
															languages.labelId,
															testAdmin,
															languages
														)}
													</div>
												)}
											</div>
										)
									)}
									{this.state.isAddingNewLanguage && (
										<div className="lit-add-languages">
											{selectedAdditionalLanguage.map(
												(languages) => (
													<div className="lit-lang">
														<button
															className="lit-btn-lang-expander lit-bg-secondary"
															type="button"
															id={languages.id}
															name={
																languages.name
															}
															onClick={() => {
																if (
																	!languages.active
																) {
																	languages.active = true;
																	languages.labelId =
																		languages.lang;

																	this.setState(
																		{
																			activeBtnStatus: true,
																			allSubscriptions: [],
																		}
																	);
																} else if (
																	languages.active
																) {
																	languages.active = false;
																	this.setState(
																		{
																			activeBtnStatus: !activeBtnStatus,
																		}
																	);
																}
															}}
														>
															{languages.active ? (
																<span>
																	{
																		languages.hideText
																	}
																</span>
															) : (
																<span>
																	{
																		languages.revealText
																	}
																</span>
															)}
														</button>
														{languages.active && (
															<div className="checkout">
																{this.renderDynamicCheckboxes(
																	languages.labelId,
																	testAdmin,
																	languages
																)}
															</div>
														)}
													</div>
												)
											)}
										</div>
									)}
									<div className="lit-input inline">
										{this.state.hasAvailableLanguage && (
											<div className="lit_language-dropdown">
												<div className="lit_language-select">
													<select
														className="lit-input__field"
														id="user-stage-editor-assign-Language"
														name="Language"
														onChange={(event) => {
															let tempObj = [];
															for (
																let i = 0;
																i <
																this.state
																	.allLanguages
																	.length;
																i++
															) {
																if (
																	this.state
																		.allLanguages[
																		i
																	].lang ===
																	event.target
																		.value
																) {
																	tempObj.push(
																		this
																			.state
																			.allLanguages[
																			i
																		]
																	);
																	this.setState(
																		{
																			selectedAdditionalLanguage: tempObj,
																		}
																	);
																	break;
																}
															}
														}}
													>
														<option
															key={
																'dormant option'
															}
															value={
																'dormant option'
															}
															disabled="disabled"
															selected
														>
															{
																strings.addlanguage
															}
														</option>
														{this.state.allLanguages.map(
															(languages) => {
																if (
																	!languages.default
																) {
																	return (
																		<option
																			key={
																				languages.id
																			}
																			value={
																				languages.lanng
																			}
																		>
																			{
																				languages.lang
																			}
																		</option>
																	);
																}
															}
														)}
													</select>
												</div>
												<div className="lit-add_language">
													<button
														className="lit-btn add-language_bg-secondary"
														type="button"
														id={''}
														onClick={() => {
															this.setState({
																isAddingNewLanguage: true,
															});
														}}
													>
														{strings.confirm}
													</button>
												</div>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
						<div className="inline-button__wrapper">
							<button className="lit-btn bg-primary">
								{strings.savesubscription}
							</button>
							{(userStage === EXPIRED_TRIAL ||
								userStage === TRIAL) && (
								<button
									className="lit-btn bg-primary"
									onClick={(e) => {
										e.preventDefault();
										onExtendTrial(testAdmin.id);
									}}
								>
									{strings.extendtrialperiod}
								</button>
							)}
						</div>
					</form>
				);
				break;
			}
			default:
				throw new Error(`Invalid userStage`);
		}

		return (
			<div className="register-form">
				{this.state.initiateUserApproval && (
					<InformationPane
						paneMessage={this.state.paneMessage}
						variables={this.state.paneVariable}
						onApproval={(returnedProps) => {
							this.props.onSaveSubscription(returnedProps);
						}}
						onDisproval={() => {
							this.setState({
								initiateUserApproval: false,
							});
						}}
						onCloseWindow={() => {
							this.setState({
								initiateUserApproval: false,
							});
						}}
						category={'confirmation'}
					/>
				)}
				<h3>{strings.subscription}</h3>
				<div className="lit-input">
					<div className="lit-input inline">
						<label className="lit-input__label wide">
							{strings.currentstatus}
						</label>

						<UserStageIndicator user={testAdmin} withText />
						<button
							className="lit-img-button"
							onClick={this.handleChange}
						>
							{<img src={create} alt="" />}
						</button>
					</div>
				</div>
				{editorContent}
			</div>
		);
	}
}

UserStageEditor.propTypes = {
	onExtendTrial: PropTypes.func.isRequired,
	onSaveSubscription: PropTypes.func.isRequired,
	testAdmin: PropTypes.shape({}),
};

export default UserStageEditor;
