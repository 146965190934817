import {ENDPOINT_URL} from '../constants/index';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

export default function restartTest(id, key) {
	return fetch(
		`${ENDPOINT_URL}/test/restart`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				id,
				key,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			console.log(err);
			return Promise.reject(apiErrorHandling(err));
		});
}
