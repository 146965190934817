import {ALL} from '../constants/index';

export default function getFilteredTestResultsMonthBatches(
	testResultsMonthBatches,
	filters
) {
	const {testkey, gender, zScore, age} = filters;

	const testResultsFilterFn = (testResult) => {
		if (testResult.key.toLowerCase().indexOf(testkey) === -1) {
			return false;
		}

		if (testResult.zScore < zScore.from || testResult.zScore > zScore.to) {
			return false;
		}

		const {testUser} = testResult;
		if (testUser.age) {
			if (testUser.age.years < age.from || testUser.age.years > age.to) {
				return false;
			}
		}

		if (gender !== ALL && testUser.gender !== gender.id) {
			return false;
		}

		return true;
	};

	/* filteredTestAdmins = filteredTestAdmins.filter(
			testAdmin =>  {
				const searchableFields = ['firstName', 'lastName', 'phone', 'email', 'organization']

				for(const field of searchableFields) {
					if(testAdmin[field].toLowerCase().indexOf(this.state.searchFilter) !== -1) return true;
				}
				
				return false;
			}
		)   */

	return testResultsMonthBatches.map((testResultsMonthBatch) => {
		return {
			name: testResultsMonthBatch.name,
			testResults: testResultsMonthBatch?.testResults?.filter(
				testResultsFilterFn
			),
		};
	});
}
