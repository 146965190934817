/* eslint-disable camelcase */

export default {
	mytest: `Mein Test`,
	bibliotek: 'Bibliothek',
	myCandidates: `Meine Kandidaten`,
	createnewtest: `Neuen Test erstellen`,
	started: `Gestartet`,
	starttest: `Test beginnen`,
	all: `Alle`,
	chooseagroup: `Gruppe auswählen`,
	distributed: `Verteilt`,
	notdistributed: `Nicht verteilt`,
	notstarted: `Nicht gestartet`,
	completed: `abgeschlossen`,
	completeplural: `abgeschlossen:`,
	seeresults: `Ergebnisse ansehen`,
	status: `Status`,
	testkey: `Testschlüssel`,
	expiresIn: 'Läuft in',
	type: `Typ`,
	recipient: `Empfänger`,
	actions: `Aktionen`,
	send: `Senden`,
	resend: `Nochmals senden`,
	save: `Speichern`,
	sendcodeemail: `Code per E-Mail senden`,
	no_tests_available_message: `Sie haben keine Tests.`,
	trashcan: `Mülleimer`,
	january: `Januar`,
	february: `Februar`,
	march: `März`,
	april: `April`,
	may: `Mai`,
	june: `Juni`,
	juli: `Juli`,
	august: `August`,
	september: `September`,
	october: `Oktober`,
	november: `November`,
	december: `Dezember`,
	norway: `Norwegen`,
	sweden: `Schweden`,
	germany: `Deutschland`,
	england: `Großbritannien`,
	nederland: `Niederlande`,
	message_testcannotbedeleted: `Der Test muss mindestens 3 Monate alt sein, um gelöscht zu werden.`,
	warning_deletetest: `Der Test wird nicht länger für die Diagnostik verfügbar sein. Möchtest du fortfahren?`,
	headerchildtest: `Testergebnis für Dysmate – C (Test für Kinder)`,
	dynamicheaderfortestresult_child: `Detaillierte Ergebnisse für Dysmate {0}`,
	dynamicheaderfortestreport_child: `Detaillierter Bericht für Dysmate {0}`,
	dynamicheaderfortestcandidate_child: `Detaillierte Informationen für Dysmate Kandidat {0}`,
	dynamicheaderfortestresult_adult: `Detaillierte Ergebnisse für Dysmate {0}`,
	dynamicheaderfortestreport_adult: `Detaillierter Bericht für Dysmate {0}`,
	dynamicheaderfortestcandidate_adult: `Detaillierte Information für Dysmate Kandidat {0}`,
	headeradulttest: `Ergebnisse für Dysmate A (Screeningtest für Erwachsene)`,
	showbackground: `Zeige die Hintergrundinformationen des Kandidaten`,
	incompleteMessage: `Der Test ist nicht vollständig. Subtests fehlen oder wurden noch nicht begonnen.`,
	summary: `Zusammenfassung`,
	summarypretext: `Diese Abbildung zeigt die individuellen Ergebnisse von {0} in den Subtests des 
	Screenings im Vergleich zur Normstichprobe. Abgetragen sind die sog. Z-Werte 
	(standardisierte Werte; Mittelwert 0, Standardabweichung 1).`,
	printtestresults: `Ergebnisse drucken`,
	inforeferall: `Für das konkrete Profil des
	Schülers oder der Schülerin
	finden Sie im Folgenden die
	Ergebnisse der einzelnen
	Subtests des Screenings. Für
	eine übergreifende
	Veranschaulichung der
	Kategorisierung der Testwerte
	können Sie sich zusätzlich die
	unten abgebildete Graphik
	ansehen.`,
	zscorered: `Der Z-Wert liegt mehr als eine Standardabweichung {0} dem Mittelwert`,
	zscoregreen: `Der Z-Wert liegt mehr als eine Standardabweichung {0} dem Mittelwert`,
	zscoreblue: `Der Z-Wert liegt innerhalb des durchschnittlichen Bereichs`,
	overview: `Übersicht`,
	assessment: `Diagnostik: `,
	assessmentscreening: `Gesamtbewertung auf der Grundlage der Ergebnisse 
	aller Subtests aus dem Screening:`,
	assessmentfollowup: `Gesamtbewertungen auf der Grundlage der Ergebnisse 
	aller Subtests aus dem Follow-up`,
	assesmentgeneral: `In Dysmate werden zur Bewertung der Leistungen regressionsbasierte Normen 
	verwendet. Das bedeutet, dass Faktoren wie Geschlecht, Klassenstufe und 
	Familiensprache der Schülerinnen und Schüler berücksichtigt werden, um den 
	individuellen Testwert (errechnet aus der jeweiligen Rohwertsumme eines 
	Subtests) im Vergleich zur Bezugsgruppe zu setzen.`,
	assessmentspeechok: `Fazal {0} hat für den experimentellen Eine-Minute-Test Ergebnisse im normalen Bereich oder höher erzielt und scheint gute Lesefähigkeiten zu haben.`,
	assessmentspeechnotok: `Mehmood {0} hat für den experimentellen Eine-Minute-Test Ergebnisse unter dem normalen Bereich erzielt und sollte weiter auf Dyslexie und/oder Leseverständnisschwierigkeiten untersucht werden.`,
	asessmenttextok_1: ` Khan {0} hat für {1} der Subtests Ergebnisse im normalen Bereich oder höher erzielt und scheint gute Lese- und Schreibfähigkeiten zu haben.`,
	asessmenttextnotok_1: `Sher {0} hat für {1} der {2} Subtests Ergebnisse unter dem normalen Bereich erzielt und sollte weiter auf Dyslexie und/oder Leseverständnisschwierigkeiten untersucht werden.`,
	assesmenttextok_youthfollowup: `{0} zeigt keine typischen kognitiven Merkmale einer Lese-Rechtschreib-Schwierigkeit (LRS).`,
	assesmentok_recommendation_youthfollowup: ``,
	workingmemorylowassesment: `{0} erreicht im Subtest zum Arbeitsgedächtnis ein (stark) 
	unterdurchschnittliches Ergebnis. Schwache Leistungen in diesem Subtest sind 
	an sich kein kognitives Merkmal für eine Lese-Rechtschreib-Schwierigkeit (LRS), 
	dennoch werden schwache Fähigkeiten in diesem Bereich mit LRS als auch mit 
	Schwierigkeiten beim Lesesinnverständnis in Verbindung gebracht. Ein 
	vermindertes Arbeitsgedächtnis kann sich u. a. darauf auswirken, wie viele 
	Informationen aus einem gelesenen Text behalten werden können. Es wird daher 
	empfohlen zu untersuchen, inwieweit die eingeschränkte Kapazität des 
	Arbeitsgedächtnisses den Erwerb von neuem Lernstoff beeinträchtigt und ob 
	diesbezüglich eine schulische Anpassung erforderlich ist.`,

	rapidnominationlowassesment: `{0} erreicht im Subtest zum automatisierten Schnellbenennen ein (stark) 
	unterdurchschnittliches Ergebnis. Der Subtest zum automatisierten 
	Schnellbenennen prüft, wie schnell und genau {1} Wörter aus dem mentalen 
	Lexikon abrufen kann. Schwache Fähigkeiten in diesem Bereich sind ein 
	kognitives Merkmal für eine Lese-Rechtschreib-Schwierigkeit (LRS).`,

	oneminutelowassesment: `{0} erreicht im 1-Minute-Lesetest ein (stark) unterdurchschnittliches Ergebnis. 
	Der Eine-Minute-Lesetest misst die Anzahl der korrekt gelesenen Wörter pro 
	Minute und vermittelt ein gutes Bild der Dekodier-Fähigkeiten. Schwache 
	Dekodier-Fähigkeiten sind ein Anzeichen für Lese-Rechtschreib-Schwierigkeiten 
	(LRS)`,

	oneminutelowassesment2: `HINWEIS! Es wird empfohlen, die Ergebnisse des Eine-Minute-Lesetests in 
	Verbindung mit den Ergebnissen des Subtests zum Schreiben nach Diktat und 
	des Wortkettentests im DYSMATE-Screening zu betrachten.`,

	spoonerismlowassesment: `{0} erreicht im Subtest zur Phonologischen Bewusstheit ein (stark) 
	unterdurchschnittliches Ergebnis. Schwache Fähigkeiten in diesem Bereich sind 
	ein kognitives Merkmal für eine Lese-Rechtschreib-Schwierigkeit (LRS).`,

	rawscore: `Rohwert`,
	zscore: `Z-Wert`,
	percentilescore: `Prozentwert`,
	tscore: `T-Wert`,
	scaledscore: `Skalenwert`,
	standardscore: `Standard-Score`,
	theAverage: `Durchschnitt`,
	normalrange: `Normalbereich`,
	assessmentsubtestnormal: `Diese Punktzahl ist im Normalbereich.`,
	assessmentsubtestbelow: `Diese Punktzahl ist {0} Standardabweichungen {1} dem Durchschnitt.`,
	under: `unter`,
	assessmentsubtestabove: `Diese Punktzahl ist {0} Standardabweichungen {1} dem Durchschnitt`,
	over: `über`,
	within: 'innerhalb',
	workingmemorytest: `Arbeitsgedächtnis`,
	spoonerismtest: `Spoonerism`,
	'rapid test': `Rapid autonaming`,
	oneminutetest: `Eine-Minute-Test`,
	letterknowledgetest: `Letter knowledge`,
	phonemisolationtest: `Phoneme Isolation`,
	worddictationtest: `Schreiben nach Diktat`,
	phonemeomissiontest: `Phoneme omission test`,
	wordchaintest: `Wortketten Test`,
	nonwordtest: `Nonword test`,
	typingspeedtest: `Writing speed test`,
	readingtest: `Lesesinnverständnis`,
	wordcomprehensiontest: `Word comprehension`,
	loggin: `Einloggen`,
	loggout: `Ausloggen`,
	users: 'Benutzer',
	trialperiod: `Probezeit`,
	trialuser: `Testbenutzer`,
	activeSubscription: 'Aktives Abonnement',
	basicsupcription: `Basisabonnement`,
	fullsupscription: `Volles Abonnement`,
	childsubscription: `Dysmate-C - Abonnement`,
	adultsubscription: `Dysmate-A - Abonnement`,
	youthsubscription: `Dysmate-Y - Abonnement`,
	blocked: 'Gesperrt',
	name: `Name:`,
	firstname: `Vorname`,
	lastname: `Nachname`,
	dateofbirth: `Geburtsdatum:`,
	birthDate: `Geburtsdatum`,
	paneMessageContinueTest: `Dies wird einen neuen Testlink an den Kandidaten senden, sodass er einen nicht beendeten Test fortsetzen kann. Fortfahren?`,
	telephone: `Telefon`,
	blocked: 'Blokkert',
	blockuser: 'Benutzer sperren',
	unblockuser: 'Benutzer entsperren',
	email: `E-Mail`,
	edit: `Bearbeiten`,
	edituser: `Benutzer bearbeiten`,
	seesubscription: `Siehe Abonnement`,
	adulttests: `Dysmate-A (Test für Erwachsene)`,
	youthtests: `Dysmate-Y (Test für Jugendliche)`,
	confirmationofcost: `Bestätigung der Kosten`,
	confirmpassword: 'Bestätigen Sie Ihr Passwort',
	password: 'passwort',
	sex: `Geschlecht`,
	male: `männlich`,
	female: `weiblich`,
	education: `Ausbildung`,
	confirm: `Bestätigen`,
	age: `Alter`,
	age_specified: `Alter (Jahre)`,
	testdate: `Testdatum`,
	nousedtests: `Keine genutzten {0} Tests`,
	used: `Genutzt:`,
	boy: `Junge`,
	girl: `Mädchen`,
	logg: `Ereignisprotokoll`,
	of: `von {0}`,
	wordsperminute: `Wörter pro Minute`,
	deleteoldtests: `Alten Test löschen`,
	addtesttogroup: `Test zu einer Gruppe hinzufügen`,
	message_incompletData: `Ungültige oder unvollständige Daten`,
	newclasscreated: `Neue Klasse erstellt`,
	testcandidateremoved: `Der Testkandidat wurde aus der Klasse entfernt`,
	testcandidateadded: `Der Testkandidat wurde zu der Klasse hinzugefügt`,
	classexistchoosename: `Diese Klasse existiert bereits, bitte wähle einen anderen Namen.`,
	class: ` Klasse`,
	subscription: `Abonnement`,
	viewsubscriptiontable: `bonnement anzeigen`,
	supersubscription: `hat Abonnement`,
	mysubscription: `Mein Abonnement`,
	seetests: `Tests ansehen`,
	currencysymbol: `€`,
	hideWithArrow: `Ausblenden ▲`,
	yourtrialperiodhasexpired: `Deine Probezeit ist abgelaufen`,
	upgradeyoursubscription: `Upgrade Ihres Abonnements`,
	downloadandsendcontract: `Um mehrere Tests nutzen zu können, müssen Sie ein Abonnement abschließen. Besuchen Sie www.dysmate.de, um ein Abonnement zu bestellen. Preisinformationen und Bedingungen finden Sie unter www.dysmate.de.`,
	priceandconditioninfo: ``,
	downloadcontract: `Ein unverbindliches Angebot einholen`,
	onlytwotestspertrialperiod: `Während der Probezeit sind nur 2 Tests verfügbar`,
	upgradetofullsubscription: `upgrade to full subscription to issue new tests.`,
	fetch: `Wiederherstellen`,

	language: `Sprache`,
	child: `Kind`,
	childscreening: `Dysmate-C (Test für Kinder)`,
	childtests: `Dysmate-C (Test für Kinder)`,
	followup: 'Follow-Up',
	childfollowuptest: `Dysmate-C II (Followup Kinder)`,
	childnorming: `Normierung Kinder`,
	experimentalchildtest: `Experimental childtest`,
	youth: `Jugendliche(r)`,
	youthtest: `Dysmate-Y (Screening)`,
	youthscreening: `Dysmate-Y – (Screeningtest für Jugendliche)`,
	youthfollowup: `Dysmate-Y II (Followup Jugendliche)`,
	youthfollowuptest: `Dysmate-Y II (Followuptest Jugendliche)`,
	youthnorming: `Normierung Jugendliche`,
	adult: `Erwachsene(r)`,
	adulttest: `Dysmate-A (Screening)`,
	adultscreening: `Dysmate A – (Screeningtest für Erwachsene)`,
	adultfollowup: `Dysmate-A II (Followup Erwachsene)`,
	adultfollowuptest: `Dysmate-A II (Followuptest Erwachsene)`,
	adultnorming: `Normierung Erwachsene`,
	deletedon: `Deleted the`,
	basic: `Basis`,
	deletedtests: `Gelöschte Tests`,
	viewsubscription: `Abonnement ansehen ▼`,
	viewdeleted: `Gelöschte Tests ▼`,
	referalinformation: `persönliche Informationen`,
	referalgroups: `Mitgliedschaft in Gruppen`,
	mygroups: `Meine Gruppen`,
	myinformation: `Meine persönlichen Informationen`,
	message_costsforeachtestthatis_started: `Beachten Sie, dass je nach subscription, Kosten mit dem Verteilen neuer Tests verbunden sein können. Tests, die nicht genutzt werden, werden nicht in Rechnung gestellt.`,
	test_type: `Testtyp`,
	screeningtest_adult: `Dysmate-A (Screeningtest für Erwachsene)`,
	numberoftests: `Anzahl an Tests (max 30)`,
	create_tests: `Neue Tests erstellen`,
	login: `Einloggen`,
	cancel: `Abbrechen`,
	loginwithbankid: `Melden Sie sich mit den folgenden Methoden an`,
	message_thisisforcertifieduser: `Dies ist für zertifizierte Testadministratoren mit subscription`,
	bankid: `BankID`,
	bankidonmobile: `BankID for phones`,
	edittestadministrator: `Testadministrator bearbeiten`,
	personalinformation: `Persönliche Informationen`,
	shcool_or_company: `Bildungseinrichtung`,
	message_confirmation: `Hiermit eine Bestätigung an die E-Mail des/der Benutzer*in senden, um ihm/ihr Zugang zur Testphase zu gewähren.`,
	addanewtestadministrator: `Neuen Testadministrator hinzufügen`,
	addtestadministrator: `Testadministrator hinzufügen`,
	saveuserinformation: `Informationen über den Testkandidaten speichern`,
	notcompletedregistration: 'Registrierung nicht abgeschlossen',
	needsapproval: 'Genehmigung erforderlich',
	needsconfirmation: 'Genehmigung erforderlich',
	delete: `Löschen`,
	deleteuser: `Benutzer löschen`,
	currentstatus: `Aktueller Status:`,
	savesubscription: `Save subscription`,
	organization: `Organisation`,
	newloginmethod: `Neue Methode zum Einloggen`,
	errorhasoccured: `Ein Fehler ist aufgetreten`,
	message_singleuser_perks_trial: `Mit einem Benutzer kannst du eine kostenlose Probezeit von 30 Tagen und 2 kostenlose Tests von jedem Typ erhalten`,
	newuser: 'Neue:r Benutzer:in',
	message_change_loginmethod: `Sie sind dabei, Ihre Anmeldemethode zu ändern. Diese Methode wird zukünftig Ihre Standardanmeldemethode sein.`,
	info_for_professional_with_either_completed_screening_test: `Dies richtet sich an Fachleute, die entweder eine Zertifizierung abgeschlossen oder für einen entsprechenden Kurs angemeldet sind, und vor der endgültigen Anmeldung einige Tests durchführen möchten.`,
	createnewuser: 'Neue:n Benutzer:in erstellen',
	info_prerequisites_to_obtain_license: `Um Zugang zu der Probezeit zu erhalten, musst du dich für einen Dysmate Zertifikatskurs anmelden oder bereits einen Kurs abgeschlossen haben. Alle Benutzer werden manuell von Dysmate genehmigt, woraufhin sie eine E-Mail mit Instruktionen erhalten, um die Registrierung abzuschließen und Zugang zu erhalten.`,
	removefiltering: `Filter entfernen`,
	printreport: `Bericht`,
	print: '{0} drucken',
	testresult: 'Ergebnisseite',
	testreport: 'Bericht',
	hide: `Ausblenden`,
	show: `Anzeigen`,
	userinformation: `Informationen zu dem Kandidaten`,

	localizedwriteuptextforFullyNormalresults: `{0} erzielt insgesamt über alle Subtests hinweg ein Ergebnis, das innerhalb 
	oder (stark) über dem Durchschnitt im Vergleich zur Bezugsgruppe liegt. Dies 
	deutet darauf hin, dass {1} über gute bis sehr gute Lese- und Schreib- sowie 
	kognitive Fähigkeiten (Phonologische Bewusstheit, verbales Arbeitsgedächtnis) 
	verfügt.`,

	localizedwriteuptextforOneOtherSubTest:
		'{0} erzielt Ergebnisse, die es sind {1} {2} {3}. {4} {5} Zur weiteren Auswertung der Ergebnisse empfiehlt sich die Lektüre von {6}, Kapitel 5. Leitlinien zur Interpretation.',

	localizedwriteuptextforTwoOtherSubTest: `{0} {1}
		Da {2} bei den Subtests {3}, {4} und {5} (stark) 
		unterdurchschnittliche Ergebnisse erzielt, wird empfohlen, das Follow-Up als 
		vertiefenden Nachtest durchzuführen.`,

	localizedwriteuptextforThreeORMoreOtherSubTest: `{0} {1}
	Da {2} bei den Subtests {3}, {4}, {5} {6} {7} {8} (stark) 
	unterdurchschnittliche Ergebnisse erzielt, wird empfohlen, das Follow-Up als 
	vertiefenden Nachtest durchzuführen.`,

	localizedwriteuptextforIncompletedTest: `{0} hat nicht das gesamte Screening absolviert. Aus diesem Grund kann keine 
	Empfehlung zum weiteren Vorgehen ausgesprochen werden. Daher sollte das 
	Screening eventuell zu gegebener Zeit wiederholt werden.`,
	localizedwriteuptextforLowScoreOfOrdiktatWithOneOtherSubTest:
		'Rettskrivingsvansker kan være en indikasjon på dysleksi. {0} sitt resultat på Orddiktat taler for at det gjennomføres Dysmate oppfølgingstest.',
	localizedwriteuptextforLowScoreOfBetyledeWithOneOtherSubTest:
		'Resultatet på Byttelydtesten indikerer at det bør gjøres en nærmere kartlegging av {0} fonembevissthet og fonologisk prosesseringshastighet. Det anbefales derfor å gjennomføre Dysmate oppfølgingstest.',

	localizedwriteuptextforLowScoreInLeseprovenAndOrdforstaelse: `{0} {1}
	
	Da {2} bei den Subtests zum 
	Wortverständnis und zum Lesesinnverständnis (stark) unterdurchschnittliche 
	Ergebnisse erzielt, wird empfohlen, das Follow-Up als vertiefenden Nachtest 
	durchzuführen.`,
	localizedwriteuptextforLowScoreInLeseprovenAndOrdforstaelseFootnote: ` Alleinige Schwierigkeiten in den beiden Subtests zum Wortverständnis 
	und Lesesinnverständnis ohne weitere Schwierigkeiten sind KEIN Hinweis auf eine
	Lese-Rechtschreib-Schwierigkeit (LRS)! In diesem Falle sollten die allgemeinen 
	sprachlichen Fähigkeiten (bspw. den Wortschatz oder grammatische Fähigkeiten 
	betreffend) der Schülerin oder des Schülers untersucht werden, um eine 
	Sprachentwicklungsverzögerung- bzw. -störung abzuklären.`,
	localizedwriteuptextforLowScoreInLeseprovenAndOrdforstaelseReminder: ``,
	localizedwriteuptextforlowresults: `{0} hat für {1} der {2} Subtests Ergebnisse unter dem normalen Bereich erzielt und sollte weiter für Dyslexie und/oder Leseverständnisschwierigkeiten untersucht werden.`,
	localizedwriteuptextforNormalresults: `{0} erzielt insgesamt in den meisten Subtests hinweg ein Ergebnis, das innerhalb 
	oder (stark) über dem Durchschnitt im Vergleich zur Bezugsgruppe liegt. Dies 
	deutet darauf hin, dass {1} über gute bis sehr gute Lese- und Schreib- sowie 
	kognitive Fähigkeiten (Phonologische Bewusstheit, verbales Arbeitsgedächtnis) 
	verfügt.`,
	testreultsforliteratescreeningtest: `Testergebnisse für Dysmate Screeningtest`,

	info_results_belowpresentsinformation:
		'Nachfolgend finden Sie die Testergebnisse für {0}{1}{2}',
	info_report_belowpresentsinformation:
		'Nachfolgend finden Sie den Testbericht für {0}{1}{2}',
	info_user_belowpresentsinformation:
		'Nachfolgend finden Sie Kandidateninformationen für {0}{1}{2}',
	newinfo_belowpresentsinformation:
		'Nachfolgend finden Sie die Testergebnisse für {0}{1}{2}',
	newinfo2_belowpresentsinformation:
		'Nachfolgend finden Sie den Testbericht für {0}{1}{2}',
	newinfo3_belowpresentsinformation:
		'Nachfolgend finden Sie Kandidateninformationen für {0}{1}{2}',
	speech1_belowpresentsinformation:
		'Nachfolgend finden Sie die Testergebnisse für {0}, (Alder: {1}).',
	speech2_belowpresentsinformation:
		'Nachfolgend finden Sie den Testbericht für {0}, (Alder: {1} år).',
	infor_scr_ageAtCommencement:
		' Das Alter bei der Testdurchführung betrug {0} Jahre und {1} Monate.',
	speech3_belowpresentsinformation:
		'Nachfolgend finden Sie Kandidateninformationen für {0}, (Alter: {1} Jahre).',

	info_belowpresentsinformation: `Die Testergebnisse für {0}, Alter {1} Jahre and {2} Monate werden unten präsentiert. `,
	newinfo_belowpresentsinformation: `Testergebnisse für {0}, geboren am {1}, (Alter: {2} Jahre und {3} Monate) werden unten präsentiert. `,
	newinfo2_belowpresentsinformation: `Testergebnisse für {0} geboren am {1}. `,
	newinfo3_belowpresentsinformation: `Informationen zu dem Kandidaten {0}, geboren am {1}, (Alter: {2} Jahre und {3} Monate) werden unten präsentiert. `,
	speech1_belowpresentsinformation: `Testergebnisse für {0}, (Alter: {1}) werden unten präsentiert.`,
	speech2_belowpresentsinformation: `Testergebnisse für {0}, (Alter: {1}) werden unten präsentiert.`,
	speech3_belowpresentsinformation: `Informationen zu dem Kandidaten {0}, (Alter: {1} Jahre) werden unten präsentiert.`,
	info_testwascompletedon: `Der Test wurde abgeschlossen am {0}`,
	info_testwasNotcompletedon: `Der Test wurde noch nicht abgeschlossen.`,
	notetsfound: `Es wurden keine abgeschlossenen Tests gefunden`,
	background_info_testcandidate: `Hintergrundinformationen für den Testkandidaten`,
	candidate_answers: `Antworten von dem Kandidaten`,
	selectLanguage: `Sprache auswählen`,
	norwegian: `Norwegisch`,
	german: `Deutsch`,
	dutch: `Niederländisch`,
	english: `Englisch`,
	swedish: `Schwedisch`,
	zscore: `Z-Wert`,
	usersupport: `Unterstützung für Nutzer:innen`,
	groups: `Gruppen`,
	groupname: `Gruppenname`,
	date: `Datum`,
	insert: `Einfügen`,
	averageForGrade: ` Durchschnitt liegt.`,
	selected_users: `Ausgewählte Benutzer`,
	alreadyexistsingroup: `Existiert bereits in dieser Gruppe!`,
	orddiktatheading: `Dies ist ein Subtest zum Schreiben einzelner Wörter, in welchem geprüft wird, ob 
	Homophone korrekt geschrieben werden können. Jedes Wort wird zunächst in 
	einem Satz vorgestellt, um sicherzustellen, dass die Bedeutung des Wortes 
	richtig verstanden wird. Die Schülerin oder der Schüler wird dann aufgefordert, 
	das wiederholte Wort zu schreiben. Für diesen Subtest gibt es kein Zeitlimit. Die 
	Audio-Instruktion jedes Satzes und jedes Wortes kann einmal wiederholt werden.`,
	orddiktatchildexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 13 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	orddiktatvariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe liegt {2} Standardabweichungen  vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das`,
	orddiktatadultexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 45 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,

	orddiktatyouthexplanation: `Für jedes korrekt geschriebene Wort wird ein Punkt vergeben (maximal 40 
		Punkte = Rohwertsumme). Die erwartete Rohwertsumme, die eine 
		durchschnittliche Leistung von einem {0} der Klassenstufe {1} und mit der Familiensprache {2} abbildet, beträgt {3}, die Standardabweichung {4}.`,
	fonemisolasjonheading: `Dieser Test identifiziert die phonologische Bewusstheit der jüngsten Testkandidaten. In diesem Test werden dem Testkandidaten vier Bilder präsentiert. Der Testkandidat soll auf das Bild zeigen, welches entweder mit einem bestimmten Laut anfängt oder aufhört.`,
	fonemisolasjonexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 16 Punkte). Die erwartete Rohwertsumme, die eine durchschnittliche Leistung von einem <Jungen/Mädchen> der Klassenstufe {0} ist {1} und die Standardabweichung ist {2}.`,
	fonemisolasjonvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	fonemutelatelseheading: `Dieser Test identifiziert die phonologische Bewusstheit von Grundschülern. In diesem Test werden dem Testkandidaten verbal Wörter präsentiert. Der Testkandidat soll das Wort sagen, welches übrig bleibt, wenn ein bestimmter Laut entfernt wird (ie / p / in plane => lane)`,
	fonemutelatelseexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 16 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	fonemutelatelsevariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,

	spoonerismheading: `Mit diesem Subtest werden die Fähigkeit der Phonologische Bewusstheit im 
	engeren Sinne und die phonologische Verarbeitungsgeschwindigkeit geprüft. Der 
	Schülerin oder dem Schüler werden 20 Wortpaare mündlich dargeboten, und sie 
	oder er wird aufgefordert, den jeweils ersten Laut in den beiden Wörtern so 
	schnell und korrekt wie möglich zu vertauschen. Es entstehen entweder neue 
	Wort- oder Pseudowortpaare.`,
	spoonerismexplanation: `Für jedes korrekt produzierte neue (Pseudo-)Wort wird ein Punkt vergeben 
	(maximal 40 Punkte = Rohwertsumme). Die Rohwertsumme bildet die Anzahl der
	im Durchschnitt korrekt gelesenen Wörter pro Minute ab. Die erwartete 
	Rohwertsumme, die eine durchschnittliche Leistung von einem 
	{0} der Klassenstufe {1} und mit der Familiensprache 
	{2} abbildet, beträgt {3}, die 
	Standardabweichung {4}.`,
	spoonerismvariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das `,

	ettminuttstestheading: `Dies ist ein Subtest zum lauten Lesen, der die Fähigkeit des Dekodierens prüft. 
	Die Schülerin oder der Schüler wird aufgefordert, eine Liste von Wörtern laut 
	vorzulesen. Die Zeitbegrenzung für diesen Test beträgt eine Minute.`,
	ettminuttstestexplanation: `Die Rohwertsumme bildet die Anzahl der im Durchschnitt korrekt gelesenen 
	Wörter pro Minute ab. Die erwartete Rohwertsumme, die eine durchschnittliche 
	Leistung von einem {0} der Klassenstufe {1} und der 
	Familiensprache {2} abbildet, beträgt {3}, die Standardabweichung {4}.`,

	ettminuttstestvariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} also ein Ergebnis, das `,

	hurtigbenevningheading: `Mit diesem Subtest wird die Fähigkeit geprüft, Einträge aus dem mentalen 
	Lexikon zügig abzurufen. Die Schülerin oder der Schüler wird aufgefordert, 
	verschiedenfarbige Gegenstände schnell und präzise zu benennen. Die 
	Zeitbegrenzung für diesen Test beträgt vier Minuten.
	`,
	hurtigbenevningexplanation: `
		Für jeden korrekt benannten Gegenstand (korrekte Kombination aus Farbe und 
		Gegenstand) wird ein Punkt vergeben. Die Rohwertsumme bildet die Anzahl der 
		korrekt benannten Gegenstände geteilt durch die Gesamtzeit, die benötigt wird, 
		um alle 50 Gegenstände zu benennen. Die erwartete Rohwertsumme, die eine 
		durchschnittliche Leistung von einem {0} der Klassenstufe 
		{1} und mit der Familiensprache {2} abbildet, beträgt 
		{3}, die Standardabweichung {4}.`,

	hurtigbenevningvariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das `,

	arbeidsminnenummberheading: `Dieser Test identifiziert die Fähigkeit des Testkandidaten Informationen zu speichern und zu verarbeiten. In diesem Test wird dem Testkandidaten zunächst eine Folge von Zahlen, dann eine Folge von Wörtern präsentiert, die in umgekehrter Reihenfolge wiederholt werden sollen. Sowohl die Reihe an Zahlen als auch an Wörtern wird zunehmend länger, wenn der Testkandidat die vorherige Folge geschafft hat.`,
	arbeidsminneexplanation: `Für jede korrekt wiedergegebene Zahlen- oder Wortreihe wird ein Punkt 
	vergeben (maximal 12 Punkte für die Zahlenreihe und maximal 12 Punkte für die 
	Wortreihe, insgesamt also maximal 24 Punkte = Rohwertsumme). Die erwartete 
	Rohwertsumme, die eine durchschnittliche Leistung von einem 
	{0} der Klassenstufe {1} und mit der Familiensprache 
	{2} abbildet, beträgt {3}, die 
	Standardabweichung {4}.`,
	arbeidsminnevariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das `,
	arbeidsminneheading: `Mit diesem Subtest wird die Fähigkeit geprüft, verbale Informationen kurzzeitig 
	zu speichern, zu verarbeiten und wiederzugeben. Der Schülerin oder dem Schüler
	werden zunächst Reihen von Zahlen oder Wörtern vorgesprochen, die dann in 
	umgekehrter Reihenfolge wiederholt werden sollen. Sowohl die Zahlen- als auch 
	die Wortreihen werden im Verlauf des Subtests immer länger.`,
	ordforståelseheading: `Dieser Subtest erfasst den rezeptiven Wortschatz und die Ausdifferenziertheit 
	von im mentalen Lexikon gespeicherten semantischen Merkmalen 
	(Bedeutungen). Die Schülerin oder der Schüler wird aufgefordert, ein Wort aus 
	einer Auswahl an weiteren Wörtern zu finden, welches die gleiche bzw. eine sehr 
	ähnliche Bedeutung hat. Bei jeder Reihe soll die Schülerin oder der Schüler aus 
	vier Wörtern das korrekte Wort wählen. Die Zeitbegrenzung für diesen Test 
	beträgt zehn Minuten.`,
	ordforståelseexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 15 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	ordforståelsevariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das `,
	ordforståelseyouthexplanation: `Für jede korrekt gelöste Reihe wird ein Punkt vergeben (maximal 20 Punkte = 
		Rohwertsumme). Die erwartete Rohwertsumme, die eine durchschnittliche 
		Leistung von einem {0} der Klassenstufe {1} und der 
		Familiensprache {2} abbildet, beträgt {3}, die Standardabweichung {4}.`,
	leseprøvenheading: `Die ist ein Leiselesetest, der das Leseverständnis misst. Der Testkandidat muss Fragen direkt aus dem Text beantworten, Informationen aus verschiedenen Textabschnitten kombinieren und Schlüsse ziehen. Der Testkandidat hat 10 Minuten, um den Text zu lesen und möglichst viele Multiple-Choice Fragen zu beantworten. N.B. Hier ist es wichtig zu registrieren, ob der Testkandidat Multiple-Choice Fragen falsch beantwortet hat oder ob er sie gar nicht beantwortet hat (aufgrund einer langsamen Lesegeschwindigkeit).`,
	leseprøvenyouthheading: `Dies ist ein Subtest zum leisen Lesen, der das Lesesinnverständnis auf Textebene
	prüft. Die Schülerin oder der Schüler liest einen Text und wird aufgefordert, 
	Fragen zu beantworten. Die Antworten für die Fragen können entweder direkt aus
	dem Text entnommen werden oder erfordern das Ableiten von Informationen aus
	verschiedenen Abschnitten bzw. das Schlussfolgern. Alle Fragen sind nach dem 
	Single-Choice-Prinzip konzipiert (eine korrekte Antwort aus vier möglichen 
	Antworten). Die Zeitbegrenzung für diese Aufgabe beträgt zehn Minuten.`,

	leseprøvenexplanation: `Für jede korrekt beantwortete Frage wird ein Punkt vergeben (maximal 15 Punkte
		= Rohwertsumme). Die erwartete Rohwertsumme, die eine durchschnittliche 
		Leistung von einem {0} der Klassenstufe {1} und der 
		Familiensprache {2} abbildet, beträgt {3}, die Standardabweichung {4}.
		`,
	leseprøvenvariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das `,
	leseprøvenyouthexplanation: `Für jede korrekt beantwortete Frage wird ein Punkt vergeben (maximal 15 Punkte
		= Rohwertsumme). Die erwartete Rohwertsumme, die eine durchschnittliche 
		Leistung von einem {0} der Klassenstufe {1} und der 
		Familiensprache {2} abbildet, beträgt {3}, die Standardabweichung {4}.`,
	skrivehastighetheading: `Dieser Subtest prüft die Fähigkeit, schnell und präzise abzuschreiben und damit 
	orthographische Wortformen ganzheitlich aus dem mentalen Lexikon abzurufen. 
	Die Schülerin oder der Schüler sieht einen Satz und wird aufgefordert, diesen 
	innerhalb von zwei Minuten so oft und korrekt wie möglich abzuschreiben.
	`,
	skrivehastighetexplanation: `Die Rohwertsumme bildet die Anzahl der im Durchschnitt korrekt geschriebenen 
	Wörter pro Minute ab. Die erwartete Rohwertsumme, die eine durchschnittliche 
	Leistung von einem {0} der Klassenstufe {1} und der 
	Familiensprache {2} abbildet, beträgt {3}, die Standardabweichung {4}.`,
	skrivehastighetvariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das `,

	tulleordtestenheading: `Dies ist ein Subtest zum leisen Lesen, der die phonologische Bewusstheit und die 
	phonologische Verarbeitungsgeschwindigkeit prüft, die als entscheidende 
	linguistisch-kognitive Marker für Lese-Rechtschreib-Schwierigkeiten (LRS) gelten. 
	Die Schülerin oder der Schüler wird aufgefordert, ein Pseudohomophon daran zu 
	erkennen, dass es wie ein reales Wort klingt, obwohl es inkorrekt geschrieben ist.
	Bei jedem Pseudohomophon soll die Schülerin oder der Schüler aus fünf 
	möglichen Schreibungen die korrekte Schreibung auswählen. Die Zeitbegrenzung
	für diese Aufgabe beträgt zwei Minuten.`,

	tulleordtestenexplanation: `Für jedes korrekt erkannte Pseudohomophon wird ein Punkt vergeben (maximal 
		32 Punkte = Rohwertsumme). Die erwartete Rohwertsumme, die eine 
		durchschnittliche Leistung von einem {0} der Klassenstufe 
		{1} und mit der Familiensprache {2} abbildet, beträgt 
		{3}, die Standardabweichung {4}.`,

	tulleordtestenvariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das `,

	ordkjedetestenheading: `Dies ist ein Subtest zum leisen Lesen, der die schnelle Worterkennung und das 
	orthografische Wissen prüft. Hier wird die Schülerin oder der Schüler 
	aufgefordert, Zwischenräume zwischen die Wörter zu setzen, die in einer Kette 
	zusammengeschrieben sind. Jede Kette besteht aus vier Wörtern, die aus vier 
	verschiedenen Wortkategorien stammen (Nomen, Verben, Adjektive, 
	Funktionswörter). Alle Buchstaben sind großgeschrieben. Die Zeitbegrenzung für 
	diese Aufgabe beträgt vier Minuten.`,
	ordkjedetestenexplanation: `Für jede korrekt gelöste Wortkette wird ein Punkt vergeben (maximal 50 Punkte 
		= Rohwertsumme). Die erwartete Rohwertsumme, die eine durchschnittliche 
		Leistung von einem {0} der Klassenstufe {1} und der 
		Familiensprache {2} abbildet, beträgt {3}, die Standardabweichung {4}.`,
	ordkjedetestenvariabletext: `{0} hat in diesem Subtest eine Rohwertsumme von {1} erreicht. Diese Summe 
	liegt {2} Standardabweichungen vom Mittelwert für {3} Bezugsgruppe 
	entfernt. {4} erreicht also ein Ergebnis, das `,
	ordkjedetestenyouthexplanation: `Für jede korrekt gelöste Wortkette wird ein Punkt vergeben (maximal 50 Punkte 
		= Rohwertsumme). Die erwartete Rohwertsumme, die eine durchschnittliche 
		Leistung von einem {0} der Klassenstufe {1} und der 
		Familiensprache {2} abbildet, beträgt {3}, die Standardabweichung {4}.`,

	chooseagroup: `Gruppe auswählen`,
	includeLongIntro: `Beinhaltet Selbstbeurteilung / Anamnese`,
	anamneseexplaination: `Wenn der Test nur zur Voruntersuchung verwendet wird, ist es nicht notwendig, eine Selbstbewertung / Anamnese einzuschließen.e`,
	chooselanguage: `Sprache auswählen`,
	seegrouptests: `Tests in Gruppen ansehen`,
	addtogroup: `Zu Gruppe hinzufügen`,
	nogroupsfound: `keine Gruppe gefunden`,
	fromgroups: `aus Gruppe`,
	allmytests: `Alle meine Tests`,
	groupindicator: `-----Gruppen-----`,
	notanswered: `nicht beantwortet`,
	testnotanswered: `{0} Items wurden nicht beantwortet`,
	showunanswered: `Nicht beantwortete Items anzeigen`,
	hideunanswered: `Nicht beantwortete Items ausblenden`,
	rightanswer: `Richtige Antwort: {0}`,
	action: `Aktion`,
	deleteall: `Alle löschen`,
	chooseclass: `Klasse auswählen`,
	confirmationdeleteclass: `Bist du sicher, dass du {0} löschen möchtest`,
	theclass: `Klasse`,
	multipleclasses: `Klassen`,
	classGrade: 'Klassenstufe',
	deleteclass: `Klasse löschen`,
	message_noclasses: `Du hast noch keine Klassen erstellt`,
	showtests: `Tests ansehen ▼`,
	allmycandidates: `Alle meine Kandidaten`,
	notestsforcandidate: `Dieser Kandidat hat noch keine Tests abgeschlossen`,
	gender: `Geschlecht`,
	nocandidates: `Du hast keine Kandidaten`,
	choosecandidate: `Wähle einen Kandidaten`,
	classindicator: `-----Klassen-----`,
	sendtest: `Test senden`,
	createclass: `Klasse erstellen`,
	removefromclass: `Aus Klasse entfernen`,
	providevalidname: `Füge einen gültigen Namen hinzu`,
	searchhere: `Suche hier...`,
	newclass: `Neue Klasse`,
	writeclassname: `Gib der Klasse einen Namen…`,
	deleting: `Löschen`,
	mypage: `Meine Seite`,
	notavailable: `nicht verfügbar`,
	theagegroup: `Altersgruppe`,
	hiddenname: `<Name verbergen>`,
	wordreadcorrectly: `Richtig gelesene Wörter`,
	correct: `Richtig`,
	wrong: `Falsch`,
	timeused: `benötigte Zeit`,
	thisagegroup: `Altersgruppe`,
	adminpersonalinformation: `Persönliche Informationen`,
	consentText: `Der Vorgesetzte des Testkandidaten hat der Teilnahme an der Normierungsstudie zugestimmt.`,
	creategroups: `Gruppen erstellen`,
	createnewgroup: `Neue Gruppe erstellen`,
	writegroupname: `Gruppenname eingeben...`,
	deletegroup: `die Gruppe löschen`,
	editgroup: `Ändern Sie die Gruppe`,
	cannotaddexistingmembertogroup: `Mitglieder, die bereits in der Gruppe sind, können nicht hinzugefügt werden`,
	newgroup: `Neue Gruppe`,
	removefromgroup: `Aus Gruppen entfernen`,
	showearliermonths: `Siehe Vormonate ▼`,
	teststhismonths: `Test diesen Monat`,
	coststhismonth: `Kosten diesen Monat`,
	previousperiods: `Frühere Perioden`,
	period: `Zeitraum`,
	tests: `Anzahl der Tests`,
	statuses: `Status`,
	costs: `Kosten`,
	notestsafterdatetoinvoice: `Keine Tests nach ausgewähltem Rechnungsdatum`,
	accessNormingMakeTests: `Hat Zugriff zum Erstellen von Standardisierungstests`,
	accessProdMakeTests: `Sie haben Zugriff, um Produktionstests zu erstellen und Testergebnisse anzuzeigen`,
	averagezscore: `Durchschnittlicher Z-Wert`,
	childrenAndAdultTests: `Kinder- und Erwachsenentests`,
	childrenAndYouthTests: `Kinder- und Erwachsenentests`,
	childYouthAndAdultTests: `Kinder-, Jugend- und Erwachsenentests`,
	choosegroup: `wähle die Gruppe`,
	choose: `wählen`,
	classname: `Klassenname`,
	followuptest: `Nachuntersuchung`,
	followuptests: `Nachuntersuchungen`,
	myclasses: `Meine Klasse`,
	mytests: `Meine Tests`,
	norming: `Standardisierung`,
	normingtests: `Normative Prüfungen`,
	numberofcandidates: `Anzahl der Kandidaten`,
	selectedTestAddedToGroup: `Ausgewählte {0} zur Gruppe hinzugefügt`,
	selectedTestRemovedFromGroup: `Ausgewählte {0} aus der Gruppe entfernt`,
	singleChild: `Kinder`,
	testscompleted: `Test durchgeführt`,
	theAverage: `durchschnitt.`,
	undistributedTests: `Unverteilte Tests`,
	warning_candidatesfromdifferentcategori: `Haben einige Kandidaten bereits Tests aus einer anderen Kategorie absolviert. Trotzdem fortfahren?`,
	workingmemorytestNumbers: `Arbeitsgedächtnis (Zahlen)`,
	workingmemorytestWords: `Arbeitsgedächtnis (Wörter)`,
	readingTest_questioncount: 15,
	averageArea: 'Durchschnittsbereich',
	exportmailingaddress: 'Adressliste exportieren',
	onlyReadAccess: 'Har kun tilgang til å se testresultater',
	onlyTrialAccess: 'Hat nur Zugang zu 4 Tests',
	newclasscreated: 'Neue Klasse erstellt',
	fullname: 'Voller Name',
	sendnewinvitation: 'Einladung erneut senden',
	createclass: 'Klasse erstellen',
	hideWithArrow: 'Verbergen ▲',
	hide: 'Verbergen ',
	name: 'Name: ',

	searchByTestKey: 'Testschlüssel suchen',
	createNotification: 'Create a Notification',
	viewNotification: 'View Notifications',
	postNotification: 'Post Notification',
	areyousure: 'Are you sure?',
	notificationcreated: 'Varsel opprettet',
	allnotifications: 'Alle Notifizierungen',
	nonotificationsfound: 'Keine Notifizierung',
	createnotificationwarning:
		'Du kan ikke endre varselet når det først er lagt ut',
};
