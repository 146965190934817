export function formateTestName(testName) {
	switch (testName) {
		case 'ORDDIKTAT':
			return 'Wörter schreiben';

		case 'ORDKJEDETESTEN':
			return 'Wortketten trennen';
		case 'TULLEORDTESTEN':
			return 'Pseudohomophone erkennen';

		case 'SKRIVEHASTIGHET':
			return 'Einen Satz abschreiben';
		case 'LESEPRØVEN':
			return 'Einen Text verstehen';
		case 'ORDFORSTÅELSE':
			return 'Ähnliche Wörter finden';
	}

	return testName.charAt(0).toUpperCase() + testName.slice(1).toLowerCase();
}
