import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/de';

import Sidebar from '../../components/Sidebar';

import './styles.scss';

import {setCurrentUser} from '../../actions/index';

import getUserInfo from '../../api/getUserInfo';

import getUserStage from '../../utils/getUserStage';

import {TRIAL} from '../../constants/index';
import strings from '../../utils/strings';
class Backoffice extends React.Component {
	constructor() {
		super();

		this.fetchUserInfo = this.fetchUserInfo.bind(this);
	}
	componentDidMount() {
		this.fetchUserInfo();
	}
	fetchUserInfo() {
		const {handleSetCurrentUser} = this.props;

		getUserInfo()
			.then((user) => {
				let isLoggedIn = false;
				if (user.subscription.super) {
					user.isSuperAdmin = true;
					user.userStage = getUserStage(user);
					isLoggedIn = true;
				} else {
					user.isSuperAdmin = false;
					user.userStage = getUserStage(user);
					isLoggedIn = true;
				}
				const {unReadNotifications = []} = user;

				handleSetCurrentUser({
					...user,
					unReadNotificationsCount: unReadNotifications.length,
				});

				if (!isLoggedIn) {
					throw new Error('Unauthorized error');
				}
			})
			.catch(() => {
				window.location.href = '/login';
			});
	}
	render() {
		const {currentUser} = this.props;

		if (!currentUser) {
			return null;
		}
		const {isSuperAdmin} = currentUser;
		let trialExpiryText;

		const now = moment();
		if (currentUser.userStage === TRIAL) {
			const trialValidUntilMoment = moment(
				currentUser.subscription.trialValidUntil
			);

			if (trialValidUntilMoment.isAfter(now)) {
				trialExpiryText = `${strings.expiresIn} 
					${trialValidUntilMoment.fromNow(true)}`;
			} else {
				trialExpiryText = strings.expired;
			}
		}

		return (
			<div>
				<Sidebar
					isSuperAdmin={isSuperAdmin}
					currentUserInfo={currentUser}
				/>
				<div className="lit-view">
					<header className="lit-header">
						<div />
					</header>
					<div className="lit-view__content">
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

Backoffice.propTypes = {
	currentUser: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {
	handleSetCurrentUser: setCurrentUser,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Backoffice)
);
