/* eslint-disable no-nested-ternary */
import React from 'react';
import './styles.scss';
import moment from 'moment';
import strings from '../../utils/strings';
import getTestsForDump from '../../api/getTestsForDump'; //remove
import {STATUS, ALL, NOT_INVOICED, INVOICED} from '../../constants/index';
import deleteAllTestsPermanently from '../../api/deleteAllTestsPermanently';
import deleteSingleTestPermanently from '../../api/deleteSingleTestPermanently';
import Loader from '../../components/Loader';
var purgedKeysCollector = [];
const statusFilters = [STATUS, ALL, NOT_INVOICED, INVOICED];
class DumpTests extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeFilter: ALL,
			redundantTests: [],
			batchedFilteredTestResults: [],
			isMainChecked: false,
			selectedPurgedKeys: [],
			allKeys: null,
			hasChangedFilter: false,
			testsForDumpingLoading: true,
		};
	}
	componentDidMount() {
		this.getTestsReadyForDump();
	}

	getTestsReadyForDump() {
		return getTestsForDump().then((results) => {
			const tests = results;
			return new Promise((resolve) => {
				if (tests.length) {
					const {activeFilter} = this.state;
					let filteredEvents = [];
					if (activeFilter.id === 'all') {
						filteredEvents = tests;
					} else {
						if (activeFilter.id === 'invoiced') {
							filteredEvents = tests.filter((test) => {
								if (test.isInvoiced === true) {
									return test;
								}
							});
						}
						if (activeFilter.id === 'not_invoiced') {
							filteredEvents = tests.filter((test) => {
								if (test.isInvoiced === false) {
									return test;
								}
							});
						}
					}
					this.setState(
						{
							testsForDumping: tests,
							allKeys: tests,
							redundantTests: filteredEvents,
							testsForDumpingLoading: false,
							activeFilter: this.state.activeFilter,
						},
						resolve
					);
				} else {
					this.setState(
						{
							testsForDumping: [],
							allKeys: [],
							redundantTests: [],
							testsForDumpingLoading: false,
							activeFilter: this.state.activeFilter,
						},
						resolve
					);
				}
			});
		});
	}
	onSelectStatus = (e) => {
		const {redundantTests, hasChangedFilter} = this.state;
		let newHandle = !hasChangedFilter;
		const defaultPurgedTests = redundantTests.map((data) => {
			data.checked = false;
			return data;
		});

		this.setState(
			{
				activeFilter: statusFilters.filter(
					(filter) => filter.id === e.target.value
				)[0],
				selectedPurgedKeys: [],
				isMainChecked: false,
				redundantTests: defaultPurgedTests,
				hasChangedFilter: newHandle,
			},
			() => {
				const batchedFilteredTestResults = this.getFilteredTestResults(
					this.state.testsForDumping,
					this.state.activeFilter
				);
				return new Promise((resolve) => {
					this.setState(
						{
							redundantTests: batchedFilteredTestResults,
							batchedFilteredTestResults: batchedFilteredTestResults,
							isMainChecked: false,
							hasChangedFilter: newHandle,
						},
						resolve
					);
				});
			}
		);
	};

	getFilteredTestResults(AllTestsForDumping, activeFilter) {
		let testResults = [];
		if (activeFilter === ALL) {
			testResults = this.state.testsForDumping.map((tests) => {
				tests.checked = false;
				return tests;
			});
		}

		if (activeFilter.id === 'invoiced') {
			testResults = AllTestsForDumping.filter((tests) => {
				if (tests.isInvoiced === true) {
					tests.checked = false;
					return tests;
				}
			});
		}
		if (activeFilter.id === 'not_invoiced') {
			testResults = AllTestsForDumping.filter((tests) => {
				if (tests.isInvoiced === false) {
					tests.checked = false;
					return tests;
				}
			});
		}
		return testResults;
	}

	handleSingleCheck(target, test, index) {
		const {allKeys, redundantTests} = this.state;
		if (target.checked) {
			const indexVal = this.findWithProp(
				this.state.allKeys,
				'id',
				test.id
			);
			redundantTests[index].checked = true;
			allKeys[indexVal].checked = true;
		} else {
			const indexVal = this.findWithProp(
				this.state.allKeys,
				'id',
				test.id
			);
			allKeys[indexVal].checked = false;
			redundantTests[index].checked = false;
		}

		this.setState({allKeys, redundantTests});
	}

	findWithProp(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	setLoaderToFalse() {
		this.setState({
			testsForDumpingLoading: false,
		});
	}

	dumpAllTests(testsForDump) {
		const allTestKeys = testsForDump.map((testKeys) => {
			return {testKey: testKeys.keyString, type: testKeys.type};
		});
		return deleteAllTestsPermanently(allTestKeys).then(() => {
			this.getTestsReadyForDump();
		});
	}

	dumpSingleTest(testId, testType, testKey) {
		return deleteSingleTestPermanently(testId, testType, testKey).then(
			() => {
				this.getTestsReadyForDump();
			}
		);
	}
	render() {
		const {
			redundantTests,
			allKeys,
			isMainChecked,
			hasChangedFilter,
			testsForDumpingLoading,
		} = this.state;
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{'Slett gamle og slettede tester'}</h3>
					{testsForDumpingLoading && <Loader />}
					{!testsForDumpingLoading && (
						<div>
							<div className="lit-View_tests">
								<div className="lit-dropdown_filter">
									<select
										className="lit-input__field"
										onChange={(e) => {
											this.onSelectStatus(e);
										}}
									>
										{statusFilters.map((filters) => (
											<option
												key={filters.id}
												value={filters.id}
												disabled={
													filters.label ===
													strings.status
												}
											>
												{filters.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
					)}

					{Boolean(redundantTests.length) && (
						<div>
							<table className="lit-tests">
								<thead className="lit-old-tests-lists">
									<tr>
										{isMainChecked && (
											<div className="checkbox-wrapper-14">
												<input
													type="checkbox"
													className="lit_cb_users"
													id="main_checkbox"
													defaultChecked
													onChange={(e) => {
														if (e.target.checked) {
															const checkedPurgedTests = redundantTests.map(
																(data) => {
																	data.checked = true;
																	return data;
																}
															);

															purgedKeysCollector = checkedPurgedTests;
															this.setState({
																isMainChecked: true,
																selectedPurgedKeys: checkedPurgedTests,
																allKeys: checkedPurgedTests,
															});
														} else {
															const checkedPurgedTests = redundantTests.map(
																(data) => {
																	data.checked = false;
																	return data;
																}
															);

															this.setState({
																isMainChecked: false,
																selectedPurgedKeys: [],
																allKeys: checkedPurgedTests,
															});
														}
													}}
												/>
											</div>
										)}
										{!isMainChecked && (
											<div className="checkbox-wrapper-14">
												<input
													type="checkbox"
													className="lit_cb_users"
													id="main_checkbox"
													onChange={(e) => {
														if (e.target.checked) {
															const checkedPurgedTests = redundantTests.map(
																(data) => {
																	data.checked = true;
																	return data;
																}
															);

															purgedKeysCollector = checkedPurgedTests;
															this.setState({
																isMainChecked: true,
																selectedPurgedKeys: checkedPurgedTests,
																allKeys: checkedPurgedTests,
															});
														} else {
															const checkedPurgedTests = redundantTests.map(
																(data) => {
																	data.checked = false;
																	return data;
																}
															);

															this.setState({
																isMainChecked: false,
																selectedPurgedKeys: [],
																allKeys: checkedPurgedTests,
															});
														}
													}}
												/>
											</div>
										)}
										<th className="lit-test__header">
											{`${strings.name}`}
										</th>
										<th className="lit-test__header">
											{strings.testkey}
										</th>
										<th className="lit-test__header">
											{strings.type}
										</th>
										<th className="lit-test__header">
											{'Fakturert'}
										</th>
										<th className="lit-test__header">
											{strings.usedon}
										</th>
										<th className="lit-test__header">
											{strings.completed}
										</th>
										<th className="lit-test__header">
											{strings.reason}
										</th>
										<th className="lit-test__header">
											{strings.action}
										</th>
									</tr>
								</thead>
								<tbody className="lit-old-tests-lists">
									{redundantTests.map(
										(redundantTest, index) => {
											return (
												<tr
													className="lit-list__item"
													key={redundantTest.id}
												>
													{this.state
														.isMainChecked && (
														<th>
															<span className="checkbox-wrapper-13">
																<input
																	type="checkbox"
																	className="user_checkbox2"
																	defaultChecked
																	id={
																		redundantTest.id
																	}
																	key={
																		redundantTest.id
																	}
																	onChange={(
																		e
																	) => {
																		e.stopPropagation();
																		this.handleSingleCheck(
																			e.target,
																			redundantTest,
																			index
																		);
																	}}
																/>
															</span>
														</th>
													)}
													{!this.state
														.isMainChecked &&
														!hasChangedFilter && (
															<th>
																<span className="checkbox-wrapper-13">
																	<input
																		type="checkbox"
																		className="user_checkbox2"
																		id={
																			redundantTest.id
																		}
																		key={
																			redundantTest.id
																		}
																		defaultChecked={
																			redundantTest.checked
																		}
																		onChange={(
																			e
																		) => {
																			e.stopPropagation();
																			this.handleSingleCheck(
																				e.target,
																				redundantTest,
																				index
																			);
																		}}
																	/>
																</span>
															</th>
														)}

													{!this.state
														.isMainChecked &&
														hasChangedFilter && (
															<th>
																<span className="checkbox-wrapper-13">
																	<input
																		type="checkbox"
																		className="user_checkbox2"
																		id={
																			redundantTest.id
																		}
																		key={
																			redundantTest.id
																		}
																		defaultChecked={
																			redundantTest.checked
																		}
																		onChange={(
																			e
																		) => {
																			e.stopPropagation();
																			this.handleSingleCheck(
																				e.target,
																				redundantTest,
																				index
																			);
																		}}
																	/>
																</span>
															</th>
														)}

													<td className="lit-test__col">
														{!redundantTest.firstName ||
														!redundantTest.lastName
															? '--Slettet bruker---'
															: `${redundantTest.firstName}, ${redundantTest.lastName}`}
													</td>
													<td className="lit-test__col">
														{
															redundantTest.keyString
														}
													</td>
													<td className="lit-test__col">
														{redundantTest.type}
													</td>

													{redundantTest.isCompleted &&
													redundantTest.isInvoiced ? (
														<td className="lit-list__col">
															<div className="tickmark large">
																L
															</div>
														</td>
													) : !redundantTest.isCompleted ? (
														<td className="lit-list__col">
															<div className="crossmark large">
																./..
															</div>
														</td>
													) : (
														<td className="lit-list__col">
															<div className="crossmark large">
																X
															</div>
														</td>
													)}
													<td className="lit-test__col">
														{moment(
															redundantTest.usedAt
														).format('LL')}
													</td>
													{redundantTest.isCompleted ? (
														<td className="lit-list__col">
															<div className="tickmark large">
																L
															</div>
														</td>
													) : (
														<td className="lit-list__col">
															<div className="crossmark large">
																X
															</div>
														</td>
													)}
													{redundantTest.deleted ? (
														<td className="lit-test__col">
															{
																strings.testdeletedbyuser
															}
														</td>
													) : (
														<td className="lit-test__col">
															{
																strings.testolderthan3years
															}
														</td>
													)}
													<td className="lit-test__col">
														<button
															className="lit-btn bg-negative2"
															onClick={() => {
																if (
																	window.confirm(
																		strings.warning_deleteSingleTest
																	)
																) {
																	this.dumpSingleTest(
																		redundantTest.id,
																		redundantTest.type,
																		redundantTest.keyString
																	);
																}
															}}
														>
															{strings.delete}
														</button>
													</td>
												</tr>
											);
										}
									)}
								</tbody>
							</table>

							<div className="lit-deleteAll">
								<button
									className="lit-btn bg-negative2"
									onClick={() => {
										const newSelectedKeys = allKeys.filter(
											(tests) => {
												if (tests.checked === true) {
													return tests;
												}
											}
										);
										if (!newSelectedKeys.length) {
											alert('Du har ikke valgt noe!');
										} else if (
											window.confirm(
												strings.warning_deleteAllTest
											)
										) {
											this.dumpAllTests(newSelectedKeys);
										}
									}}
								>
									{strings.deleteall}
								</button>
							</div>
						</div>
					)}
					{Boolean(!redundantTests.length) &&
						!testsForDumpingLoading && (
							<div className="lit-model-no-tests-available-text">
								<p>{`Du har ingen < ${this.state.activeFilter.label} > tester`}</p>
							</div>
						)}
				</div>
			</div>
		);
	}
}

export default DumpTests;
