/* eslint-disable react/no-multi-comp */
import React, {Component} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import strings from '../../../utils/strings';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint} from '@fortawesome/free-solid-svg-icons';

const InfoRow = (props) => {
	// eslint-disable-next-line no-undefined
	if (props.condition !== undefined && !props.condition) {
		return null;
	}
	return (
		<p className="info__row">
			<span className="info__label">{props.name}</span>
			<span className="info__value">{props.children || props.value}</span>
		</p>
	);
};
class TestUserInfo extends Component {
	render() {
		const {testResult} = this.props;
		const {testUser} = testResult;
		const isTestOwner = testResult.isTestOwner;

		let completeName = '<Name verbergen>';

		if (testUser.firstName) {
			completeName = `${testUser.firstName} ${testUser.lastName}`;
		}

		return (
			<div>
				<div className="lit-input-information">
					<button
						className={classNames('print-hide', {
							'lit-btn2 see-more': true,
							'bg-tertiary': true,
						})}
						onClick={() => {
							window.print();
						}}
					>
						<FontAwesomeIcon
							size="lg"
							className="lit-icon-btns"
							icon={faPrint}
						/>

						<p className="lit-btns-txt">
							{strings.printInformation}
						</p>
					</button>
				</div>
				<div className="info">
					<InfoRow
						condition={isTestOwner}
						name={`Navn: `}
						value={completeName}
					/>
					<InfoRow
						condition={isTestOwner}
						name={strings.dateofbirth}
						value={`${moment(testUser.birthdate).format('LL')}`}
					/>
					<InfoRow
						name={strings.testdate}
						value={moment(testResult.completedAt).format('LL')}
					/>
					<InfoRow
						name={strings.age}
						value={`${testUser.age.years} Jahre und ${testUser.age.months} Monate`}
					/>
					<InfoRow name={strings.sex}>
						{testUser.gender === 'm' &&
							(testUser.age.years < 18
								? strings.boy
								: strings.male)}
						{testUser.gender === 'f' &&
							(testUser.age.years < 18
								? strings.girl
								: strings.female)}
						{testUser.gender === 'u' && 'Uspesifisert'}
					</InfoRow>

					<div className="info__group info__group--education">
						<h3>{strings.education}</h3>
					</div>
					{/* <InfoRow
						condition={isTestOwner}
						name={`Fylket: `}
						value={testUser.county}
					/> 
					<InfoRow
						condition={isTestOwner}
						name={`Kommune: `}
						value={testUser.municipality}
					/>
					<InfoRow
						condition={isTestOwner}
						name={`Skole: `}
						value={testUser.school}
					/>
					*/}
					<InfoRow name={`${strings.classGrade}: `}>
						{testUser.class === '0' && 'Siste år før skolestart'}
						{testUser.class === '1' && '1. klasse'}
						{testUser.class === '2' && '2. klasse'}
						{testUser.class === '3' && '3. klasse'}
						{testUser.class === '4' && '4. klasse'}
						{testUser.class === '5' && '5. klasse'}
						{testUser.class === '6' && '6. klasse'}
						{testUser.class === '7' && '7. klasse'}
						{testUser.class === '8' && '8. klasse'}
						{testUser.class === '9' && '9. klasse'}
						{testUser.class === '10' && '10. klasse'}

						{testUser.class === 'studiespesialisering' &&
							'Studiespesialisering'}
						{testUser.class === 'yrkesfag' && 'Yrkesfag'}
						{testUser.class === 'bachelor' && 'Bachelor'}
						{testUser.class === 'master' && 'Mastergrad'}
						{testUser.class === 'phd' && 'Doktorgrad (PhD)'}
					</InfoRow>
					<div className="info__group">
						<h3>{strings.homeLanugage}</h3>
						<InfoRow
							name={`Sind Sie in einem 
							ein- oder 
							zweisprachigen 
							Elternhaus 
							aufgewachsen? `}
						>
							{testUser.languagesInHome === 'one' &&
								'Einsprachig'}
							{testUser.languagesInHome === 'two' &&
								'Zweisprachig'}
							{testUser.languagesInHome === 'multiple' &&
								'Zweisprachig'}
						</InfoRow>
					</div>
				</div>
			</div>
		);
	}
}

export default TestUserInfo;
