import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {Link} from 'react-router-dom';

import getAllTestResults from '../../api/getAllTestResults';
import updateLogEvent from '../../api/updateLogEvent';

import {updateObject} from '../../utils/reducerUtils';

import getAgeLabel from '../../utils/getAgeLabel';
import addMomentsToTestResults from '../../utils/addMomentsToTestResults';
import getBatchedTestResults, {
	getBestTestResultMoment,
} from '../../utils/getBatchedTestResults';
import getFilteredTestResultsMonthBatches from '../../utils/getFilteredTestResultsMonthBatches';
import roundWithDecimals from '../../utils/roundWithDecimals';

import {
	GENDER_LABELS,
	Z_SCORE_OFFSET,
	ALL,
	MALE,
	FEMALE,
	MINIMUM_AGE,
	MAXIMUM_AGE,
} from '../../constants/index';

import './styles.scss';
import strings from '../../utils/strings';

const genderFilters = [ALL, FEMALE, MALE];

const defaultFilters = {
	gender: ALL,
	zScore: {
		from: -Z_SCORE_OFFSET,
		to: Z_SCORE_OFFSET,
	},
	testkey: '',
	age: {
		from: MINIMUM_AGE,
		to: MAXIMUM_AGE,
	},
};

class AllTests extends React.Component {
	constructor() {
		super();

		this.state = {
			batchedTestResults: [],
			lowerAgeLimit: 0,
			upperAgeLimit: 100,
			filters: defaultFilters,
		};
	}

	componentDidMount() {
		getAllTestResults(this.props.type).then((testResults) => {
			const testResultsWithMoments = addMomentsToTestResults(testResults);
			const batchedTestResults = getBatchedTestResults(
				testResultsWithMoments
			);
			this.setState({
				batchedTestResults,
			});
		});
	}
	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results');
	}

	render() {
		const {
			batchedTestResults,
			lowerAgeLimit,
			upperAgeLimit,
			filters,
		} = this.state;

		if (!batchedTestResults) {
			return null;
		}
		const months = getFilteredTestResultsMonthBatches(
			batchedTestResults,
			filters
		);

		return (
			<div className="lit-all-tests">
				<div className="lit-filters-row">
					<div className="lit-filters">
						<h4 className="lit-filters__heading">
							{strings.testkey}
						</h4>
						<input
							className="lit_txtf"
							type="text"
							placeholder="Testschlüssel schreiben... "
							id="testkey"
							name="testkey"
							onChange={(event) => {
								//const value = event.target.value;
								if (event.target.value === '') {
									this.setState({
										filters: defaultFilters,
									});
								} else {
									const newFilters = updateObject(filters, {
										testkey: event.target.value.toLowerCase(),
									});
									this.setState({
										filters: newFilters,
									});
								}
							}}
							value={filters.testkey}
						/>
					</div>
					<div className="lit-filters">
						<h4 className="lit-filters__heading">{strings.sex}</h4>
						{genderFilters.map((gender) => {
							return (
								<div className="lit-filter" key={gender.id}>
									<input
										checked={filters.gender === gender}
										className="lit-filter__field"
										id={gender.id}
										name="gender"
										onChange={() => {
											const newFilters = updateObject(
												filters,
												{gender}
											);

											this.setState({
												filters: newFilters,
											});
										}}
										type="radio"
									/>
									<label
										className="lit-filter__label"
										htmlFor={gender.id}
									>
										{gender.label}
									</label>
								</div>
							);
						})}
					</div>
					<div className="lit-filters lit-range">
						<h4 className="lit-filters__heading">
							{strings.zscore}
						</h4>
						<div>
							<h4 className="lit-range__from">{strings.from}</h4>
							<div className="lit-range__label">
								<input
									className="lit-range__input lit-range__number"
									max={filters.zScore.to}
									min={-Z_SCORE_OFFSET}
									onChange={(event) => {
										const value = event.target.value;
										const zScore = updateObject(
											filters.zScore,
											{
												from: value,
											}
										);
										this.setState({
											filters: updateObject(filters, {
												zScore,
											}),
										});
									}}
									step={0.1}
									type="number"
									value={filters.zScore.from}
								/>
							</div>
						</div>
						<div>
							<h4 className="lit-range__from">{strings.to}</h4>
							<div className="lit-range__label">
								<input
									className="lit-range__input lit-range__number"
									max={Z_SCORE_OFFSET}
									min={filters.zScore.from}
									onChange={(event) => {
										const value = event.target.value;
										const zScore = updateObject(
											filters.zScore,
											{
												to: value,
											}
										);
										this.setState({
											filters: updateObject(filters, {
												zScore,
											}),
										});
									}}
									step={0.1}
									type="number"
									value={filters.zScore.to}
								/>
							</div>
						</div>
					</div>
					<div className="lit-filters lit-range">
						<h4 className="lit-filters__heading">
							{strings.age_specified}
						</h4>
						<div>
							<h4 className="lit-range__from">{strings.from}</h4>
							<div className="lit-range__label">
								<input
									className="lit-range__input lit-range__number"
									max={filters.age.to}
									min={lowerAgeLimit}
									onChange={(event) => {
										const value = event.target.value;
										const age = updateObject(filters.age, {
											from: value,
										});
										this.setState({
											filters: updateObject(filters, {
												age,
											}),
										});
									}}
									step={1}
									type="number"
									value={filters.age.from}
								/>
							</div>
						</div>
						<div>
							<h4 className="lit-range__from">{strings.to}</h4>
							<div className="lit-range__label">
								<input
									className="lit-range__input lit-range__number"
									max={upperAgeLimit}
									min={filters.age.from}
									onChange={(event) => {
										const value = event.target.value;
										const age = updateObject(filters.age, {
											to: value,
										});
										this.setState({
											filters: updateObject(filters, {
												age,
											}),
										});
									}}
									step={1}
									type="number"
									value={filters.age.to}
								/>
							</div>
						</div>
					</div>
				</div>
				{filters !== defaultFilters && (
					<button
						className="lit-btn lit-btn--tiny lit-clear-filter bg-gray"
						onClick={() => {
							this.setState({
								filters: defaultFilters,
							});
						}}
					>
						{strings.removefiltering}
					</button>
				)}
				{months.length === 0 && <p>{strings.notetsfound}</p>}
				{months.map((month) => {
					if (!month.testResults?.length) {
						return null;
					}

					return (
						<div className="month" key={month.name}>
							<h3>
								{month.name === 'Invalid date'
									? 'Distribuert/ikke fulført'
									: month.name}
							</h3>
							<table className="lit-list">
								<thead>
									<tr>
										<th>{strings.testdate}</th>
										<th>{strings.zscore}</th>
										<th>{strings.testkey}</th>
										<th>{strings.sex}</th>
										<th>{strings.age}</th>
										<th>{strings.actions}</th>
									</tr>
								</thead>
								<tbody>
									{month.testResults.map((testResult) => {
										const {
											id,
											zScore,
											key,
											testUser: {gender, age},
										} = testResult;
										const time = getBestTestResultMoment(
											testResult
										);
										return (
											<tr
												className="lit-list__item"
												key={id}
											>
												<td>
													{moment(time).format('LL')}
												</td>
												<td>
													<h3 className="score">
														{roundWithDecimals(
															zScore,
															3
														)}
													</h3>
												</td>
												<td>
													{testResult.isNorming
														? `${key} (N)`
														: key}
												</td>

												<td>{GENDER_LABELS[gender]}</td>
												<td>{getAgeLabel(age)}</td>
												<td>
													<Link
														className="lit-btn lit-btn--small bg-secondary"
														to={`/single-test/${id}`}
														target="_blank"
														rel="noopener noreferrer"
														onClick={() =>
															this.handleClick(
																id,
																key
															)
														}
													>
														{strings.seeresults}
													</Link>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					);
				})}
			</div>
		);
	}
}

AllTests.propTypes = {
	type: PropTypes.string,
};

AllTests.defaultProps = {
	type: 'adult-screening',
};

export default AllTests;
