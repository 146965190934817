import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import queryString from 'query-string';

import './styles.scss';

import {
	BANK_ID,
	BANK_ID_MOBILE,
	FEIDE,
	USER_MESSAGE,
} from '../../constants/index';

import {initAuthAction, resetAuthAction} from '../../actions/index';
import strings from '../../utils/strings';
import EmailPasswordLogin from '../../pages/EmailPasswordLogin';
import Sidebar from '../../components/Sidebar';

const parsedSearch = queryString.parse(window.location.search);

const testKey = parsedSearch.key;
const authSuccess = parsedSearch.authSuccess;
function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
const methodsMap = {
	[BANK_ID]: {
		label: strings.bankid,
		primary: !Boolean(testKey),
	},
	[BANK_ID_MOBILE]: {
		label: strings.bankidonmobile,
	},
	[FEIDE]: {
		label: strings.feide,
		recommended: Boolean(testKey),
	},
	Password: {
		label: 'Passwort',
	},
};
const sortMethods = (a, b) => {
	const recommended =
		Boolean(methodsMap[b].recommended) - Boolean(methodsMap[a].recommended);

	if (recommended !== 0) return recommended;

	return Boolean(methodsMap[b].primary) - Boolean(methodsMap[a].primary);
};
const primaryClass = (method) =>
	methodsMap[method].primary || methodsMap[method].recommended
		? ''
		: ' bg-border';
const getLoginMethods = () => {
	switch ('se') {
		case 'no': {
			return [BANK_ID, BANK_ID_MOBILE, FEIDE, 'Password'];
		}
		case 'en':
		default: {
			return ['Password'];
		}
	}
};

const loginMethods = getLoginMethods();
class Login extends React.Component {
	constructor() {
		super();
		this.state = {
			customSelection: false,
			selectedMethod: null,
		};
	}
	componentDidMount() {
		var receiveMessage = function (evt) {
			if (evt.data === 'MM:3PCunsupported') {
				document.getElementById('lit-site-warning').innerHTML = '';
				document.getElementById('lit-site-warning_text').innerHTML = '';
				document.getElementById(
					'lit-site-settings-instructions'
				).innerHTML = strings.linkForInstructions;
				document.getElementById('lit-site-warning_text_end').innerHTML =
					'';
			} else if (evt.data === 'MM:3PCsupported') {
				document.getElementById('result').innerHTML = '';
			}
		};
		window.addEventListener('message', receiveMessage, false);

		if (authSuccess) {
			let data = atob(
				decodeURIComponent(
					window.location.search.substr(
						window.location.search.indexOf('=') + 1
					)
				)
			);

			if (data && IsValidJSONString(data)) {
				return new Promise((resolve, reject) => {
					data = JSON.parse(data);

					if (data.authError) {
						reject({message: 'There was an error logging you in'});
						return;
					}

					if (
						data &&
						data.action === 'auth' &&
						data.result === 'OK'
					) {
						localStorage.setItem('authtoken', data.authToken);

						window.location.href = `/my-tests`;
						resolve();
						return;
					}
					reject({message: `Kunne ikke logge inn!`});
				});
			} else {
				return;
			}
		}
	}

	render() {
		const {
			error,
			iframeUrl,
			initAuth,
			isInit,
			isFeide,
			authMethod,
		} = this.props;
		const {customSelection} = this.state;
		const showIframe = isInit && iframeUrl;
		const loginClass = testKey ? 'lit-login-register' : 'lit-login';
		if (showIframe && isFeide) window.location.href = `${iframeUrl}`;
		const isLoginPage = Boolean(
			window.location.pathname === '/login' || '/'
		);

		return (
			<div className="lit-login-page">
				<Sidebar isLoginPage={isLoginPage} />

				<div className="lit-login-view-content">
					<p className="lit-thirdPCsupport" id="result" />

					<div className="lit-thrid-party-settings">
						<p
							className="candidate-start-fail__instructions"
							style={{
								color: 'orange',
								margin: 'auto',
								textAlign: 'center',
								fontSize: '18px',
							}}
						>
							<span style={{color: 'Red'}}>
								<b>
									<span id="lit-site-warning"></span>{' '}
								</b>
							</span>
							<em>
								<span id="lit-site-warning_text"></span>
							</em>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.dysmate.no/advarsel-2/"
							>
								<u style={{color: 'white'}}>
									<span id="lit-site-settings-instructions"></span>
								</u>
							</a>
							<em>
								<span id="lit-site-warning_text_end"></span>
							</em>
						</p>
					</div>

					{USER_MESSAGE && (
						<div
							style={{
								backgroundColor: 'white',
								width: '42%',
								padding: '5px',
								margin: 'auto',
								marginTop: '80px',
								marginBottom: '-100px',
								border: 'solid white 1px',
								borderRadius: '20px',
								justifyContent: 'center',
								boxShadow: '3px 4p 6px 3px blue',
								display: 'none',
							}}
						>
							<span>
								<p
									style={{
										fontSize: '17px',
										textAlign: 'center',
									}}
								>
									8.mai 2024 har vi lansert noen
									produktforbedringer. Les mer{' '}
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://www.dysmate.no/tavla/produktforbedringer-8-mai/"
									>
										<u>
											<i>{' her'}</i>
										</u>
									</a>{' '}
								</p>
							</span>
						</div>
					)}

					{error && testKey && (
						<div
							className="lit-error-wrapper"
							style={{
								width: '60%',
								margin: 'auto',
								padding: '15px',
								backgroundColor: 'white',
								border: 'solid white thin',
								borderRadius: '10px',
								alighText: 'center',
							}}
						>
							{error === 'Unauthorized' && (
								<span className="error">
									<span style={{color: 'red'}}>
										<strong>
											<i>{`Uautorisert:`}</i>
										</strong>
									</span>
									<span>{` Registreringsnøkkel`}</span>{' '}
									<span
										style={{color: 'darkblue'}}
									>{`${testKey}`}</span>{' '}
									<span>{`er allerede brukt. Prøv å logge inn på `}</span>
									<span>
										<a
											target="_self"
											rel="noopener noreferrer"
											href="https://admin.dysmate.se/"
										>
											<u>
												<i>dysmate.no</i>
											</u>
										</a>
									</span>
									<span>
										<i> ➜ testleder.</i>
									</span>
								</span>
							)}
							{error === 'Not Found' && (
								<span className="error">
									<span className="error">
										<span style={{color: 'red'}}>
											<strong>{`Ikke funnet:`}</strong>
										</span>
										<span>{` Registreringsnøkkel`}</span>{' '}
										<span
											style={{color: 'darkblue'}}
										>{`${testKey}`}</span>{' '}
										<span>{`eksisterer ikke. Prøv lenken sendt på e-post.`}</span>
									</span>
								</span>
							)}
						</div>
					)}

					<div
						className={testKey ? 'lit-login-register' : 'lit-login'}
					>
						{!isInit && !customSelection && (
							<div className={loginClass + '__wrapper bg-white'}>
								<h1 className={loginClass + '__header'}>
									{strings.login}
								</h1>
								<p className={loginClass + '__description'}>
									{strings.loginwithbankid}
								</p>
								<p className={loginClass + '__description'}>
									{strings.message_thisisforcertifieduser}
								</p>
								<div
									className={loginClass + '__action-wrapper'}
								>
									<div className="lit-login__action-wrapper">
										{loginMethods
											.sort(sortMethods)
											.map((method) => (
												<button
													key={method}
													className="lit-btn bg-tertiary"
													onClick={() => {
														const authToken = localStorage.getItem(
															'authtoken'
														);
														if (authToken) {
															window.localStorage.removeItem(
																'authtoken'
															);
														}

														if (testKey) {
															if (
																method ===
																BANK_ID
															) {
																window.confirm(
																	strings.reminder_bankidmobileregistration
																);
															} else if (
																method ===
																BANK_ID_MOBILE
															) {
																window.confirm(
																	strings.reminder_bankidmobileregistration
																);
															}
														}
														console.log(
															'menthod',
															method
														);
														this.setState({
															selectedMethod: method,
															customSelection: true,
														});
													}}
												>
													{methodsMap[method].label +
														(methodsMap[method]
															.recommended
															? ` (${strings.recommended})`
															: '')}
												</button>
											))}
									</div>
								</div>
								<br></br>
							</div>
						)}

						{showIframe && !isFeide && (
							<div className="bank-id-iframe-wrapper">
								<iframe
									title="login-frame"
									frameBorder="0"
									id="authFrame"
									scrolling="no"
									src={iframeUrl}
									style={{
										width: '100%',
										minHeight: '200px',
									}}
								/>
							</div>
						)}
						{error && (
							<p className="error">
								<strong>{error}</strong>
							</p>
						)}
					</div>
					{this.state.selectedMethod === 'Password' && (
						<div className="custom-login-iframe-wrapper">
							<EmailPasswordLogin />
						</div>
					)}
					{error && (
						<p className="error">
							<strong>{error}</strong>
						</p>
					)}
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	initAuth: PropTypes.func.isRequired,
	isInit: PropTypes.bool.isRequired,
	isFeide: PropTypes.bool,
};

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit, isFeide} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
		isFeide,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
	resetAuth: resetAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
