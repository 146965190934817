import moment from 'moment';
import {ENDPOINT_URL} from '../constants/index';

import getFetchOptions from '../utils/getFetchOptions';
import {apiErrorHandling} from '../utils/apiUtils';
import {objectToQueryString} from '../utils/objectToQueryString';

export default function getTestsBySearch(filters) {
	const queryString = objectToQueryString(filters);
	return fetch(
		`${ENDPOINT_URL}/searchAllTests${queryString}`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.then((testResults) => {
			const newTestResults = testResults.map((testResult) => {
				const totalmonths = moment(testResult.startedAt).diff(
					moment(testResult.testUser.birthdate),
					'months'
				);

				const years = Math.floor(totalmonths / 12);
				const months = totalmonths % 12;
				testResult.testUser.age = {years, months};

				return testResult;
			});

			return Promise.resolve(newTestResults);
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
