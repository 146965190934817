import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faArrowRightFromBracket,
	faBell,
} from '@fortawesome/free-solid-svg-icons';

import logout from '../../api/logout';

import strings from '../../utils/strings';
import Logo from '../../assets/dysmate-logo-cropped.jpg';

import MenuItem from './MenuItem';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
import classNames from 'classnames';
import UserStageIndicator from '../../components/UserStageIndicator';
import {
	LANGUAGE,
	TRIAL,
	controlPanel,
	notifications,
} from '../../constants/index';
strings.setLanguage(LANGUAGE);

const USERS = {name: strings.users, url: '/users'};
const GROUPS = {name: strings.groups, url: '/groups'};
const SUBSCRIPTION = {name: strings.mypage, url: '/my-subscription'};
const MY_TESTS = {name: strings.mytests, url: '/my-tests', altUrl: ''};
const ALL_SCREENING_TESTS = {name: strings.adulttests, url: '/all-tests'};
const ALL_YOUTH_SCREENING_TESTS = {
	name: strings.youthtests,
	url: '/all-youth-tests',
};
const ALL_CHILD_SCREENING_TESTS = {
	name: strings.childtests,
	url: '/all-child-scr-tests',
};

const CONTROL_PANEL = {
	name: 'Kontrollpanel',
	url: controlPanel,
};
const SEARCH_BY_TESTKEY = {
	name: 'Testschlüssel suchen',
	url: '/search-by-testkey',
};
const ALL_CHILD_TESTS = {name: 'Oppfølgingstester', url: '/all-child-tests'};
const EVENT_LOG = {name: strings.logg, url: '/event-log'};
const USERSUPPORT = {
	name: strings.usersupport,
	url: 'https://www.dysmate.de/brukerstotte/',
	external: true,
};
const BIBLIOTEK = {name: strings.bibliotek, url: '/bibliotek'};

const TEST_ADMIN_TABS = [MY_TESTS, SUBSCRIPTION, USERSUPPORT /* BIBLIOTEK */];
const SUPER_ADMIN_TABS = [
	MY_TESTS,
	USERS,
	GROUPS,
	CONTROL_PANEL,
	ALL_CHILD_SCREENING_TESTS,
	ALL_SCREENING_TESTS,
	ALL_YOUTH_SCREENING_TESTS,
	ALL_CHILD_TESTS,
	USERSUPPORT,
	EVENT_LOG,
	BIBLIOTEK,
];

class Sidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showNotificationBadge: true,
		};
	}

	render() {
		const {showNotificationBadge} = this.state;
		const {isSuperAdmin} = this.props;
		const {currentUserInfo = {}} = this.props;
		let trialExpiryText;

		const now = moment();
		const {isLoginPage = false} = this.props;
		if (currentUserInfo.userStage === TRIAL) {
			const trialValidUntilMoment = moment(
				currentUserInfo.subscription.trialValidUntil
			);
			if (trialValidUntilMoment.isAfter(now)) {
				trialExpiryText = `${
					strings.expiresIn
				} ${trialValidUntilMoment.fromNow(true)}`;
			} else {
				trialExpiryText = `Utløpt`;
			}
		}

		const {pathname} = this.props.location;

		const tabs = isSuperAdmin ? SUPER_ADMIN_TABS : TEST_ADMIN_TABS;
		const isActiveTab = pathname === '/my-subscription';
		const isNotificationTabEnabled = pathname === notifications;
		const {currentUser = null} = this.props;
		const unReadNotificationsCount = currentUser
			? currentUser.unReadNotificationsCount
			: null;
		return (
			<div className="lit-sidebar">
				<div className="lit-logo">
					<img
						className="lit-logo__img"
						src={Logo}
						alt="literate logo"
					/>
				</div>
				<br />
				{!isLoginPage && (
					<nav className="lit-menu">
						<ul>
							{tabs.map((tab, index) => (
								<MenuItem
									key={index}
									tab={tab}
									pathname={pathname}
								/>
							))}
							<br />
							<li>
								<hr
									style={{
										backgroundColor: '#ffcb5c',
										width: '80%',
										marginTop: '10px',
										marginLeft: 'auto',
										marginRight: 'auto',
									}}
								/>
								<br />
							</li>
							{!isSuperAdmin && (
								<li>
									<div className="lit-header-info">
										<Link
											to={notifications}
											className={classNames({
												'lit-menu__subscription': true,
												selected: isNotificationTabEnabled,
											})}
											onClick={(e) => {
												this.setState({
													showNotificationBadge: false,
												});
											}}
										>
											<div
												style={{
													position: 'relative',
													marginBottom: '30px',
												}}
											>
												<FontAwesomeIcon
													className="lit-notification-button"
													icon={faBell}
												/>
												Notifikationen
												{unReadNotificationsCount > 0 &&
													showNotificationBadge && (
														<span className="notification-count">
															{
																unReadNotificationsCount
															}
														</span>
													)}
											</div>
										</Link>
									</div>
								</li>
							)}
							<li>
								<div className="lit-header-info">
									<Link
										to="/my-subscription"
										className={classNames({
											'lit-menu__subscription': true,
											selected: isActiveTab,
										})}
									>
										<div>{`${currentUserInfo.firstName} ${currentUserInfo.lastName}`}</div>
										<UserStageIndicator
											className="lit-header-info__expires"
											small
											user={currentUserInfo}
											withText
										/>

										{currentUserInfo.userStage ===
											TRIAL && (
											<span className="lit-header-info__expires">
												{trialExpiryText}
											</span>
										)}
									</Link>
								</div>
							</li>
							<br />
							<li>
								<button
									className="lit-no-logout-button"
									onClick={(e) => {
										e.preventDefault();
										logout(
											unReadNotificationsCount > 0
												? false
												: true
										);
									}}
								>
									<FontAwesomeIcon
										className="lit-no-logout-button__icon"
										icon={faArrowRightFromBracket}
									/>
									<span className="lit-no-logout-button__text">
										{strings.loggout}
									</span>
								</button>
							</li>
						</ul>
					</nav>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
