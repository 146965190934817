import {ENDPOINT_URL} from '../constants/index';
import {apiErrorHandling} from '../utils/apiUtils';
import getFetchOptions from '../utils/getFetchOptions';

//removing sub_id from api func.
export default function getTestAdminActivity(subscription) {
	return fetch(
		`${ENDPOINT_URL}/tests/${subscription}/activity`,
		getFetchOptions({
			method: 'GET',
			/* body: JSON.stringify({
				subscriptions,
			}), */
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
