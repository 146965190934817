import React from 'react';
import {connect} from 'react-redux';
import {SEARCHBYTESTKEY} from '../../constants/index';
import './styles.scss';
import SearchByTestKey from '../../pages/SearchByTestKey';

const testsFilters = [SEARCHBYTESTKEY];

class Tests extends React.Component {
	constructor() {
		super();

		this.state = {
			activeFilter: SEARCHBYTESTKEY,
		};
	}

	render() {
		const {activeFilter} = this.state;

		return (
			<div>
				<div className="lit-test-section-drop-down">
					<div className="lit-dropdown_filter">
						<select
							className="lit-input__field"
							defaultValue={SEARCHBYTESTKEY}
							onChange={(e) => {
								this.setState({
									activeFilter: testsFilters.filter(
										(filter) => filter.id === e.target.value
									)[0],
								});
							}}
						>
							{testsFilters.map((userStageFilter) => (
								<option
									key={userStageFilter.id}
									value={userStageFilter.id}
								>
									{userStageFilter.label ||
										userStageFilter.text}
								</option>
							))}
						</select>
					</div>
				</div>

				{activeFilter === SEARCHBYTESTKEY && (
					<div className="lit-search-by-test-key">
						<SearchByTestKey />
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tests);
