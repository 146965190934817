/* eslint-disable complexity */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import '../../TestView/styles.scss';

import getSingleChildTestResult from '../../../api/getSingleChildTestResult';
import updateLogEvent from '../../../api/updateLogEvent';
import strings from '../../../utils/strings';
import getQuestionChoices from '../Utils/getQuestionChoices';

import Subtest from '../SingleScreeningTest/Subtest';
import SubtestReport from '../SingleScreeningTest/SubtestReport';
import HandBook from '../SingleScreeningTest/HandBook';
import HorizontalBarChart from '../../../components/HorizontalBarChart';

import {withRouter} from 'react-router-dom';
import TestUserInfo from './TestUserInfo';

import {no, nn} from './assets';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint} from '@fortawesome/free-solid-svg-icons';
import {getPronoun} from '../../../utils/getPronouns';

const TESTS_REQUIRED_TO_PASS = 3;

class SingleChildTest extends React.Component {
	constructor() {
		super();

		this.state = {
			showTestUserInfo: false,
			viewModes: {},
			testResult: null,
			testId: null,
			testType: null,
			isInKinderGarten: false,
			showResultsTab: false,
			showReportTab: true,
			showInformationTab: false,
			showHandBookTab: false,
			showResultsActive: 'tablink1',
			showReportActive: 'tablink2-active',
			showInformationActive: 'tablink3',
			showHandBookActive: 'tablink4',
			birthdate: null,
			buttonTabs: [
				{
					id: 2,
					value: 'report',
					buttonClass: 'tablink2-active',
					text: 'Rapport',
				},
				{
					id: 'defaultOpen',
					value: 'result',
					buttonClass: 'tablink1',
					text: 'Resultater',
				},

				{
					id: 3,
					value: 'info',
					buttonClass: 'tablink3',
					text: strings.userinformation,
				},
			],
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		this.setState({
			testId: id,
		});
		window.scrollTo(0, 0);
		getSingleChildTestResult(id).then((testResult) => {
			let isInKinderGarten = false;
			const testUser = testResult.testUser;
			const birthDate = testUser.hasOwnProperty('birthdate')
				? ` ${moment(testUser.birthdate).format('DD.MM.YYYY')}` //TODO: make brithday bold
				: '';
			if (testResult.type === 'child') {
				if (
					testResult.info.class == 0 ||
					testResult.info.class == 1 ||
					testResult.info.class == 2
				) {
					isInKinderGarten = true;
				}
			}

			testResult.subtests.map((subtest) => {
				this.setViewMode(subtest.id, 'SHOW_ANSWERED');

				return subtest;
			});

			this.setState({
				testResult,
				testType: testResult.type,
				isInKinderGarten,
				birthDate,
			});
		});
	}

	getLanguageAssets = (language) => {
		switch (language) {
			case 'nn':
				return nn;
			case 'no':
			default:
				return no;
		}
	};

	/* 	getQuestionChoices(question) {
		const output = {};
		for (const choice of question.choices) {
			output[choice.letter] = choice;
		}
		return output;
	} */
	updatePrintLog(action) {
		updateLogEvent('print', this.state.testId, '', action);
	}
	//more robust log
	getAnswersWithCorrectAnswer(answers, answersCorrect, questions = false) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			let jInit = 0;
			//Saves us some looping if arrays are arranged as expected
			if (
				answers[i] &&
				answersCorrect[i] &&
				answers[i].id === answersCorrect[i].id
			) {
				jInit = i;
			}
			if (typeof answers[i].id === 'undefined') {
				break;
			}
			const currentAnswer = answers[i];
			const currentAnswerValue = currentAnswer.value.toLowerCase().trim();
			for (let j = jInit; j < answersCorrect.length; j++) {
				const currentCorrectAnswer = answersCorrect[j];
				if (currentAnswer.id === currentCorrectAnswer.id) {
					const correctAnswerValue = currentCorrectAnswer.value
						.toLowerCase()
						.trim();
					const isCorrectAnswer =
						currentAnswerValue === currentCorrectAnswer.value;

					const hasAltValues = Array.isArray(
						currentCorrectAnswer.altValues
					);
					const isCorrectAltValue =
						hasAltValues &&
						currentCorrectAnswer.altValues.indexOf(
							currentAnswerValue
						) !== -1;

					const answer = {
						id: currentAnswer.id,
						value: currentAnswerValue,
						isCorrect: isCorrectAnswer || isCorrectAltValue,
						correctValues: [correctAnswerValue],
					};

					if (hasAltValues) {
						answer.correctValues = [correctAnswerValue].concat(
							currentCorrectAnswer.altValues
						);
					}

					if (questions) {
						answer.question =
							questions[currentAnswer.id - 1].question;
						answer.choices = getQuestionChoices(
							questions[currentAnswer.id - 1]
						);
					}

					output.push(answer);
					break;
				}
			}
		}
		return output;
	}

	renderSubtestType0(key, answer, index, answers) {
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};

		const correctClass = getCorrectClass(answer.isCorrect);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key + '. '}
					<span className={correctClass}>{answers[index]}</span>
				</p>
			</div>
		);
	}
	renderSubtestType1(key, answer, index, answers) {
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};

		const correctClass = getCorrectClass(answer.isCorrect);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key + '. '}
					<span className={correctClass}>{answers[index]}</span>
				</p>
			</div>
		);
	}
	renderSubtestType2(key, answer, index, answers) {
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};
		const correctClass = getCorrectClass(
			answer.option === answers[index].correct
		);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key + '. '}
					<span className={correctClass}>
						{answers[index].options[answer.option]}
					</span>
				</p>
			</div>
		);
	}

	renderSubtestType3(key, answer, index, answers) {
		//orddiktat
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${isCorrect ? 'correct' : 'wrong'}`;
		};

		const correctClass = getCorrectClass(
			answer.toLowerCase().trim() === answers[index].toLowerCase().trim()
		);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key - 1 + '. '}
					<span className={correctClass}>{answer}</span>
				</p>
			</div>
		);
	}
	renderSubtestType4(key, answer, index, answers) {
		const getCorrectClass = (isCorrect) => {
			return `sub-test__answer--${
				isCorrect === 2
					? 'correct'
					: isCorrect === 1
					? 'halfcorrect'
					: 'wrong'
			}`;
		};

		const correctClass = getCorrectClass(answer.isCorrect);
		return (
			<div className={`sub-test__answer`} key={index}>
				<p>
					{key + '. '}
					<span className={correctClass}>{answers[index]}</span>
				</p>
			</div>
		);
	}

	renderSubtest(subtest) {
		return (
			<Subtest
				subtest={subtest}
				type="child"
				testType={this.state.testType} //is going to be used for better rendering of gjennomsnittig og stadaravvik skåre
				testResult={this.state.testResult} //is going to be used for rendering norms based on grade--agegroup
			>
				{this.renderAnswers(subtest)}
			</Subtest>
		);
	}
	renderSubtestReport(subtest, index) {
		return (
			<SubtestReport
				subtest={subtest}
				order={index + 1}
				type="child"
				testResult={this.state.testResult}
			></SubtestReport>
		);
	}

	toggleShowUnanswered(id) {
		if (this.state.viewModes[id] === 'SHOW_ALL') {
			this.setViewMode(id, 'SHOW_ANSWERED');
		} else {
			this.setViewMode(id, 'SHOW_ALL');
		}
	}

	setViewMode(id, value) {
		const obj = {};
		obj[id] = value;
		this.setState({
			viewModes: Object.assign({}, this.state.viewModes, obj),
		});
	}
	returnTestNameForId(id) {
		switch (id) {
			case 0:
				return strings.workingmemorytest;
			case 3:
				return strings.rapidautonamingtest;
			case 4:
				return strings.oneminutetest;
			case 5:
				return strings.spoonerismtest;
			case 6:
				return strings.letterknowledgetest;
			case 7:
				return strings.phonemisolationtest;
			case 8:
				return strings.phonemeomissiontest;
		}
	}
	renderAnswers(subtest) {
		const {answers} = subtest;
		const notAnswered = 0;
		let answersClass = '';
		const {viewModes} = this.state;
		let isVisible = true;
		switch (viewModes[subtest.id]) {
			case 'SHOW_ALL':
				answersClass = 'sub-test__answers--show-not-answered';
				break;
			case 'SHOW_ANSWERED':
				answersClass = '';
				break;
			case 'HIDE_ALL':
			default: {
				isVisible = false;
			}
		}

		switch (subtest.id) {
			case 0:
			case 1:
			case 2: {
				answersClass += ' col-2';
				break;
			}
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
			case 8: {
				answersClass += ' col-4';
				break;
			}
			case 9: {
				answersClass += ' col-3';
				break;
			}
			default:
				break;
		}

		const languageAssets = this.getLanguageAssets(subtest.language);
		const grade = this.state.testResult.info.class;
		const newAnswers = Object.entries(answers).map(
			([num, answer], index) => {
				const key = Number(num) + 1;
				switch (subtest.id) {
					case 0: {
						const correctAnswers = this.getLanguageAssets(
							subtest.language
						).test0;
						return Object.entries(answer).map(
							([num2, answer2], index2) => {
								const key2 = Number(num2) + 1;
								return this.renderSubtestType0(
									key2,
									answer2,
									index2,
									num === 'numbers'
										? correctAnswers.numbers
										: correctAnswers.words
								);
							}
						);
					}
					case 3: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							languageAssets.test3
						);
					}
					case 4: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							languageAssets.test4
						);
					}
					case 5: {
						return this.renderSubtestType4(
							key,
							answer,
							index,
							languageAssets.test5
						);
					}
					case 6: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							grade == 1 || grade == 0
								? languageAssets.testBH6
								: languageAssets.test6
						);
					}
					case 7: {
						return this.renderSubtestType2(
							key,
							answer,
							index,
							grade == 1 || grade == 0
								? languageAssets.testBH7
								: languageAssets.test7
						);
					}
					case 8: {
						return this.renderSubtestType1(
							key,
							answer,
							index,
							languageAssets.test8
						);
					}
					case 9: {
						return this.renderSubtestType3(
							key,
							answer,
							index,
							languageAssets.test9
						);
					}
					default:
						return '';
				}
			}
		);

		return (
			isVisible &&
			newAnswers.length && (
				<div>
					<h4 className="sub-test__answers__heading">
						{strings.candidate_answers}
					</h4>
					<div className={`sub-test__answers ${answersClass}`}>
						{newAnswers}
					</div>
					{notAnswered > 0 && (
						<p className="sub-test__not-answered">
							<b>{`${notAnswered} oppgaver ikke besvart`}</b>{' '}
							<button
								className="sub-test__link print-hide"
								onClick={(e) => {
									e.preventDefault();
									this.toggleShowUnanswered(subtest.id);
								}}
							>
								{viewModes[subtest.id] === 'SHOW_ALL'
									? 'Skjul ubesvarte'
									: 'Vis ubesvarte'}
							</button>
						</p>
					)}
				</div>
			)
		);
	}
	returnRelevantZscore(subTestsArray, testId) {
		for (let i = 0; i < subTestsArray.length; i++) {
			if (subTestsArray[i].id === testId) {
				return subTestsArray[i].zScore;
			}
		}
	}
	extractRatingDataForSubtest(subTestsArray, id) {
		for (let i = 0; i < subTestsArray.length; i++) {
			if (subTestsArray[i].id === id) {
				return subTestsArray[i].totalCorrect;
			}
		}
	}
	assembleAsessment(
		scenario,
		lowScoredSubTests,
		writeUpText,
		completeName,
		subtestsToBeRendered,
		testUser
	) {
		switch (scenario) {
			case 'lowBH':
				if (
					lowScoredSubTests.length === 1 &&
					lowScoredSubTests[0] === strings.letterknowledgetest
				) {
					writeUpText.push(
						strings.formatString(
							strings.assessmentBHonlyLK,
							`${completeName}`
						)
					);
					writeUpText.push(
						strings.formatString(
							strings.assessmentokBHtest,
							`${completeName}`
						)
					);
				} else {
					for (let i = 0; i < lowScoredSubTests.length; i++) {
						switch (lowScoredSubTests[i]) {
							case strings.letterknowledgetest:
								writeUpText.push(
									strings.formatString(
										strings.assessmentBHonlyLK,
										`${completeName}`
									)
								);
								break;

							case strings.workingmemorytest:
								writeUpText.push(
									strings.formatString(
										strings.assessmentBHWM,
										`${completeName}`,
										`${this.returnRelevantZscore(
											subtestsToBeRendered,
											0
										)}`,
										`${completeName}`
									)
								);
								break;
							case strings.phonemisolationtest:
								writeUpText.push(
									strings.formatString(
										strings.assessmentBHFI,
										completeName,
										this.returnRelevantZscore(
											subtestsToBeRendered,
											7
										)
									)
								);
								break;

							case strings.phonemeomissiontest:
								writeUpText.push(
									strings.formatString(
										strings.assessmentBHFU,
										completeName,
										this.returnRelevantZscore(
											subtestsToBeRendered,
											8
										)
									)
								);
								break;

							case strings.rapidautonamingtest:
								writeUpText.push(
									strings.formatString(
										strings.assessmentBHRI,
										completeName,
										this.returnRelevantZscore(
											subtestsToBeRendered,
											3
										)
									)
								);
								break;
							case strings.oneminutetest:
								const totalCorrect = this.extractRatingDataForSubtest(
									subtestsToBeRendered,
									4
								);
								let zScore;
								if (totalCorrect < 62) {
									zScore =
										` < ` +
										this.returnRelevantZscore(
											subtestsToBeRendered,
											4
										);
								} else if (totalCorrect > 61) {
									zScore =
										` > ` +
										this.returnRelevantZscore(
											subtestsToBeRendered,
											4
										);
								} else if (
									totalCorrect < 115 &&
									totalCorrect > 62
								) {
									zScore = this.returnRelevantZscore(
										subtestsToBeRendered,
										4
									);
								}
								writeUpText.push(
									strings.formatString(
										strings.oneminutelowassesment,
										completeName,
										zScore || 0
									)
								);
								writeUpText.push(
									strings.oneminutelowassesment2
								);
								break;
						}
					}
				}

				break;
			case 'lowFollowup':
				for (let i = 0; i < lowScoredSubTests.length; i++) {
					switch (lowScoredSubTests[i]) {
						case strings.workingmemorytest:
							writeUpText.push(
								strings.formatString(
									strings.workingmemorylowassesment,
									`${completeName}`
								)
							);
							break;
						case strings.rapidautonamingtest:
							writeUpText.push(
								strings.formatString(
									strings.rapidnominationlowassesment,
									completeName,
									`${getPronoun(testUser.gender)}`
								)
							);
							break;
						case strings.oneminutetest:
							let zScore;

							zScore = this.returnRelevantZscore(
								subtestsToBeRendered,
								4
							);

							writeUpText.push(
								strings.formatString(
									strings.oneminutelowassesment,
									completeName
								)
							);
							writeUpText.push(strings.oneminutelowassesment2);
							break;
						case strings.spoonerismtest:
							writeUpText.push(
								strings.formatString(
									strings.spoonerismlowassesment,
									completeName,
									this.returnRelevantZscore(
										subtestsToBeRendered,
										5
									)
								)
							);
							break;
						case strings.fonemutelatelsetest:
							writeUpText.push(
								strings.formatString(
									strings.fonemutelatelselowassesment,
									completeName,
									this.returnRelevantZscore(
										subtestsToBeRendered,
										8
									)
								)
							);
							break;
					}
				}
				break;
		}
	}
	returnTestUserGrade(testUser) {
		let testUserGrade = null;

		if (
			['child-followup', 'youth-followup'].includes(this.state.testType)
		) {
			testUserGrade = (
				<>
					<br />
					{`${testUser.class}.${strings.grade}`}
					<br />
				</>
			);
		} else if (testUser.hasOwnProperty('class')) {
			const highestCompletedEducation = testUser.class
				? testUser.class
				: strings.notavailable;

			testUserGrade = (
				<>
					<br />
					{`${strings.education}: ${highestCompletedEducation}`}
					<br />
				</>
			);
		}
		return testUserGrade;
	}

	setRelevantTab(key, index) {
		const {buttonTabs} = this.state;
		for (let i = 0; i < buttonTabs.length; i++) {
			if (
				i === index &&
				!buttonTabs[index].buttonClass.includes('active')
			) {
				const counter = index + 1;
				buttonTabs[index].buttonClass = `tablink${counter}-active`;
			} else {
				const counter = i + 1;
				buttonTabs[i].buttonClass = `tablink${counter}`;
			}
		}
		switch (key) {
			case 'result':
				this.setState({
					showResultsTab: true,
					showReportTab: false,
					showInformationTab: false,
					showTestUserInfo: false,
					showHandBookTab: false,

					buttonTabs,
				});
				break;
			case 'report':
				this.setState({
					showResultsTab: false,
					showReportTab: true,
					showInformationTab: true,
					showTestUserInfo: false,
					showHandBookTab: false,

					showHandBookActive: 'tablink4',
					buttonTabs,
				});
				break;
			case 'info':
				this.setState({
					showResultsTab: false,
					showReportTab: true,
					showInformationTab: false,
					showTestUserInfo: true,
					showHandBookTab: false,

					buttonTabs,
				});
				break;
			case 'book':
				this.setState({
					showResultsTab: false,
					showReportTab: false,
					showInformationTab: false,
					showHandBookTab: true,
					showTestUserInfo: false,
					buttonTabs,
				});
				break;
		}
	}

	// eslint-disable-next-line complexity
	render() {
		const {
			showTestUserInfo,
			testResult,
			testType,
			isInKinderGarten,
			birthDate,
		} = this.state;

		if (!testResult) {
			return null;
		}

		const {testUser} = testResult;
		const subtestsToBeRendered = testResult.subtests.filter((subtest) => {
			const hasZero =
				testResult.subtests.filter((subtest2) => subtest2.id === 0)
					.length > 0;
			if ([1, 2].includes(subtest.id)) {
				return !hasZero;
			}
			return true;
		});
		const subtestsLength = subtestsToBeRendered.length;
		let zScoreLowCount = 0;
		let lowScoredSubTests = [];
		subtestsToBeRendered.forEach((subtest) => {
			if (subtest.zScore <= -1) {
				lowScoredSubTests.push(this.returnTestNameForId(subtest.id));
				zScoreLowCount++;
			}
		});
		let completeName = '<Name verbergen>';
		if (testUser.firstName) {
			completeName = `${testUser.firstName} ${testUser.lastName}`;
		}

		let writeUpText = [];
		writeUpText.push(strings.assesmentgeneral);
		if (isInKinderGarten) {
			if (!lowScoredSubTests.length) {
				writeUpText.push(
					strings.formatString(
						strings.assessmentokBHtest,
						`${completeName}`
					)
				);
			} else if (lowScoredSubTests.length) {
				this.assembleAsessment(
					'lowBH',
					lowScoredSubTests,
					writeUpText,
					completeName,
					subtestsToBeRendered,
					testUser
				);
			}
		} else if (['child'].includes(testType) && !isInKinderGarten) {
			if (zScoreLowCount >= TESTS_REQUIRED_TO_PASS) {
				writeUpText.push(
					strings.formatString(
						strings.asessmenttextnotok_1,
						`${completeName}`,
						`${zScoreLowCount}`,
						`${subtestsLength}`
					)
				);
			} else if (zScoreLowCount < TESTS_REQUIRED_TO_PASS) {
				writeUpText.push(
					strings.formatString(
						strings.asessmenttextok_1,
						`${completeName}`,
						`${subtestsLength - zScoreLowCount}`
					)
				);
			}
		} else if (
			['child-followup', 'youth-followup', 'adult-followup'].includes(
				testType
			)
		) {
			if (!lowScoredSubTests.length) {
				writeUpText.push(
					strings.formatString(
						strings.assesmenttextok_youthfollowup,
						`${completeName}`
					)
				);

				writeUpText.push(
					strings.formatString(
						strings.assesmentok_recommendation_youthfollowup,
						completeName
					)
				);
			} else {
				this.assembleAsessment(
					'lowFollowup',
					lowScoredSubTests,
					writeUpText,
					completeName,
					subtestsToBeRendered,
					testUser
				);
			}
		}

		return (
			<div className="lit-single-test">
				<div>
					<div
						className={
							this.state.testType === 'child'
								? 'lit-single-youthtest-title-desc'
								: 'lit-single-adulttest-title-desc'
						}
					>
						<h1 className="lit-page-title">
							{this.state.showHandBookTab
								? strings.formatString(
										strings.handbooktitle,
										<>
											<b>Håndbok</b>
											<br />
											<br />
										</>
								  )
								: this.state.showResultsTab
								? strings.formatString(
										strings.dynamicheaderfortestresult_child,
										this.state.testType === 'child' ? (
											<>
												<br />
												<br />
												<b>
													{strings.childfollowuptest}
												</b>
											</>
										) : this.state.testType ===
										  'youth-followup' ? (
											<>
												<b>
													<br />
													<br />
													{strings.youthfollowuptest}
												</b>
											</>
										) : this.state.testType ===
										  'child-followup' ? (
											<b>
												<br />
												<br />
												{strings.childfollowuptest}
											</b>
										) : (
											<>
												<br />
												<br />
												<b>
													{strings.adultfollowuptest}
												</b>
											</>
										)
								  )
								: this.state.showTestUserInfo
								? strings.formatString(
										strings.dynamicheaderfortestcandidate_child,
										this.state.testType === 'child' ? (
											<>
												<br />
												<b>{strings.childtest}</b>
											</>
										) : this.state.testType ===
										  'youth-followup' ? (
											<>
												<br />
												<br />
												<b>
													{strings.youthfollowuptest}
												</b>
											</>
										) : this.state.testType ===
										  'child-followup' ? (
											<>
												<b>
													<br />
													<br />

													{strings.childfollowuptest}
												</b>
											</>
										) : (
											<>
												<br />
												<br />
												<b>
													{strings.adultfollowuptest}
												</b>
											</>
										)
								  )
								: this.state.showReportTab
								? strings.formatString(
										strings.dynamicheaderfortestreport_child,
										this.state.testType === 'child' ? (
											<b>{strings.childtest}</b>
										) : this.state.testType ===
										  'youth-followup' ? (
											<>
												<br />
												<br />
												<b>
													{strings.youthfollowuptest}
												</b>
											</>
										) : this.state.testType ===
										  'child-followup' ? (
											<>
												<br />
												<br />
												<b>
													{strings.childfollowuptest}
												</b>
											</>
										) : (
											<>
												<br />
												<br />
												<b>
													{strings.adultfollowuptest}
												</b>
											</>
										)
								  )
								: ''}
						</h1>

						{!this.state.showHandBookTab && (
							<p className="lit-page-lead">
								{strings.formatString(
									this.state.showResultsTab
										? strings.newinfo_belowpresentsinformation
										: this.state.showTestUserInfo
										? strings.newinfo3_belowpresentsinformation
										: this.state.showReportTab
										? strings.newinfo2_belowpresentsinformation
										: '',

									<>
										<br />
										<br />
										<b>{completeName}</b>
										<br />{' '}
									</>,

									<span>
										<b>{birthDate}</b>
									</span>
								)}

								<span>
									{this.returnTestUserGrade(testUser)}
								</span>
								{testResult.completedAt ? (
									strings.formatString(
										strings.info_testwascompletedon,
										<>
											<b>
												{moment(
													testResult.completedAt
												).format('DD.MM.YYYY')}
												.
											</b>
											<br />
										</>
									)
								) : (
									<>
										<br />
										{
											strings.info_testwasNotcompletedon
										}{' '}
										<br />
									</>
								)}
								{strings.formatString(
									strings.infor_scr_ageAtCommencement,
									<b>{testUser.age.years}</b>,

									<b>{testUser.age.months}</b>
								)}
							</p>
						)}
					</div>

					<div className="lit-tab-wrapper">
						<div className="lit-results-line" />
						{this.state.buttonTabs.map((tab, index) => {
							const id = tab.id;
							const value = tab.value;
							const buttonClass = tab.buttonClass;
							const btnText = tab.text;

							// eslint-disable-next-line react/jsx-key
							return (
								<button
									className={buttonClass}
									id={id}
									key={index}
									value={value}
									disabled={buttonClass.includes('active')}
									onClick={(e) => {
										this.setRelevantTab(
											e.target.value,
											index
										);
									}}
								>
									{btnText}
								</button>
							);
						})}
					</div>

					{this.state.showResultsTab &&
						!this.state.showReportTab &&
						!this.state.showInformationTab && (
							<div className="lit-histogram-Test_Details">
								<div className="lit-printBtn">
									<button
										className={classNames('print-hide', {
											'lit-btn2 see-more': true,
											'bg-tertiary': true,
										})}
										onClick={() => {
											window.print();
											this.updatePrintLog('Results');
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-icon-btns"
											icon={faPrint}
										/>

										<p className="lit-btns-txt">
											{strings.formatString(
												strings.print,
												strings.testresult
											)}
										</p>
									</button>
								</div>
								<div className="lit-results-background">
									<div className="sub-test total print-summary">
										{!testResult.completedAt && (
											<div className="incomplete-notice">
												{strings.incompleteMessage}
											</div>
										)}
										<h3 className="sub-test__name big">
											{strings.formatString(
												strings.summary,
												strings.testresult
											)}
										</h3>
										<div className="summary-chart">
											<HorizontalBarChart
												isChild
												subtests={subtestsToBeRendered}
											/>
										</div>
										<div className="explanations">
											<div className="explanation">
												<div className="explanation__figure negative" />
												<p className="explanation__text">
													{strings.formatString(
														strings.zscorered,
														<em>{strings.under}</em>
													)}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure neutral" />
												<p className="explanation__text">
													{strings.zscoreblue}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure positive" />
												<p className="explanation__text">
													{strings.formatString(
														strings.zscoregreen,
														<em>{strings.over}</em>
													)}
												</p>
											</div>
										</div>
										{this.state.testType === 'child' &&
											!this.state.isInKinderGarten && (
												<div className="sub-test__scores">
													<div className="width--half center">
														<h4 className="sub-test__heading big">
															{
																strings.assessmentfollowup
															}
														</h4>

														{writeUpText.map(
															(text) => {
																return (
																	<p>
																		{text}
																	</p>
																);
															}
														)}
													</div>
												</div>
											)}
									</div>
									<div className="print-sub-tests">
										{subtestsToBeRendered.map((subtest) => {
											return this.renderSubtest(subtest);
										})}
									</div>
								</div>
							</div>
						)}
					{showTestUserInfo && (
						<TestUserInfo testResult={testResult} />
					)}
					{this.state.showReportTab && !this.state.showTestUserInfo && (
						<div className="lit-histogram-Test_Report">
							<div className="lit-printBtn">
								<button
									className={classNames('print-hide', {
										'lit-btn2 see-more': true,
										'bg-tertiary': true,
									})}
									onClick={() => {
										window.print();
										this.updatePrintLog('Report');
									}}
								>
									<FontAwesomeIcon
										size="lg"
										className="lit-icon-btns"
										icon={faPrint}
									/>

									<p className="lit-btns-txt">
										{strings.formatString(
											strings.print,
											strings.testreport
										)}
									</p>
								</button>
							</div>
							<div className="lit-results-background">
								<div className="sub-test total print-summary">
									{!testResult.completedAt && (
										<div className="incomplete-notice">
											{strings.incompleteMessage}
										</div>
									)}
									<h3 className="sub-test__name big">
										{strings.formatString(
											strings.summary,
											strings.testreport
										)}
									</h3>
									<div
										style={{
											margin: '20px',
											padding: '20px',
										}}
									>
										<p
											className="explanation__text"
											style={{textAlign: 'center'}}
										>
											{strings.formatString(
												strings.summarypretext,
												<b style={{color: '#0f4256'}}>
													{completeName}
												</b>
											)}
										</p>
									</div>
									<div className="summary-chart">
										<HorizontalBarChart
											isChild
											subtests={subtestsToBeRendered}
										/>
									</div>
									<div className="explanations">
										<div className="explanation">
											<div className="explanation__figure negative" />
											<p className="explanation__text">
												{strings.formatString(
													strings.zscorered,
													<em>{strings.under}</em>
												)}
											</p>
										</div>
										<div className="explanation">
											<div className="explanation__figure neutral" />
											<p className="explanation__text">
												{strings.zscoreblue}
											</p>
										</div>
										<div className="explanation">
											<div className="explanation__figure positive" />
											<p className="explanation__text">
												{strings.formatString(
													strings.zscoregreen,
													<em>{strings.over}</em>
												)}
											</p>
										</div>
									</div>
									<div className="explanation">
										<p>
											<i>{strings.inforeferall}</i>
										</p>
									</div>

									<div className="print-sub-tests-report">
										<h2
											className="sub-test-Report__name"
											style={{color: '#0f4256'}}
										>{`Die Subtests des Follow-Ups`}</h2>
										{subtestsToBeRendered.map(
											(subtest, index) => {
												return this.renderSubtestReport(
													subtest,
													index
												);
											}
										)}
									</div>
									<br />
									<div>
										{(this.state.testType !== 'child' ||
											this.state.isInKinderGarten) && (
											<div className="sub-test__scores">
												<div className="width--half center">
													<h4 className="sub-test__heading big">
														{
															strings.assessmentfollowup
														}
													</h4>

													{writeUpText.map((text) => {
														return <p>{text}</p>;
													})}
												</div>
											</div>
										)}
										<br />
										<div className="sub-test__comment">
											<h4 className="sub-test__heading big">
												<span className="comment">
													Kommentarer:
												</span>
												<div className="line line-1" />
												<div className="line line-2" />
												<div className="line line-3" />
												<div className="line line-4" />
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{this.state.showHandBookTab && <HandBook />}
				</div>
			</div>
		);
	}
}

SingleChildTest.propTypes = {
	currentUser: PropTypes.shape({}),
};

export default withRouter(SingleChildTest);
