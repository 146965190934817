import React from 'react';
import PropTypes from 'prop-types';

import {TEST_TYPES, TEST_TYPE_NAMES} from '../../../constants/index';
import strings from '../../../utils/strings';

const testsWithLongIntro = ['adult-screening'];
const testsWithInstruction = ['child-screening'];
const testWithAutomaticDistribution = ['youth-screening', 'adult-screening'];

class NewTestModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groupId: '',
			hasConfirmedCost: true,
			newTestCount: 1,
			areEmailsCorrect: false,
			type: '',
			candidateEmails: '',
			formattedEmails: null,
			rawEmails: null,
			sendAndDistribute: false,
			childSelfStarting: false,
			infoCandidateAdmin: true,
			availableTestTypes: [],
			isAddingGroup: false,
			isAddingLongIntro: false,
			availableLanguage: [],
			selectedLanguage: null,
		};
		this.setAvailableTypes = this.setAvailableTypes.bind(this);
	}
	componentDidMount() {
		const userLanguages = this.props.userLanguages[0];
		const tempObj = [];
		let initialTestType = null;
		const searchAbaleLanguageFields = [
			'norwegian',
			'swedish',
			'english',
			'dutch',
			'german',
		];
		const searchAbaleSubscriptionFields = [
			'child',
			'youth',
			'adult',
			'norming_child',
			'norming_youth',
			'norming_adult',
			'adult_followup',
			'child_followup',
			'youth_followup',
		];
		for (const field of searchAbaleLanguageFields) {
			if (userLanguages.hasOwnProperty(field)) {
				for (const subType of searchAbaleSubscriptionFields) {
					if (userLanguages[field][subType] === 1) {
						if (initialTestType === null) {
							initialTestType = userLanguages[field];
						}
						tempObj.push(userLanguages[field].language);
						break;
					}
				}
			}
		}

		this.setAvailableTypes(initialTestType);
		this.setState({
			availableLanguage: tempObj,
		});
		setTimeout(() => {
			this.setState({
				groupId: null,
				hasConfirmedCost: true,
				selectedLanguage: this.state.availableLanguage[0],
				type: this.state.availableTestTypes[0],
			});
		}, 0);
	}

	handleChange(event) {
		const value = event.target.value;
		const emailArray = value.split(/[\s,]+/);
		this.setState({
			rawEmails: emailArray,
			candidateEmails: event.target.value,
		});
		const gatherCorrectEmails = this.areAllEmailsValid();
	}
	handlePaste(event) {
		const value = (event.clipboardData || window.clipboardData).getData(
			'text'
		);
		const emailArray = value.split(/[\s,]+/);
		this.setState({rawEmails: emailArray, candidateEmails: value});
		const gatherCorrectEmails = this.areAllEmailsValid();
	}

	areAllEmailsValid() {
		let flag = true;
		let gatheredEmails = [];
		if (this.state.sendAndDistribute && this.state.candidateEmails) {
			const {rawEmails} = this.state;
			for (let i = 0; i < rawEmails.length; i++) {
				if (rawEmails[i].length) {
					var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
					flag = re.test(rawEmails[i]);
					if (flag) {
						gatheredEmails.push(rawEmails[i]);
					}
				}
			}
		}
		this.setState({
			areEmailsCorrect: flag,
			formattedEmails: gatheredEmails,
		});
		return flag;
	}
	setAvailableTypes(availableLanguages) {
		const availableTests = [];
		const searchAbaleSubscriptionFields = [
			'child',
			'adult',
			'youth',
			'norming_youth',
			'norming_child',
			'norming_adult',
			'child_followup',
			'youth_followup',
			'adult_followup',
		];
		for (const field of searchAbaleSubscriptionFields) {
			if (availableLanguages[field] === 1) {
				if (field === 'child') {
					availableTests.push(
						TEST_TYPES.CHILD_SCREENING,
						TEST_TYPES.CHILD_EXPERIMENTAL
					);
				}
				if (field === 'child_followup') {
					availableTests.push(TEST_TYPES.CHILD_FOLLOWUP);
				}
				if (field === 'adult') {
					availableTests.push(TEST_TYPES.ADULT_SCREENING);
				}
				if (field === 'youth_followup' || field === 'norming_youth') {
					availableTests.push(TEST_TYPES.YOUTH_FOLLOWUP);
				}
				if (field === 'youth' || field === 'norming_youth') {
					availableTests.push(TEST_TYPES.YOUTH_SCREENING);
				}

				if (field === 'adult_followup') {
					availableTests.push(TEST_TYPES.ADULT_FOLLOWUP);
				}
			}
		}
		this.setState({
			availableTestTypes: availableTests,
		});
	}

	render() {
		const {onSave} = this.props;
		const UsersGroups = this.props.userGroups;
		const userLanguages = this.props.userLanguages[0];
		const groups = UsersGroups.map((userGroup) => {
			return userGroup.name;
		});

		const {
			hasConfirmedCost,
			newTestCount,
			availableTestTypes,
			isAddingGroup,
		} = this.state;

		const cancelButton = (
			<button className="lit-btn bg-negative" onClick={() => onSave()}>
				{strings.cancel}
			</button>
		);

		return (
			<div
				className="lit-modal"
				onClick={() => {
					onSave();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{strings.createnewtest}</h3>
					{!hasConfirmedCost && (
						<div>
							<button className="lit-btn bg-primary">
								{strings.confirm}
							</button>
							{cancelButton}
						</div>
					)}
					{hasConfirmedCost && (
						<form
							className="lit-form register-form"
							onSubmit={(event) => {
								event.preventDefault();
								let emailValidity = false;
								emailValidity = this.areAllEmailsValid();
								if (emailValidity) {
									if (groups.length) {
										onSave(
											this.state.type,
											this.state.newTestCount,
											this.state.groupId,
											this.state.selectedLanguage,
											this.state.isAddingLongIntro,
											this.state.childSelfStarting,
											this.state.formattedEmails
										);
									} else {
										onSave(
											this.state.type,
											this.state.newTestCount,
											null,
											this.state.selectedLanguage,
											this.state.isAddingLongIntro,
											this.state.childSelfStarting,
											this.state.formattedEmails
										);
									}
								}
							}}
						>
							<div className="lit-input">
								{this.state.availableLanguage.length > 1 && (
									<div className="lit-input inline">
										<label className="lit-input__groupname-label">
											{strings.chooselanguage}
										</label>
										<select
											className="lit-input__field"
											defaultValue={
												this.state.availableLanguage[0]
											}
											name="language"
											onChange={(event) => {
												this.setState({
													selectedLanguage:
														event.target.value,
												});
												this.setAvailableTypes(
													userLanguages[
														event.target.value
													]
												);
											}}
										>
											{this.state.availableLanguage.map(
												(language) => (
													<option
														key={language}
														value={language}
													>
														{language}
													</option>
												)
											)}
										</select>
									</div>
								)}

								<div className="lit-input inline">
									<label className="lit-input__label">
										{strings.test_type}
									</label>
									<select
										className="lit-input__field"
										defaultValue={this.state.type}
										name="type"
										onChange={(event) => {
											console.log(
												'This is the type ',
												event.target.value
											);
											this.setState({
												type: event.target.value,
											});
										}}
									>
										{Object.entries(TEST_TYPES)
											.filter(([key, value]) =>
												availableTestTypes.includes(
													value
												)
											)
											.map(([key, value]) => (
												<option key={key} value={value}>
													{TEST_TYPE_NAMES[value]}
												</option>
											))}
									</select>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label">
										{strings.numberoftests}
									</label>
									<input
										className="lit-input__field"
										max="30"
										min="0"
										name="newTestCount"
										onChange={(event) => {
											const newTestCount =
												event.target.value;
											this.setState({
												newTestCount,
											});
										}}
										placeholder="5"
										required
										type="number"
										value={newTestCount}
									/>
								</div>

								{Boolean(groups.length) && (
									<div className="lit-input inline">
										<label className="lit-input__label">
											{strings.choosegroup}
										</label>
										<input
											type="checkbox"
											className="lit-input_checkbox"
											name="velg gruppet"
											onChange={(e) => {
												if (e.target.checked) {
													this.setState({
														isAddingGroup: true,
														groupId:
															UsersGroups[0].id,
													});
												} else {
													this.setState({
														groupId: 0,
														isAddingGroup: false,
													});
												}
											}}
										/>
									</div>
								)}

								<div>
									{isAddingGroup && (
										<div className="lit-input inline">
											<label className="lit-input__groupname-label">
												{'Gruppenavn'}
											</label>
											<select
												className="lit-input__field"
												defaultValue={groups[0]}
												name="group"
												onChange={(event) => {
													var chosenGroup = UsersGroups.filter(
														(userGroup) =>
															userGroup.name ===
															event.target.value
													);

													this.setState({
														groupId:
															chosenGroup[0].id,
													});
												}}
											>
												{groups.map((group) => (
													<option
														key={group}
														value={group}
													>
														{group}
													</option>
												))}
											</select>
										</div>
									)}
								</div>
								<br></br>
								{testsWithLongIntro.includes(
									this.state.type
								) && (
									<div>
										<div className="lit-input inline">
											<label className="lit-input__label">
												{strings.includeLongIntro}
											</label>
											<input
												type="checkbox"
												className="lit-input_checkbox"
												name="velg anamnese"
												onChange={(e) => {
													if (e.target.checked) {
														this.setState({
															isAddingLongIntro: true,
														});
													} else {
														this.setState({
															isAddingLongIntro: false,
														});
													}
												}}
											/>
										</div>
										<div>
											<p>
												({strings.anamneseexplaination})
											</p>
										</div>
									</div>
								)}

								{testsWithInstruction.includes(
									this.state.type
								) && (
									<div>
										<div className="lit-input-marginal">
											<div className="lit-input inline">
												<label className="lit-input__label">
													<b>
														{
															strings.fillSelfCandidateInfo
														}
													</b>
												</label>
												<input
													checked={
														this.state
															.infoCandidateAdmin
													}
													type="radio"
													className="lit-input_checkbox"
													name="velg anamnese"
													onChange={(e) => {
														if (e.target.checked) {
															this.setState({
																infoCandidateAdmin: true,
																childSelfStarting: false,
															});
														}
													}}
												/>
											</div>
											<div>
												<p>
													{
														strings.fillKandidateInformation1
													}
												</p>
											</div>
											<div className="lit-input inline">
												<label className="lit-input__label">
													<b>
														{
															strings.letCandidateFillInfo
														}
													</b>
												</label>
												<input
													checked={
														this.state
															.childSelfStarting
													}
													type="radio"
													className="lit-input_checkbox"
													name="velg anamnese"
													onChange={(e) => {
														if (e.target.checked) {
															this.setState({
																childSelfStarting: true,
																infoCandidateAdmin: false,
															});
														}
													}}
												/>
											</div>
											<div>
												<p>
													{
														strings.fillKandidateInformationSelf1
													}
												</p>
											</div>
											<div>
												<p>
													{
														strings.fillKandidateInformationSelf2
													}
												</p>
											</div>
										</div>
										<div>
											<p>{strings.candidateInfoCommon}</p>
											<p>
												{strings.candidateInfoReminder}
											</p>
										</div>
									</div>
								)}
								{this.state.isAddingLongIntro &&
									this.state.type === 'youth-screening' && (
										<div className="lit-info__field-text">
											<div>
												<span className="lit-fill-out-info-consent">
													<input
														type="checkbox"
														name="agreedToPrivacyPolicy"
														required
														className="user_checkbox2"
													/>{' '}
													{strings.iConsent}
												</span>
												<br />
												<br />

												{strings.formatString(
													strings.constTestYouth,

													<a
														target="_blank"
														rel="noopener noreferrer"
														href="https://literate.no/personvernerklaering/"
													>
														<u>
															{
																strings.privacyPolicy
															}
														</u>
													</a>
												)}
											</div>
										</div>
									)}
							</div>
							{testWithAutomaticDistribution.includes(
								this.state.type
							) &&
								Boolean(this.state.newTestCount > 19) && (
									<div>
										<div className="lit-input-marginal">
											<div className="lit-input inline">
												<label className="lit-input__label">
													<b>{'Bulkutsending'}</b>
												</label>
												<input
													checked={
														this.state
															.sendAndDistribute
													}
													type="checkbox"
													className="lit-input_checkbox"
													name="send test"
													onChange={(e) => {
														if (e.target.checked) {
															this.setState(
																{
																	sendAndDistribute: true,
																},
																() => {
																	var txarea = document.getElementById(
																		'source'
																	);
																	if (
																		txarea
																	) {
																		txarea.addEventListener(
																			'paste',
																			(
																				e
																			) => {
																				e.preventDefault();

																				let text =
																					'';
																				if (
																					e.clipboardData ||
																					e
																						.originalEvent
																						.clipboardData
																				) {
																					text = (
																						e.originalEvent ||
																						e
																					).clipboardData.getData(
																						'text'
																					);
																				} else if (
																					window.clipboardData
																				) {
																					text = window.clipboardData.getData(
																						'Text'
																					);
																				}
																				let replace_text = text.replace(
																					/\n/g,
																					' '
																				);
																				if (
																					document.queryCommandSupported(
																						'insertText'
																					)
																				) {
																					document.execCommand(
																						'insertText',
																						false,
																						replace_text
																					);
																				} else {
																					document.execCommand(
																						'paste',
																						false,
																						replace_text
																					);
																				}
																			}
																		);
																	}
																}
															);
														} else {
															this.setState({
																sendAndDistribute: false,
															});
														}
													}}
												/>
											</div>
											<div>
												<p>
													{
														strings.createanddistributetests
													}
												</p>
											</div>
											{/* <div className="lit-input inline">
												<label className="lit-input__label">
													<b>
														{
															strings.letCandidateFillInfo
														}
													</b>
												</label>
												<input
													checked={
														this.state
															.childSelfStarting
													}
													type="radio"
													className="lit-input_checkbox"
													name="velg anamnese"
													onChange={(e) => {
														if (e.target.checked) {
															this.setState({
																childSelfStarting: true,
																infoCandidateAdmin: false,
															});
														}
													}}
												/>
											</div> */}
											{/* <div>
												<p>
													{
														strings.fillKandidateInformationSelf1
													}
												</p>
											</div>
											<div>
												<p>
													{
														strings.fillKandidateInformationSelf2
													}
												</p>
											</div> */}
										</div>
										{/* <div>
											<p>{strings.candidateInfoCommon}</p>
											<p>
												{strings.candidateInfoReminder}
											</p>
										</div> */}
									</div>
								)}

							{this.state.sendAndDistribute && (
								<div className="lit-input inline">
									<span>
										{strings.remembertoprovideemail} f.eks.{' '}
										<span style={{color: 'darkOrange'}}>
											<i>{strings.exmapledemail}</i>
										</span>
									</span>

									<textarea
										id="source"
										cols="40"
										rows="20"
										name="textarea"
										placeholder={
											'lim inn e-poster, en e-post pr linje'
										}
										className="lit-tests_textarea"
										value={this.state.candidateEmails}
										onChange={(event) => {
											this.handleChange(event);
										}}
										onPaste={(event) => {
											this.handlePaste(event);
										}}
									/>
									<div></div>
								</div>
							)}
							<div className="lit-input">
								<div>
									<button
										className="lit-btn bg-primary"
										disabled={
											newTestCount < 1 ||
											newTestCount > 30 ||
											this.state.sendAndDistribute
										}
									>
										{`${
											newTestCount > 1
												? strings.create_tests
												: strings.createnewtest
										}`}
									</button>

									{this.state.sendAndDistribute && (
										<button
											className="lit-btn bg-primary3"
											disabled={
												!this.state.candidateEmails ||
												!this.state.areEmailsCorrect
											}
										>
											{'Opprett og send'}
										</button>
									)}

									{cancelButton}
								</div>
							</div>
						</form>
					)}
				</div>
			</div>
		);
	}
}

NewTestModal.propTypes = {
	onSave: PropTypes.func.isRequired,
	userStage: PropTypes.string.isRequired,
};

export default NewTestModal;
