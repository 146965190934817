import moment from 'moment';
import {ENDPOINT_URL} from '../constants/index';
import getFetchOptions from '../utils/getFetchOptions';
import {apiErrorHandling} from '../utils/apiUtils';

export default function getSingleSpeechTestResult(testResultId) {
	return fetch(
		`${ENDPOINT_URL}/speechtest/${testResultId}/results`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.then((testResult) => {
			const totalmonths = moment(testResult.startedAt).diff(
				moment(testResult.testUser.birthdate),
				'months'
			);

			const years = Math.floor(totalmonths / 12);
			const months = totalmonths % 12;
			testResult.testUser.age = {years, months};

			return Promise.resolve(testResult);
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
