import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import UserStageEditor from './UserStageEditor';

import {updateObject} from '../../../utils/reducerUtils';
//import {language} from '../../constants/index';
import strings from '../../../utils/strings';
import 'moment/locale/de';
moment.locale('de');

class TestAdminModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			testAdmin: props.testAdmin,
		};

		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleUpdateTestAdminEvent = this.handleUpdateTestAdminEvent.bind(
			this
		);
	}

	handleUpdateTestAdminEvent(event) {
		const target = event.target;

		const key = target.name;
		const value = target.value;

		this.handleUpdate(key, value);
	}

	handleUpdate(key, value) {
		const {testAdmin} = this.state;
		this.setState({
			testAdmin: updateObject(testAdmin, {
				[key]: value,
			}),
		});
	}

	render() {
		const {
			isCreate,
			onSave,
			onDelete,
			onDeleteLanguage,
			onUpdateLanguageSubscription,
			onAssignLanguageSubscription,
			onSaveSubscription,
			onExtendTrial,
			language,
		} = this.props;

		const {testAdmin} = this.state;
		return (
			<div
				className="lit-modal"
				onClick={() => {
					onSave();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3 className="register-form">{`${
						isCreate
							? strings.addanewtestadministrator
							: strings.edittestadministrator
					}`}</h3>
					<form
						className="register-form"
						onSubmit={(event) => {
							event.preventDefault();
							const testAdminResult = updateObject(testAdmin);
							onSave(testAdminResult);
						}}
					>
						<br />
						<h3>{strings.personalinformation}</h3>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-firstName"
							>
								{strings.firstname}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-firstName"
								name="firstName"
								onChange={this.handleUpdateTestAdminEvent}
								placeholder="Kari"
								required
								type="text"
								value={testAdmin.firstName || ''}
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-lastName"
							>
								{strings.lastname}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-lastName"
								name="lastName"
								onChange={this.handleUpdateTestAdminEvent}
								placeholder="Nordmann"
								required
								type="text"
								value={testAdmin.lastName || ''}
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-organization"
							>
								{strings.shcool_or_company}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-organization"
								name="organization"
								onChange={this.handleUpdateTestAdminEvent}
								placeholder="Kongsbakken vgs"
								type="text"
								value={testAdmin.organization || ''}
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-email"
							>
								{strings.email}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-email"
								name="email"
								onChange={this.handleUpdateTestAdminEvent}
								placeholder="kari@nordmann.no"
								required
								type="email"
								value={testAdmin.email || ''}
							/>
						</div>
						<div className="lit-input inline">
							<label
								className="lit-input__label wide"
								htmlFor="test-admin-modal-phone"
							>
								{strings.telephone}
							</label>
							<input
								className="lit-input__field"
								id="test-admin-modal-phone"
								name="phone"
								onChange={this.handleUpdateTestAdminEvent}
								placeholder="987 65 432"
								required
								type="tel"
								value={testAdmin.phone || ''}
							/>
						</div>

						<div className="lit-input">
							<button className="lit-btn bg-primary">
								{`${
									isCreate
										? strings.addtestadministrator
										: strings.saveuserinformation
								}`}
							</button>
						</div>
					</form>
					<br />
					<UserStageEditor
						onExtendTrial={onExtendTrial}
						onSaveSubscription={onSaveSubscription}
						onUpdateLanguageSubscription={
							onUpdateLanguageSubscription
						}
						onDeleteLanguage={onDeleteLanguage}
						onAssignLanguageSubscription={
							onAssignLanguageSubscription
						}
						testAdmin={testAdmin}
						language={language}
					/>
					<div className="register-form">
						{!isCreate && (
							<button
								onClick={() => onDelete(testAdmin.id)}
								className="lit-btn bg-danger"
							>
								{strings.deleteuser}
							</button>
						)}
					</div>
					<br />
					<div className="register-form">
						<label>
							Registration URL
							<input
								className="lit-input__field disabled"
								type="text"
								readOnly
								value={
									window.location.origin +
									'/?key=' +
									testAdmin.regToken
								}
							/>
						</label>
					</div>
				</div>
			</div>
		);
	}
}

TestAdminModal.propTypes = {
	isCreate: PropTypes.bool,
	onExtendTrial: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onSaveSubscription: PropTypes.func.isRequired,
	testAdmin: PropTypes.shape({}),
};

export default TestAdminModal;
