import {ALL} from '../constants/index';

import {updateObject} from '../utils/reducerUtils';

export default function getFilteredTestResults(
	batchedTestResults,
	activeFilter
) {
	if (activeFilter === ALL) {
		return batchedTestResults;
	}

	return batchedTestResults.map((monthBatch) => {
		const testResults = monthBatch.testResults.filter(
			(testResult) => testResult.status === activeFilter
		);

		return updateObject(monthBatch, {
			testResults,
		});
	});
}
