import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

import retrieveDeletedTests from '../../api/retrieveDeletedTests';

import {connect} from 'react-redux';
import strings from '../../utils/strings';
import moment from 'moment';
import classNames from 'classnames';

class TrashCanModel extends React.Component {
	constructor(props) {
		super(props);
		this.retreiveTest = this.retreiveTest.bind(this);
	}

	retreiveTest(testId, key) {
		return retrieveDeletedTests(testId, key).then(() => {
			this.props.getAndSetTestAdminTestResults();
		});
	}

	render() {
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{strings.trashcan}</h3>
					{!this.props.deletedTests.length && (
						<div className="lit-model-no-tests-available-text">
							<p>{strings.message_noTestsFound}</p>
						</div>
					)}
					{Boolean(this.props.deletedTests.length) && (
						<table className="lit-tests">
							<thead className="lit-trashed-tests-lists">
								<tr>
									<th className="lit-test__header">
										{strings.status}
									</th>
									<th className="lit-test__header">
										{strings.recipient}
									</th>
									<th className="lit-test__header">
										{strings.testkey}
									</th>
									<th className="lit-test__header">
										{strings.type}
									</th>

									<th className="lit-test__header">
										{strings.deleatedat}
									</th>
									<th className="lit-test__actions_header">
										{strings.actions}
									</th>
								</tr>
							</thead>
							<tbody className="lit-trashed-tests-lists">
								{this.props.deletedTests?.map((item, key) => {
									return (
										<tr
											className="lit-list__item"
											key={key}
										>
											<td className="lit-test__col">
												<div
													className={
														'lit-test-status'
													}
												>
													<div
														className={classNames({
															'lit-test-status__indicator': true,
															[item.status
																.id]: true,
														})}
													/>
													<span className="lit-test-status__label">
														{item.status.label}
													</span>
												</div>
											</td>
											<td className="lit-test__col">
												{' '}
												{item.information.name ||
													item.information.email}
											</td>
											<td className="lit-test__col">
												{item.key}
											</td>
											<td className="lit-test__col">
												{' '}
												{item.type}
											</td>
											<td className="lit-test__col">
												{' '}
												{moment(item.deletedAt).format(
													'LL'
												)}
											</td>
											<td className="lit-test__actions">
												<button
													className="lit-btn bg-negative2"
													onClick={() => {
														const key = {
															testKey: item.key,
															subsId: this.props
																.subsId,
															adminId: this.props
																.adminId,
														};
														this.retreiveTest(
															item.id,
															key
														);
													}}
												>
													{strings.fetch}
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>
			</div>
		);
	}
}

TrashCanModel.propTypes = {
	currentUser: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TrashCanModel);
